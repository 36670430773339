import React from "react";

import defaultImage from "assets/img/default-avatar.png";

class PictureUpload extends React.Component {
  
  state = {
    renderURL: null
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        renderURL: reader.result
      });
      this.props.input.onChange({ file })
    };
    reader.readAsDataURL(file);

    // TODO: fix the rotation of the images based on their EXIF
  }

  render() {
    const { imageURL } = this.props
    return (
      <div className="picture-container">
        <div className="picture">
          <img
            src={this.state.renderURL || imageURL ||  defaultImage}
            className="picture-src"
            alt="..."
          />
          <input type="file" onChange={e => this.handleImageChange(e)} />
        </div>
        <h6 className="description">Choose Picture</h6>
      </div>
    );
  }
}

export default PictureUpload;
