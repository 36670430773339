import React from 'react'
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import CreateClientForm from './CreateClientForm'
import { NotificationManager} from 'react-notifications';

import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import OverlayLoader from '../Components/OverlayLoader';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

class CreateClient extends React.Component {

    state = {
        alert: null,
        submitting: false
    }

    componentDidMount() {
        document.title = "EPOS - Create Consignor"
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(message) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title="Create Failed"
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    createClient = (json) => {
        this.setState({submitting: true})
        axios.post(`/api/clients`, json).then(response => {
            this.setState({submitting: false})
            NotificationManager.success("Consignor Created", "Success")
            this.props.history.replace(`/clients/list`)
        }).catch( error => {
            this.setState({submitting: false})
            this.showAlert(extractErrorMessage(error))
        })
    }

    render() {
        return (
            <div>
                { this.state.alert }
                <CreateClientForm submitting={this.state.submitting} onSubmit={this.createClient} />
                { this.state.submitting && <OverlayLoader />}
            </div>
        )
    }
};

export default compose(
    withRouter,
    withStyles(buttonStyle)
) (CreateClient)