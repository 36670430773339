import React from 'react'

import Dialog from 'material-ui/Dialog';
import DialogContent from 'material-ui/Dialog/DialogContent';
import DialogTitle from 'material-ui/Dialog/DialogTitle';

import withStyles from "material-ui/styles/withStyles";

import CategoriesView from "../Sale/CategoriesView"
import Grid from "material-ui/Grid";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button";

function SetCategory(props) {
    const {categoryId, onSelect} = props;
    return(
        <Grid style={{
            height: "80%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: 'column',
            justifyContent: "center",
        }}>
            <Button variant="fab" color="primary" size="lg" onClick={() => onSelect(categoryId)}>
                <AddIcon/>
                Add
            </Button>
            <h5>Tap to assign selected category</h5>
        </Grid>
    )
}

const styles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '50vw',
        maxWidth: '99vw'
    },
};

function CategoryDialog(props) {
    const { classes, onChange, onClose } = props
    return (
        <Dialog
            classes={{ paper: classes.dialogPaper }}
            open={true}
            keepMounted
            onClose={ onClose }>
            <DialogTitle><h4>Product Category</h4></DialogTitle>

            <DialogContent >
                <CategoriesView categoryId={0} modal={true} onSelect={onChange}>
                    <SetCategory onSelect={onChange} />
                </CategoriesView>
            </DialogContent>
        </Dialog>
    )
}

export default withStyles(styles)(CategoryDialog);