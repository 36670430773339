import React, { useCallback } from "react";
import ReactTable from "react-table";
import moment from "moment";
import _ from "lodash";
import withConnected from "utilities/withConnected";
import { codes } from "views/Products/ShortCodes";

const ProductTable = ({ products, settings, rdx }) => {
  const {
    perPage: pageSize = 15,
    page,
    totalPages = 1,
    sorted = [],
    search = null,
  } = settings || {};

  const tableColumns = [
    {
      Header: "Date",
      accessor: "created_at",
      width: 100,
      Cell: (item) => moment(item.original.created_at).format("MMM Do, YYYY"),
    },
    {
      Header: "Inventory #",
      accessor: "sku",
      Cell: (item) => {
        const { sku } = item.original;
        if (sku) {
          return sku;
        }
        return "-";
      },
      width: 100,
    },
    {
      Header: "Category/Name",
      accessor: "name",
      Cell: (item) => item.original.name,
      width: 120,
    },
    {
      Header: "$1-$999",
      accessor: "price",
      width: 170,
      Cell: (item) => {
        const { price, code } = item.original;
        if (
          price &&
          _.toNumber(price) < 1000 &&
          _.toNumber(price && code !== "7")
        ) {
          return `$${price}`;
        }
        return "-";
      },
    },
    {
      Header: "$1000-$1999",
      accessor: "price",
      width: 170,
      Cell: (item) => {
        const { price, code } = item.original;

        if (
          price &&
          _.toNumber(price) >= 1000 &&
          _.toNumber(price) < 2000 &&
          code !== "7"
        ) {
          return `$${price}`;
        }
        return "-";
      },
    },
    {
      Header: "$2000+",
      accessor: "price",
      width: 170,
      Cell: (item) => {
        const { price, code } = item.original;

        if (price && _.toNumber(price) >= 2000 && code !== "7") {
          return `$${price}`;
        }
        return "-";
      },
    },
    {
      Header: "Fine",
      accessor: "-",
      width: 170,
      Cell: (item) => {
        const { price, code } = item.original;
        if (price && code === "7") {
          return `$${price}`;
        }
        return "-";
      },
      sortable: false,
    },
    {
      Header: "Total",
      accessor: "price",
      width: 80,
      sortable: false,
    },
  ];

  const handleFetchData = useCallback(
    async ({ page: nextPage, pageSize: nextPageSize, sorted: nextSorted }) => {
      const fetchDetails = { page, pageSize, sorted, search };
      const nextFetch = {
        page: nextPage,
        pageSize: nextPageSize,
        sorted: nextSorted,
        search,
      };
      if (!_.isEqual(fetchDetails, nextFetch)) {
        let sort = null;
        let sortDirection = null;
        if (nextSorted.length) {
          const sortValue = nextSorted[0];
          sort = sortValue?.id;
          sortDirection = sortValue?.desc ? "desc" : "asc";
        }
        rdx.action.loadProducts({
          ...search,
          per_page: pageSize,
          page: nextPage + 1,
          sort,
          sort_direction: sortDirection,
        });
      }
    },
    [page, pageSize, sorted, search]
  );

  return (
    <div>
      <ReactTable
        data={products}
        columns={tableColumns}
        defaultPageSize={pageSize}
        showPageSizeOptions={false}
        showPageJump={false}
        showPaginationTop={false}
        multiSort={false}
        manual
        page={page}
        pages={totalPages}
        pageSizeOptions={[5, 10, 15, 20, 25, 50]}
        defaultSorted={sorted}
        minRows={products.length || undefined}
        onFetchData={handleFetchData}
      />
    </div>
  );
};

export default withConnected(ProductTable);
