import React from 'react';
import ReactTable from 'react-table';
import _ from 'lodash'
import withConnected from 'utilities/withConnected';

import styled from 'styled-components';

const Table = styled(ReactTable)`
    font-weight: bold;
`


const TotalsTable = ({ products }) => {

    const tableColumns = [
        {

            width: 100,
            sortable: false,
            Cell: () => (
                null
            )
        },
        {
            Cell: () => (
                null
            ),
            sortable: false,
            width: 120
        },
        {
            Cell: () => (
                null
            ),
            sortable: false,
            width: 100
        },
        {
            width: 230,
            sortable: false,
            Cell: (item) => (
                item.original.description
            )
        },
        {
            width: 170,
            sortable: false,
            Cell: (item) => {
                const { under, percentage } = item.original;
                if (!under) {
                    return '';
                }
                if (percentage) {
                    return `%${under}`
                }
                return `$${under}`
            }

        },
        {
            width: 170,
            sortable: false,
            Cell: (item) => {
                const { over, percentage } = item.original;
                if (!over) {
                    return '';
                }
                if (percentage) {
                    return `%${over}`
                }
                return `$${over}`
            }
        },
        {
            width: 170,
            sortable: false,
            Cell: (item) => {
                const { fine, percentage } = item.original;
                if (!fine) {
                    return '';
                }
                if (percentage) {
                    return `%${fine}`
                }
                return `$${fine}`
            }
        },
        {
            accessor: "status",
            width: 100,
            sortable: false,
            Cell: (item) => {
                const { total } = item.original;
                if (!total) {
                    return '';
                }
                return `$${total}`
            }
        }
    ];

    const pUnder = 50;
    const pOver = 60;
    const pFine = 75;

    const under = _.reduce(products, (sum, p) => {
        const { price, code } = p;
        if (price && _.toNumber(price) < 100 && _.toNumber(price && code !== '7')) {
            return sum + _.toNumber(price);
        }
        return sum
    }, 0)

    const over = _.reduce(products, (sum, p) => {
        const { price, code } = p;
        if (price && _.toNumber(price) >= 100 && _.toNumber(price && code !== '7')) {
            return sum + _.toNumber(price);
        }
        return sum
    }, 0)

    const fine = _.reduce(products, (sum, p) => {
        const { price, code } = p;
        if (price && code === '7') {
            return sum + _.toNumber(price);
        }
        return sum
    }, 0)

    const underTotal = (under * (pUnder * 0.01));
    const overTotal = (over * (pOver * 0.01));
    const fineTotal = (fine * (pFine * 0.01));

    const total = _.sum([underTotal, overTotal, fineTotal]);
    const data = [
        {
            description: 'Total in Sales',
            under: under,
            over: over,
            fine: fine
        },
        {
            description: 'Percent to Consignor',
            percentage: true,
            under: pUnder,
            over: pOver,
            fine: pFine
        },
        {
            description: 'Total Due to Consignor',
            under: underTotal.toFixed(2),
            over: overTotal.toFixed(2),
            fine: fineTotal.toFixed(2),
            total: total.toFixed(2)
        }
    ]

    return (
        <div>
            <Table
                showPagination={false}
                columns={tableColumns}
                data={data}
                minRows={1}
            />
        </div>
    );
}

export default withConnected(TotalsTable)