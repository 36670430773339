import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { serviceAxios as axios } from "../../bootstrap";
import withConnected from "utilities/withConnected";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import withStyles from "material-ui/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import { Field, reduxForm, formValueSelector } from "redux-form";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Unarchive from "@material-ui/icons/Unarchive";
import ImageUpload from "components/CustomUpload/ImageUpload";
import FormSwitch from "../Components/FormSwitch";
import SelectClient from "./SelectClient";
import SelectTaxes from "../Reports/SelectSquareTaxes";
import SelectLocation from "../Reports/SelectSquareLocation";
import CategorySelect from "./CategorySelect";
import SelectStatus from "views/Reports/SelectStatus";
import { connect } from "react-redux";
import StarCheckbox from "../../components/Checkbox/StarCheckbox";
import GuardedComponent from "components/Authorization/GuardedComponent";
const required = (value) => (value ? undefined : "Required");
const skuFormatRule = (value = "") =>
  /^[a-zA-z]{3}-?\d{6}$/.test(value)
    ? undefined
    : "SKU Format Error. The SKU format is 3 Letters, followed by 6 digits";

class CreateProductForm extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    const { rdx } = this.props;
    rdx.action.loadClients({enabled: 1});
    rdx.action.loadSquareLocations();
    rdx.action.loadSquareTaxes();
  }

  handleLocationChange(event, locationId) {
    const { change } = this.props;
    axios.get("/api/square/taxes").then((response) => {
      const defaultTax = locationId
        ? response?.data?.data?.filter(
            (tax) =>
              tax.present_at_all_locations ||
              tax.present_at_location_ids.includes(locationId)
          )[0]
        : undefined;
      if (defaultTax) {
        change("square_tax_id", defaultTax.id);
      }
    });
  }

  render() {
    const { handleSubmit, submitting, clientName, isReservedPrice } =
      this.props;
    const priceField = (
      <ItemGrid xs={12} sm={12} md={12}>
        <Field
          component={CustomInput}
          name="price"
          type="number"
          labelText={isReservedPrice ? "Reserved Price" : "Price"}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "number",
          }}
        />
      </ItemGrid>
    );

    return (
      <ItemGrid xs={12} sm={12} md={12}>
        <IconCard
          icon={Unarchive}
          iconColor="rose"
          title="Create Product"
          content={
            <div>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <ItemGrid xs={12} sm={12} md={12}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={6} md={6}>
                        <Field
                          validate={[required]}
                          component={CategorySelect}
                          name="category_id"
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6} md={6}>
                        <Field
                          component={CustomInput}
                          name="name"
                          type="text"
                          labelText="Description"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        <Field
                          component={CustomInput}
                          name="description"
                          type="text"
                          labelText="Notes"
                          inputProps={{ multiline: true, rows: 2, maxrows: 4 }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid
                    xs={12}
                    sm={12}
                    md={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ margin: "auto" }}>
                      <Field component={ImageUpload} name="picture" />
                    </div>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={12} md={12}></ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <Field
                          component={CustomInput}
                          name="sku"
                          type="text"
                          labelText="SKU *"
                          validate={[required, skuFormatRule]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <Field
                          component={SelectClient}
                          name="client_id"
                          clientName={clientName}
                          validate={[required]}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <Field
                          component={SelectStatus}
                          name="status"
                          type="text"
                          labelText="Select Status *"
                          validate={[required]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <Field
                          component={SelectLocation}
                          name="square_location_id"
                          type="text"
                          labelText="Select Location *"
                          onChange={this.handleLocationChange}
                          validate={[required]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </ItemGrid>
                      {priceField}
                      {/*
                      <ItemGrid xs={1} sm={1} md={1}>
                        <Field
                          component={StarCheckbox}
                          name="starred"
                          title="Highlight this product"
                          labelText=""
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </ItemGrid>
                      */}
                      <ItemGrid xs={12} sm={12} md={6}>
                        Reserved Price
                        <Field
                          component={FormSwitch}
                          name="is_reserved_price"
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        Signed
                        <Field component={FormSwitch} name="signed" />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <Field
                          component={SelectTaxes}
                          name="square_tax_id"
                          type="text"
                          labelText="Taxes"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
                <GuardedComponent scope={["products-create"]}>
                  <Button
                    color="rose"
                    right
                    type="submit"
                    disabled={submitting}
                  >
                    Create
                  </Button>
                </GuardedComponent>
              </form>
            </div>
          }
        />
      </ItemGrid>
    );
  }
}

const createProductSelector = formValueSelector("create_product");
const mapStateToProps = (state) => {
  return {
    isReservedPrice: createProductSelector(state, "is_reserved_price") || false,
    price: createProductSelector(state, "price") || 0,
  };
};

export default compose(
  withStyles(regularFormsStyle),
  withProps(({ product }) => {
    return product
      ? {
          initialValues: {
            name: product.name,
            sku: product.sku,
            category_id: product.category_id,
            description: product.description,
            notes: product.notes,
            price: product.price,
            starred: product.starred,
            is_reserved_price: product.is_reserved_price,
            client_id: product.client_id,
            signed: product.signed,
            square_tax_id: product.square_tax_id || "",
            square_location_id: product.square_location_id || "",
          },
          clientName: product.clientName,
        }
      : {
          initialValues: {
            square_tax_id: "",
            status: "Not Sold",
          },
        };
  }),
  reduxForm({
    form: "create_product",
  }),
  connect(mapStateToProps)
)(withConnected(CreateProductForm));
