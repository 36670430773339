import React, { Component } from "react";
import { compose } from "recompose";
import Table from "./ProductsTable";
import withConnected from "utilities/withConnected";
import CategorySelect from "views/Products/CategorySelect";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import SelectClient from "./SelectClient";
import SelectStatus from "./SelectStatus";
import ReportIcon from "@material-ui/icons/LibraryBooks";
import {
  Icon,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  LinearProgress,
} from "material-ui";
import IconButton from "material-ui/IconButton";

import DatePicker from "react-datepicker";
import Close from "@material-ui/icons/Close";
import update from "immutability-helper";

const FilterContainer = styled.div`
  margin-bottom: 10px;
`;

const InputWrapper = styled.div`
  .input-field {
    width: 90%;
  }
`;

const ActionsWrapper = styled.div`
  margin-right: 33px;
`;

const DatesWrapper = styled.div`
  margin-top: 41px;
  .react-datepicker-wrapper {
    margin-left: 40px;
  }
  input {
    font-size: 14px;
  }
  .material-icons {
    position: absolute;
  }
`;

const DatePick = styled(DatePicker)`
  background: none;
  border: none;
  border-bottom: 1px solid gray;
`;

const Progress = styled(LinearProgress)`
  margin-left: 20px;
  margin-right: 3px;
  border-top-right-radius: 20px;
  bottom: -26px;
  visibility: ${(props) => (props.loading ? "inherited" : "hidden")};
`;

class CreateReport extends Component {
  constructor() {
    super();
    this.state = {
      openSave: false,
      name: null,
    };
  }

  componentWillMount() {
    const { rdx } = this.props;
    rdx.action.loadClients({enabled: 1});
  }

  onSearch = () => {
    const { rdx } = this.props;
    const { form } = rdx.state;
    const values = form.report_create.values;
    rdx.action.loadProducts(values);
    this.setState({ searched: true });
  };

  onReset = (field) => {
    const { rdx, change } = this.props;
    const { form } = rdx.state;
    const values = { ...form.report_create.values };
    change(field, null);
    delete values[field];
    rdx.action.loadProducts(values);
  };

  onSave = () => {
    const { rdx } = this.props;
    const { form } = rdx.state;
    const { category_id, client_id, status, start_date, end_date } =
      form.report_create.values;
    const query = { category_id, client_id, status, start_date, end_date };

    rdx.action.createReport({ name: this.state.name, query });
  };

  onChange = (other) => {
    const { rdx, change } = this.props;
    const { form } = rdx.state;
    let values = form.report_create.values;
    const field = other[3];
    let value = other[1];

    change(field, value);

    if (field === "start_date" && !values.end_date) {
      // values = update(values, { ['end_date']: { $set: new Date() }})
      change("end_date", new Date());
    }

    rdx.action.loadProducts(update(values, { [field]: { $set: value } }));
  };

  filters = () => {
    const { rdx } = this.props;
    const { form } = rdx.state;
    let selectedClient = false;
    const values = form.report_create.values;
    if (values.client_id) {
      selectedClient = true;
    }

    let button = (
      <Button
        onClick={() => this.setState({ openSave: true })}
        disabled={!selectedClient}
        style={{ float: "right", marginRight: 20 }}
        color="rose"
        size=""
      >
        create
      </Button>
    );

    return (
      <FilterContainer>
        <form>
          <GridContainer>
            <ItemGrid xs={6}>
              <Field
                component={SelectClient}
                name="client_id"
                value={values.client_id}
                onChange={(...props) => this.onChange(props)}
              />
              <DatesWrapper>
                <GridContainer>
                  <ItemGrid className="end_date" xs={6} sm={6} md={6}>
                    <Icon color="primary">date_range</Icon>
                    <Field
                      name="start_date"
                      component={() => {
                        return (
                          <DatePick
                            placeholderText="Start date"
                            selected={values.start_date}
                            disabled={!selectedClient}
                            dateFormat="MMMM d, yyyy"
                            onChange={(date) => {
                              this.onChange([null, date, null, "start_date"]);
                            }}
                          />
                        );
                      }}
                    />
                  </ItemGrid>
                  <ItemGrid className="start_date" xs={6} sm={6} md={6}>
                    <Icon color="primary">date_range</Icon>
                    <Field
                      name="end_date"
                      component={() => {
                        return (
                          <DatePick
                            placeholderText="End date"
                            selected={values.end_date}
                            disabled={!selectedClient}
                            onChange={(date) =>
                              this.onChange([null, date, null, "end_date"])
                            }
                            dateFormat="MMMM d, yyyy"
                          />
                        );
                      }}
                    />
                  </ItemGrid>
                </GridContainer>
              </DatesWrapper>
            </ItemGrid>
            <ItemGrid xs={6}>
              <InputWrapper>
                <Field
                  component={CategorySelect}
                  name="category_id"
                  onOpen={() => {}}
                  value={values.category_id}
                  onChange={(...props) => this.onChange(props)}
                  classes={{ selectFormControl: "input-field" }}
                  disabled={!selectedClient}
                />
                {values.category_id ? (
                  <IconButton onClick={() => this.onReset("category_id")}>
                    <Close className="Close" />
                  </IconButton>
                ) : null}
              </InputWrapper>
              <InputWrapper>
                <Field
                  component={SelectStatus}
                  multiple
                  name="status"
                  value={values.status}
                  classes={{ selectFormControl: "input-field" }}
                  disabled={!selectedClient}
                  onChange={(...props) => this.onChange(props)}
                />
                {values.status ? (
                  <IconButton onClick={() => this.onReset("status")}>
                    <Close className="Close" />
                  </IconButton>
                ) : null}
              </InputWrapper>
              <ActionsWrapper>{button}</ActionsWrapper>
            </ItemGrid>
          </GridContainer>
        </form>
      </FilterContainer>
    );
  };

  render() {
    const { rdx } = this.props;
    const {
      reports: { products, tableSettings, loading },
    } = rdx.state;

    return (
      <div>
        <Dialog open={this.state.openSave}>
          <DialogTitle>Select a name for the report</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="name"
              fullWidth
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openSave: false, name: null })}
            >
              Cancel
            </Button>
            <Button onClick={this.onSave} color="rose">
              Create
            </Button>
          </DialogActions>
        </Dialog>
        {this.filters()}
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12}>
            <Progress loading={loading} />
            <IconCard
              icon={ReportIcon}
              iconColor="purple"
              title="&nbsp;"
              content={
                <div>
                  <Table
                    products={products}
                    settings={{
                      perPage: tableSettings?.per_page || 10,
                      page: tableSettings?.current_page - 1 || 0,
                      totalPages: tableSettings?.total_pages || 1,
                      sorted: tableSettings?.sorted || [],
                      search: tableSettings?.values || null,
                    }}
                  />
                </div>
              }
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: "report_create",
    initialValues: {},
  })
)(withConnected(CreateReport));
