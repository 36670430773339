import {
    SET_ERROR,
} from './error-actions';
import { stopSubmit } from 'redux-form';
import _ from 'lodash';
import { NotificationManager} from 'react-notifications';


export default function setError(options = {}) {
    return (dispatch, getState) => {

        if(options.httpCode && options.httpCode !== 422) {

            const error = {
                title: options.title || null,
                httpCode: options.httpCode || null,
                message: options.message || null,
            };

            dispatch({type: SET_ERROR, payload: { error }});
        }

        if(options.httpCode && options.httpCode === 422) {

            const errorsTransformed = {};

            _.map(Object.keys(options.errors), (param) => {
               errorsTransformed[param] = options.errors[param][0];
            });

            NotificationManager.warning(options.message, "Warning");

            dispatch(stopSubmit(options.action, errorsTransformed));
        }
    }
};