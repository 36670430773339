import ReportIcon from "@material-ui/icons/LibraryBooks";
import Reports from 'views/Reports';
import ReportDetail from 'views/Reports/ReportDetail'
import ReportCreate from 'views/Reports/CreateReport';
import ReportEdit from 'views/Reports/EditReport';
import Summary from "views/Reports/Summary";


export const reportsInner = [
    {
        path: "/reports/list",
        parentPath: "/reports/",
        name: "Reports",
        icon: ReportIcon,
        component: Reports,
        hideInMenu: false,
        collapse: true,
        views: [{
            path: "/reports/list",
            component: Reports,
            name: "List",
        },{
            path: "/reports/archive",
            component: Reports,
            name: "Archive",
        },
        {
            path: "/reports/summary",
            component: Summary,
            name: "Summary",
        }],
        acl: ['reports-read']
    },
    {
        path: "/reports/detail/:id",
        name: "Report Detail",
        icon: ReportIcon,
        component: ReportDetail,
        hideInMenu: true,
        matchUrl: '/reports/detail'
    },
    {
        path: "/reports/edit/:id",
        name: "Report Edit",
        icon: ReportIcon,
        component: ReportEdit,
        hideInMenu: true,
        matchUrl: '/reports/edit'
    },
    {
        path: "/reports/create",
        name: 'Create Report',
        icon: ReportIcon,
        component: ReportCreate,
        hideInMenu: true,
    
    },
]