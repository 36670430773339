import Token from 'models/Token';
import User from 'models/User';
import { push } from 'react-router-redux'

import {
    LOGOUT_SUCCESS
} from './auth-reducer'

export default function logout() {
    return (dispatch, getState) => {
        Token.saveToLocalStorage(null);
        User.saveToLocalStorage(null);
        dispatch({type: LOGOUT_SUCCESS });
        dispatch(push('/auth/login'));
    }
};