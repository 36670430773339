import React from "react";

import Grid from "material-ui/Grid";

// core components
import Fade from "material-ui/transitions/Fade";

import { Paper } from "material-ui";

const CategoryThumbView = ({ categories, onClick, children, productId }) => {
    return (
        <div style={{ height:"100%" }} >
            {
                categories.length === 0 || productId ?
                  children : (
                <Grid container style={{height:"auto"}}>
                    {
                    categories.map( category => (
                    <Grid item key = { category.id } xs={6} sm={3} md={3}  >
                        <Fade in timeout={800}>
                            <Paper
                                style= {{ height:"100px", margin:10, padding:"25px 0", textAlign: "center", cursor: "pointer"}}
                                onClick = { () => onClick(category.id) }
                            >
                                <h4>{category.name}</h4>
                            </Paper>
                        </Fade>
                    </Grid>    )
                    )}
                </Grid>
            )
            }
        </div>
    );
};

export default CategoryThumbView;
