import _ from 'lodash';

export default function makeBrand(routes, location) {

    let name = null;

    _.map(routes, (route) => {

        if(route.collapse) {

            for(let i = 0; i < route.views.length; i++) {

                const subRoute = route.views[i];

                if(location.pathname.indexOf(subRoute.matchUrl) !== -1) {
                    name = subRoute.name;
                }
            }
        }

        if(!route.collapse && location.pathname.indexOf(route.matchUrl) !== -1) {
            name = route.name;
        }
    });

    return name;
};