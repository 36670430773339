import {
    SET_ERROR
} from './error-actions';

const defaultState = {
    error: null,
};

const errorReducer = (state = defaultState, action) => {
    switch(action.type) {
        case(SET_ERROR):
            return ({
                ...state,
                error: action.payload.error
            });
        default:
            return state;
    }
};

export default errorReducer;