import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import {
    ADD_TO_CART,
    REMOVE_CART_ITEM,
    TOGGLE_CHECKOUT,
    PURCHASE_COMPLETED
} from '../types';

const INITIAL_SALES_STATE = {
    items: [],
    search: {},
    total: null,
    checkout: false
}
export default handleActions(
    {
        [ADD_TO_CART]: (state, action) => {
            return update(state, {
                items: { $push: [action.payload] }
            })
        },
        [REMOVE_CART_ITEM]: (state, action) => {
            const index = state.items.findIndex((t) => { return(t.id === action.payload) });
            return update(state, {
                items: { $splice:[[index, 1]]}
            })
        },
        [TOGGLE_CHECKOUT]: (state, action) => {
            return update(state, {
                $toggle: ['checkout']
            })
        },
        [PURCHASE_COMPLETED]: (state ) => {
            return {
                items: [],
                search: {},
                total: null
            }
        }
    },
    INITIAL_SALES_STATE
)