import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { compose, withProps } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import GuardedComponent from "components/Authorization/GuardedComponent";

const EditCategoryForm = (props) => {
    const {
        handleSubmit,
        submitting,
        image
    } = props;

    return (
        <GridContainer>
            <ItemGrid xs={12} sm={12} md={12}>
                <IconCard
                    icon={WidgetsIcon}
                    iconColor="rose"
                    title="Edit Category"
                    content={
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6} style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                    <div style={{ margin: "auto" }}>
                                        <Field 
                                            component={ ImageUpload }
                                            name = "picture" 
                                            imageURL = { image }/>
                                    </div>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='name'
                                        labelText="Name"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>

                            <GuardedComponent scope={['categories-edit']}>
                                <Button color="rose" right type="submit" disabled={submitting}>
                                    Submit
                                </Button>
                            </GuardedComponent>
                        </form>
                    }
                />
            </ItemGrid>
        </GridContainer>
    )
};

export default compose(
    withProps(({ category }) => {
        return {
            initialValues: {
                name: category.name,
            }
        }
    }),
    reduxForm({
        form: 'edit_category'
    })
)(EditCategoryForm);