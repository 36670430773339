import {
    GET_USER_START,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
} from './auth-reducer';
import { serviceAxios as axios } from '../../bootstrap';
import User from 'models/User';
import setError from 'redux/general/error-actioncreator';

export default function getUser() {
    return (dispatch) => {

        dispatch({ type: GET_USER_START });

        axios.post('/api/me')
        .then(response => {
            const user = User.createFromResponse(response.data.data);

            dispatch({type: GET_USER_SUCCESS, payload: { user: user }});
        })
        .catch(error => {
            dispatch({ type: GET_USER_FAILED });

            const httpCode = error.response ? error.response.status : 0

            dispatch(setError({
                title: 'errors.general',
                httpCode: httpCode,
                message: 'users.failed_to_get_user_info'
            }));
        });
    }
};