import React, { Component } from 'react';
import Table from './ProductsTable';
import withConnected from 'utilities/withConnected';

import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import styled from 'styled-components';

import ReportIcon from "@material-ui/icons/LibraryBooks";
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, LinearProgress } from 'material-ui';

import EditForm from './EditReportForm';

const Progress = styled(LinearProgress)`
    margin-left: 20px;
    margin-right: 3px;
    border-top-right-radius: 20px;
    bottom: -26px;
    visibility: ${ props => props.loading ? 'inherited' : 'hidden'};
`

class EditReport extends Component {

    constructor() {
        super();
        this.state = {
            openSave: false,
            name: null
        }
    }

    componentWillMount() {
        const { rdx, match } = this.props;
        rdx.action.getReport(match.params.id)
        rdx.action.loadClients({ enabled: 1});
    }

    onSearch = () => {
        const { rdx } = this.props;
        const { form } = rdx.state;
        const values = form.report_create.values
        rdx.action.loadProducts(values)
        this.setState({ searched: true})
    }

    onSave = () => {
        const { rdx } = this.props;
        const { form, reports} = rdx.state;
        rdx.action.editReport(reports.detail.id, form.update_report.values)
    }

    render() {
        const { rdx } = this.props;
        const { reports } = rdx.state;
        const { detail } = rdx.state.reports;

        return (
            <div>
                <Dialog
                    open={this.state.openSave}
                >
                    <DialogTitle>Select a name for the report</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="name"
                            fullWidth
                            onChange={(e)=> this.setState({ name: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ openSave: false, name: null })}>
                            Cancel
                        </Button>
                        <Button onClick={this.onSave} color="rose">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    detail ? <EditForm onSave={this.onSave} onChange={this.onChange} report={detail} rdx={rdx} /> : null
                }
                <ItemGrid xs={12} sm={12} md={12}>

                    <Progress loading={reports.loading}/>
                    <IconCard
                        icon={ ReportIcon }
                        iconColor="purple"
                        title='Edit report'
                        content={
                            <div>
                                <Table products={reports.products}/>
                            </div>
                        }
                    />
                </ItemGrid>
            </div>
        );
    }
}

export default withConnected(EditReport);
