import { MAX_CHART_ITEMS, pieSummaryCharts } from "constants/charts";
import { DAY, MONTH, WEEK, YEAR } from "constants/selects";

import moment from "moment";

export const getDataFilteredByConsignor = (
  consignorId = "",
  products = [],
  transactions = []
) => {
  let filteredProducts = products instanceof Array ? products : [];
  let filteredTransactions = transactions instanceof Array ? transactions : [];

  if (consignorId) {
    const productsIds = new Set();

    filteredProducts = filteredProducts.filter(({ id, client_id }) => {
      const keep = !consignorId || client_id === Number(consignorId);

      if (keep) {
        productsIds.add(id);
        return true;
      }

      return false;
    });

    filteredTransactions = filteredTransactions.map(
      ({ items = [], ...transaction }) => {
        const filteredItems =
          items instanceof Array
            ? items.filter((id) => productsIds.has(id))
            : [];
        return {
          ...transaction,
          items: filteredItems,
        };
      }
    );
  }

  return [filteredProducts, filteredTransactions];
};

export const getPricesRange = (products = []) => {
  let lower = null;
  let larger = null;

  products.forEach(({ price }) => {
    const value = Number(price);

    if (lower === null || larger === null) {
      lower = value;
      larger = value;
    } else if (value < lower) {
      lower = value;
    } else if (value > larger) {
      larger = value;
    }
  });

  return { lower, larger };
};

export const mapPricesSummary = (
  pricesRanges,
  { total, step, empty = false }
) => {
  const pricesSummary = new Map();

  for (let i = 1; i <= total; i++) {
    const prevRangeValue = step * (i - 1) + 1;
    const currentRangeValue = step * i;

    const count = pricesRanges.get(i) ?? 0;

    if (!empty && count === 0) {
      continue;
    }

    if (i === 1) {
      pricesSummary.set(`Free-$${currentRangeValue}`, count);
    } else if (i === total) {
      pricesSummary.set(`>$${prevRangeValue}`, count);
    } else {
      pricesSummary.set(`$${prevRangeValue}-$${currentRangeValue}`, count);
    }
  }

  return pricesSummary;
};

export const mapConsignorsSummary = (
  consignorsMap = new Map(),
  consignors = [],
  { empty = false } = {}
) => {
  const consignorsSummary = new Map();

  consignors.forEach(({ id, firstName, lastName }) => {
    const fullName = `${firstName} ${lastName}`;
    const count = consignorsMap.get(id) ?? 0;
    if (empty || count > 0) {
      consignorsSummary.set(fullName, count);
    }
  });

  return consignorsSummary;
};

export const mapCategoriesSummary = (
  categoriesMap = new Map(),
  categories = [],
  { empty = false } = {}
) => {
  const categoriesSummary = new Map();

  categories.forEach(({ id, name }) => {
    const count = categoriesMap.get(id) ?? 0;
    if (empty || count > 0) {
      categoriesSummary.set(name, count);
    }
  });

  return categoriesSummary;
};

export const getSummaryData = (
  products = [],
  consignors = [],
  categories = []
) => {
  const categoriesMap = new Map();
  const statusSummary = new Map();
  const pricesRanges = new Map();
  const consignorsMap = new Map();

  const { lower, larger } = getPricesRange(products);

  const diff = larger - lower;
  const step = Math.round(diff / MAX_CHART_ITEMS);

  for (const product of products) {
    const {
      status,
      category_id: categoryId,
      price,
      client_id: consignorId,
    } = product;

    // Categories
    if (categoriesMap.has(categoryId)) {
      categoriesMap.set(categoryId, categoriesMap.get(categoryId) + 1);
    } else {
      categoriesMap.set(categoryId, 1);
    }

    // Status
    if (statusSummary.has(status)) {
      statusSummary.set(status, statusSummary.get(status) + 1);
    } else {
      statusSummary.set(status, 1);
    }

    // Prices ranges
    const value = Number(price);
    for (let i = 1; i <= MAX_CHART_ITEMS; i++) {
      const prevRangeValue = step * (i - 1);
      const currentRangeValue = step * i;

      // Set count +1 for a value in current range or larger price out of range
      if (
        (value >= prevRangeValue && value < currentRangeValue) ||
        i === MAX_CHART_ITEMS
      ) {
        if (pricesRanges.has(i)) {
          pricesRanges.set(i, pricesRanges.get(i) + 1);
        } else {
          pricesRanges.set(i, 1);
        }
        break;
      }
    }

    // Consignors
    if (consignorsMap.has(consignorId)) {
      consignorsMap.set(consignorId, consignorsMap.get(consignorId) + 1);
    } else {
      consignorsMap.set(consignorId, 1);
    }
  }

  // Map categories names
  const categoriesSummary = mapCategoriesSummary(categoriesMap, categories);

  // Map prices ranges
  const pricesSummary = mapPricesSummary(pricesRanges, {
    total: MAX_CHART_ITEMS,
    step,
  });

  // Map consignors names
  const consignorsSummary = mapConsignorsSummary(consignorsMap, consignors);

  return {
    categoriesSummary,
    statusSummary,
    pricesSummary,
    consignorsSummary,
  };
};

export const getChartData = (data = new Map()) => {
  const labels = [];
  const series = [];
  const items = [];

  const arry = [...data.entries()];
  const ordered = arry.sort(([, valueA], [, valueB]) => valueB - valueA);

  const total = ordered.reduce((acc, [, count]) => {
    return (acc += count);
  }, 0);

  const maxIndex = MAX_CHART_ITEMS - 1;
  for (let i = 0; i < ordered.length; i++) {
    const [name, count] = ordered[i];
    const percentage = total ? (count * 100) / total : 0;

    if (i > maxIndex) {
      const { value: prevPercentage, total: prevTotal } = series[maxIndex];
      const newPercentage = prevPercentage + percentage;
      const newTotal = prevTotal + count;
      items[maxIndex] = `Others (${newTotal})`;
      labels[maxIndex] = `${Math.round(newPercentage)}%`;
      series[maxIndex] = {
        value: newPercentage,
        total: newTotal,
      };
    } else {
      labels.push(`${Math.round(percentage)}%`);
      series.push({
        value: percentage,
        total: count,
      });
      items.push(`${name} (${count})`);
    }
  }

  return [
    {
      labels,
      series,
    },
    items,
  ];
};

export const getChartsData = (summary) => {
  const {
    categoriesSummary = new Map(),
    statusSummary = new Map(),
    pricesSummary = new Map(),
    consignorsSummary = new Map(),
  } = summary;

  const charts = pieSummaryCharts.map(({ id, title, icon, iconColor }) => {
    let summary = null;
    switch (id) {
      case "category":
        summary = categoriesSummary;
        break;
      case "status":
        summary = statusSummary;
        break;
      case "price":
        summary = pricesSummary;
        break;
      case "consignors":
      default:
        summary = consignorsSummary;
    }

    const [data, items] = getChartData(summary);

    return {
      id,
      title,
      icon,
      iconColor,
      data,
      items,
    };
  });

  return charts;
};

// Not used yet
export const getPeriodRange = (products = []) => {
  let start = null;
  let end = null;

  products.forEach(({ created_at }) => {
    const value = moment(created_at);

    if (start === null || end === null) {
      start = value;
      end = value;
    } else if (value.isBefore(start)) {
      start = value;
    } else if (value.isAfter(end)) {
      end = value;
    }
  });

  return { start, end };
};

export const fillPeriodsEntries = (
  arrRef = [],
  dates = [],
  { items = 1, created_at = new Date(), type = DAY, fragments = 12 }
) => {
  const createdAt = moment(created_at);
  for (let i = 0; i < fragments; i++) {
    const month = dates[i];
    const firstMonthDay = moment(month).startOf(type);
    const lastMonthDay = moment(month).endOf(type);
    if (createdAt.isBetween(firstMonthDay, lastMonthDay)) {
      arrRef[i] += items;
      break;
    }
  }
};

export const getOperationsData = (
  products = [],
  orders = [],
  { type = DAY, fragments = 12 } = {}
) => {
  const ins = [];
  const outs = [];
  const labels = [];
  const dates = [];

  const NOW = moment(new Date());
  const startPeriod = moment(NOW).add(-fragments, type);

  let format;
  switch (type) {
    case YEAR:
      format = "Y";
      break;
    case MONTH:
      format = "MMM";
      break;
    case DAY:
    default:
      format = "ddd";
  }

  for (let i = 1; i <= fragments; i++) {
    const currentPeriod = moment(startPeriod).add(i, type);
    let name;
    if (type === WEEK) {
      name = `Wk ${moment(currentPeriod).week()}`;
    } else {
      name = moment(currentPeriod).format(format);
    }
    ins.push(0);
    outs.push(0);
    labels.push(name);
    dates.push(currentPeriod);
  }

  products.forEach(({ created_at }) => {
    fillPeriodsEntries(ins, dates, { created_at, type, fragments });
  });

  orders.forEach(({ items = [], created_at }) => {
    fillPeriodsEntries(outs, dates, {
      items: items instanceof Array ? items.length : 0,
      created_at,
      type,
      fragments,
    });
  });

  return [
    {
      ins,
      outs,
    },
    labels,
  ];
};

export const getOperationsChartsData = (operations = []) => {
  const [serie, labels] = operations;

  const operationChartData = {
    labels,
    series: [serie.ins, serie.outs],
  };

  return operationChartData;
};
