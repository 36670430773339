import React from 'react'

import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";

import withStyles from "material-ui/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

function ClientSelect(props) {
        const {
            clientName,
            input: {
                value,
                onChange
            },
            classes,
            disabled
        } = props;

        return (
            <FormControl
            fullWidth
            className={classes.selectFormControl}>
                <InputLabel
                    className={classes.selectLabel}
                    htmlFor="simple-select">
                    Consignor
                </InputLabel>
                <Select 
                    value={value || ''}
                    onOpen={() => {}}
                    onChange={onChange}
                    disabled={disabled}
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                    }}>
                    <MenuItem disabled classes={{
                        root: classes.selectMenuItem
                        }}>
                        Choose Consignor
                    </MenuItem>
                    <MenuItem value={value} classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                        }}>
                        { clientName }
                    </MenuItem>

                </Select>
            </FormControl>
        )
}

export default withStyles(extendedFormsStyle)(ClientSelect)