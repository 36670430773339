import React from 'react';
import { BarLoader } from 'react-css-loaders';
import { serviceAxios as axios } from '../../bootstrap';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import withStyles from 'material-ui/styles/withStyles';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";
import CategoriesTree from './CategoriesTree';

class CategoriesListing extends React.Component {
    state = {
        isLoadingCategories: false,
        categories: null,
        alert: null,
        searchCriteria: '',
    }

    componentDidMount() {
        document.title = 'EPOS - Categories';

        if (!this.state.isLoadingCategories) {
            this.getCategories();
        }
    }

    getCategories = () => {
        axios.get('/api/categories')
            .then(response => {
                this.setState({
                    isLoadingCategories: false,
                    categories: response.data.data
                })
            }).catch(error => {
                this.setState({ submitting: false })
                this.showAlert("Retreiving Categories Failed", extractErrorMessage(error))
            })
    }

    deleteCategories = (id) => {
        axios.delete(`/api/categories/${id}`)
            .then(response => {
                this.getCategories();

            }).catch(error => {
                this.showAlert("Delete Failed", extractErrorMessage(error))
            })
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(title, message) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title={title}
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }


    deleteCategoryAlert = (category) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    title={"Delete category - " + category.name + " ?"}
                    onConfirm={() => {
                        this.hideAlert();
                        this.deleteCategories(category.id)
                    }}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.default
                    }
                >
                    Are you sure you want to delete? The category will be deleted immediately, and can't be restored.
                </SweetAlert>
            )
        });
    }

    debounceEventHandler = (...args) => {
        const debounced = _.debounce(...args)
        return function (e) {
            e.persist()
            return debounced(e)
        }
    }

    handleInputChange = (e) => {
        const searchCriteria = e.target.value.toLowerCase()
        this.setState({ searchCriteria })
    }

    render() {
        const classes = this.props.classes;
        return (
            <div>
                <CustomInput
                    id="search"
                    labelText="Search for Category name"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        autoComplete: 'off',
                        onChange: this.debounceEventHandler(this.handleInputChange, 900),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                    }}
                    meta={{ touched: false, error: false }}
                />
                { this.state.alert}
                { (this.state.isLoadingCategories || !this.state.categories) ?
                    <BarLoader color="#d81b60" size={8} />
                    :
                    <CategoriesTree
                        categories={this.state.categories}
                        searchString={this.state.searchCriteria}
                    />
                }
            </div>
        )
    }
}

export default withStyles(buttonStyle)(CategoriesListing)