import _ from 'lodash';

class BaseModel {

    constructor(props) {
        _.assignIn(this, this.mergePropsWithDefaults(props));
    }

    mergePropsWithDefaults(props) {

        const defaults = this.getDefaults();

        _.forEach(_.keys(props), (propertyName) => {
            if(!_.hasIn(defaults, propertyName)){
                delete props[propertyName];
            }
        });

        return _.mergeWith(props, defaults, (propValue, defaultValue, key, props, defaults, stack) => {
            return _.isUndefined(propValue) ? defaultValue : propValue;
        });
    }

    getDefaults() {
        return {};
    }
}

export default BaseModel;