export const YEAR = "year";
export const MONTH = "month";
export const WEEK = "week";
export const DAY = "day";

export const INTERVAL_OPTIONS = [
  { value: YEAR, label: "Years" },
  { value: MONTH, label: "Months" },
  { value: WEEK, label: "Weeks" },
  { value: DAY, label: "Days" },
];
