import React from 'react'

let extractErrorMessage = (error) => {
    let message = "" + error && error.message
    let errors = null
    if ( error && error.response && error.response.data ) {
        errors =  error.response.data.errors
        if (!errors) {
            errors = error.response.data
        }
    }
    const messageHTML = ( <div>
        { message && <p><b>{message}</b></p> }
        { errors && !(typeof errors === 'string' || errors instanceof String) &&
            Object.keys(errors).filter((_, i) => i < 5).map( key => (
                <p>{key} : {errors[key]}</p>
            ))
        }
    </div> )
    return messageHTML
}

export default extractErrorMessage