import React from "react";
import bwipjs from "bwip-js";

const pageSize = {
  width: 8,
  height: 11
};
const rowOffset = 0.66;
const pageHeadOffset = 0.59;
const skuCodeLength = 3;
const skuDigits = 6;

class ClientBarcodes extends React.Component {
  async componentDidMount() {
    document.title = "EPOS - Client Barcodes";
    await this.renderAllBarcodes(
      this.props.clientCode,
      Number.parseInt(this.props.startNumber, 10),
      this.props.barcodesQuantity
    );
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.clientId !== this.props.clientId ||
      prevProps.startNumber !== this.props.startNumber ||
      prevProps.barcodesQuantity !== this.props.barcodesQuantity) {
      
        await this.renderAllBarcodes(
        this.props.clientCode,
        Number.parseInt(this.props.startNumber, 10),
        this.props.barcodesQuantity
      );
    }
  }

  startLeft = [0.387, 2.437, 4.487, 6.537];
  startLeftInches = this.startLeft.map(x => `${x + 0.1}in`);

  leftPad(number, targetLength) {
    var output = number + "";
    while (output.length < targetLength) {
      output = "0" + output;
    }
    return output;
  }

  async renderAllBarcodes(clientCode, startNumber, count) {
    let clientCode3 = this.leftPad(clientCode, skuCodeLength);
    for (let i = 0; i < count; i++) {
      let code = `${clientCode3}${this.leftPad(i + startNumber, skuDigits)}`;
      await this.renderIntoCanvas(`mycanvas${i}`, code);
    }
  }

  async renderIntoCanvas(canvasName, barcodeText) {
    return new Promise((resolve, reject) => {
      bwipjs(
        canvasName,
        {
          bcid: "code128", // Barcode type
          text: barcodeText, // Text to encode
          scale: 3, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center" // Always good to set this
        },
        function (err, cvs) {
          if (err) {
            return reject(err);
          }
          resolve();
        }
      );
    });
  }

  codeScanned = code => {
    this.setState(state => ({
      messages: state.messages.concat([code])
    }));
  };

  render() {
    const { barcodesQuantity } = this.props;
    return (
      <div style={{ position: "relative" }}>
        {new Array(barcodesQuantity).fill(0).map((i, index) => (
          <canvas
            id={`mycanvas${index}`}
            key={index}
            style={{
              width: "1.5in",
              left: this.startLeftInches[index % 4],
              position: "absolute",
              top: `${pageHeadOffset +
                rowOffset * (Math.floor(index / 4) % 15) + // Row offset
                (pageSize.height) * Math.floor(index / 60)}in` // Page offset
            }}
          />
        ))}
      </div>
    );
  }
}


export default ClientBarcodes;