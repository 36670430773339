import _ from 'lodash';

const sameAs = {};

sameAs.expects = [
    'target',
];

sameAs.message = 'does not match';

sameAs.validate = (value, { config: { input }, target }) => {
    return _.has(input, target) && input[target] === value;
};

export default sameAs;