import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose, lifecycle } from "recompose";
import ChartistGraph from "react-chartist";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CircularProgress from "@material-ui/core/CircularProgress";

import IconCard from "components/Cards/IconCard";
import ChartFooter from "components/ChartFooter";
import ChartFooterInterval from "components/ChartFooterInterval";
import Select from "components/Select";

import { PIE_OPTIONS, BAR_OPTIONS } from "constants/charts";
import { MONTH } from "constants/selects";

import withConnected from "utilities/withConnected";
import {
  getChartsData,
  getDataFilteredByConsignor,
  getOperationsChartsData,
  getOperationsData,
  getSummaryData,
} from "utilities/helpers/products";
import { fetchAll } from "utilities/api";
import { getConsignorsSelectData } from "utilities/selects";

import styles from "./SummaryStyles";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      process: false,
      consignorId: "",
      intervalId: MONTH,
      clients: null,
      products: null,
      categories: null,
      transactions: null,
      consignors: [],
      pieCharts: [],
      operationChart: null,
    };
  }

  async componentWillMount() {
    try {
      const promises = await Promise.all([
        fetchAll("clients"),
        fetchAll("products"),
        fetchAll("categories"),
        fetchAll("transactions"),
      ]);

      const [clients, products, categories, transactions] = promises;

      this.setState((state) => ({
        ...state,
        clients,
        products,
        categories,
        transactions,
        loading: false,
        process: true,
      }));
    } catch (error) {
      console.error(`componentWillMount - error: `, error);
    }
  }

  componentDidUpdate() {
    const {
      process,
      clients,
      products,
      categories,
      transactions,
      consignorId,
      intervalId,
    } = this.state;

    if (process) {
      const consignors = getConsignorsSelectData(clients);

      const [filteredProducts, filteredTransactions] =
        getDataFilteredByConsignor(consignorId, products, transactions);

      const summary = getSummaryData(filteredProducts, clients, categories);
      const pieCharts = getChartsData(summary);

      const operations = getOperationsData(
        filteredProducts,
        filteredTransactions,
        { type: intervalId }
      );
      const operationChart = getOperationsChartsData(operations);

      this.setState((state) => ({
        ...state,
        consignors,
        pieCharts,
        operationChart,
        process: false,
      }));
    }
  }

  handleChange(key, value) {
    this.setState((state) => ({
      ...state,
      [key]: value,
      process: true,
    }));
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      consignorId,
      consignors,
      intervalId,
      pieCharts,
      operationChart,
    } = this.state;

    if (loading) {
      return (
        <div className={classes.container}>
          <CircularProgress size={60} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.filters}>
          <Select
            className={classes.consignor}
            label="SELECT A CONSIGNOR *"
            value={consignorId}
            items={consignors}
            onChange={(event) =>
              this.handleChange("consignorId", event.target.value)
            }
          />
        </div>
        <div className={classes.pieCharts}>
          {pieCharts.map(
            ({ title, icon, iconColor = "red", data, items }, index) => (
              <IconCard
                key={index}
                className="card"
                icon={icon}
                iconColor={iconColor}
                title={title}
                content={
                  <ChartistGraph
                    className={classes.chart}
                    type="Pie"
                    data={data}
                    options={PIE_OPTIONS}
                  />
                }
                footer={<ChartFooter items={items} />}
              />
            )
          )}
        </div>
        <div className={classes.barChart}>
          <IconCard
            className="card"
            icon={QueryBuilderIcon}
            iconColor="rose"
            title="Operations by Period"
            content={
              <ChartistGraph
                className={classes.chart}
                type="Bar"
                data={operationChart}
                options={BAR_OPTIONS}
              />
            }
            footer={
              <ChartFooterInterval
                value={intervalId}
                onChange={(event) =>
                  this.handleChange("intervalId", event.target.value)
                }
              />
            }
          />
        </div>
      </div>
    );
  }
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withConnected,
  withStyles(styles),
  lifecycle({
    componentDidMount() {
      document.title = "EPOS - Reports";
    },
  })
)(Summary);
