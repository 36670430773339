import React from 'react'
import { BarLoader } from 'react-css-loaders';

export default function OverlayLoader() {
    return (
        <div style={
            {
                width: "100%", 
                height: "100%",
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "white",
                opacity: 0.3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
            <BarLoader />
        </div>
    )
}