import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import styles from "./ChartFooterStyles";
import ChartFooterItem from "components/ChartFooterItem/ChartFooterItem";
import { CHART_COLORS } from "constants/charts";

const ChartFooter = (props) => {
  const { classes, items = [] } = props;

  return (
    <div>
      <h6 className={classes.title}>Legend</h6>
      <div className={classes.list}>
        {items.map((item, index) => {
          const color = CHART_COLORS[index] ?? 'default'
          return (
          <ChartFooterItem key={index} color={color} name={item} />
        )})}
      </div>
    </div>
  );
};

ChartFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(ChartFooter);
