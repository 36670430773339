import _ from 'lodash';

const requiredIf = {};

requiredIf.expects = [
    'target',
];

requiredIf.message = 'is required because of other field';

requiredIf.validate = (value, { config: { input }, target }) => {
    return _.has(input, target) && !!input[target];
};

export default requiredIf;