import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// @material-ui/icons
import AppBar from "material-ui/AppBar/AppBar";
import Tabs from "material-ui/Tabs/Tabs";
import Tab from "material-ui/Tabs/Tab";
import ArrowRight from "@material-ui/icons/ChevronRight";
import { roseColor } from "../../assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: roseColor,
    borderRadius: "4px",
  },
  tab: {
    fontWeight: "normal",
    minWidth: "50px",
    color: theme.palette.common.white,
    opacity: 0.9,
  },
  doneButton: {
    fontWeight: "bold",
    opacity: 1,
    marginLeft: "auto",
    "&:hover": {
      fontWeight: "normal",
    },
  },
});

class BreadcrumbCard extends React.Component {
  state = {
    value: 0,
    currentCategory: {}
  };

  handleChange = (event, id) => {
    this.props.onClick(id);
    event.stopPropagation();
  };

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.tabs) !== JSON.stringify(this.props.tabs)){
      const currentCategory = this.props.tabs[this.props.tabs.length - 1]
      this.setState({currentCategory})
    }
  }

  render() {
    const { classes, tabs, modal, onSelect } = this.props;

    return (
      <AppBar position="static" color="default" className={classes.root}>
        <Tabs value={0} scrollable scrollButtons="off" textColor="inherit">
          {tabs.map(({ tabName, id }, key, { length }) => {
            return (
              <React.Fragment key={key}>
                <Tab
                  className={classes.tab}
                  fullWidth={false}
                  label={tabName}
                  onClick={(evt) => {
                    this.props.onClick(id);
                    evt.stopPropagation();
                  }}
                />
                {key !== length - 1 && (
                  <ArrowRight
                    style={{
                      width: "auto",
                      height: "15px",
                      color: "#dddddd",
                      marginTop: "17px",
                      verticalAlign: "middle",
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
          { 1 === 0 && modal && tabs.length > 1 ? (
            <Tab
              className={classes.doneButton}
              fullWidth={false}
              label={"Done"}
              onClick={(evt) => {
                onSelect(this.state.currentCategory.id);
                evt.stopPropagation();
              }}
            />
          ) : (
            ""
          )}
        </Tabs>
      </AppBar>
    );
  }
}

BreadcrumbCard.defaultProps = {
  headerColor: "purple",
};

BreadcrumbCard.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
    })
  ),
};

export default withStyles(styles)(BreadcrumbCard);
