import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support

import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import App from 'App';
import store, { history } from './store';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/material-dashboard-pro-react.css?v=1.1.1";
import 'react-sortable-tree/style.css';
import * as Sentry from '@sentry/browser';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

Sentry.init({ dsn: "https://98440d6ae77c4257866ae721603f8a79@sentry.io/1757608" });

const Component = () => {

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <App />
                    <NotificationContainer />
                </MuiPickersUtilsProvider>
            </ConnectedRouter>
        </Provider>
    )
};

ReactDOM.render(<Component />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();