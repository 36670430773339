import Auth from "layouts/Auth.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import Recover from "layouts/Recover.jsx";

const indexRoutes = [
  { path: "/auth", name: "Auth", component: Auth },
  { path: "/recover", name: "Recover", component: Recover },
  { path: "/", name: "Home", component: Dashboard },
];

export default indexRoutes;
