import Validator from '../Validator';

class LoginValidator extends Validator {
    rulesDefinition() {
        return {
            username: {
                required: true,
                stop: true,
            },
            password: {
                stop: true,
                required: true
            },
        };
    }
}

export default LoginValidator;