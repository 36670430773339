import React from "react";
import { BarLoader } from "react-css-loaders";

import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { serviceAxios as axios } from "../../bootstrap";
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import ClientReportTable from "./ClientReportTable";
import extractErrorMessage from "../../utilities/HttpErrorResponse";
import SweetAlert from "react-bootstrap-sweetalert";

class ClientReport extends React.Component {
  state = {
    isLoadingReport: false,
    alert: null,
    report: [],
    tableSettings: null,
  };

  componentDidMount() {
    document.title = "EPOS - Consignor Report";

    if (!this.state.isLoadingReport) {
      this.getReport();
    }
  }

  getReport = ({ page = 1, pageSize = 15, sorted = [], search } = {}) => {
    this.setState({ isLoadingReport: true }, () => {
      const clientId = parseInt(this.props.match.params.id, 10);
      const sort = sorted?.[0]?.id;
      const sortDirection = sorted?.[0]?.desc ? "desc" : "asc";
      const queryParams = {
        page,
        per_page: pageSize,
        sort,
        sort_direction: sortDirection,
        client_id: clientId,
      };
      if (search) {
        queryParams.search = search;
      }
      axios
        .get(`/api/products`, {
          params: queryParams,
        })
        .then((response) => {
          this.setState({
            report: response.data.data,
            tableSettings: {
              sorted,
              search,
              ...response.data.meta?.pagination,
            },
            isLoadingReport: false,
          });
        })
        .catch((error) => {
          this.setState({ isLoadingReport: false });
          this.showAlert(
            "Retrieving Customer Report Failed",
            extractErrorMessage(error)
          );
        });
    });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  showAlert(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  render() {
    const { alert, isLoadingReport, report, tableSettings } = this.state;
    return (
      <div>
        {alert}
        {isLoadingReport || !report ? (
          <BarLoader color="#d81b60" size={8} />
        ) : (
          <ClientReportTable
            report={this.state.report}
            settings={{
              perPage: tableSettings?.per_page || 15,
              page: tableSettings?.current_page - 1 || 0,
              totalPages: tableSettings?.total_pages || 1,
              sorted: tableSettings?.sorted || [],
              search: tableSettings?.search || null,
            }}
            getClientReport={this.getReport}
          />
        )}
      </div>
    );
  }
}

export default compose(withRouter, withStyles(buttonStyle))(ClientReport);
