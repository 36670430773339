import WidgetsIcon from "@material-ui/icons/Widgets";
import CategoriesListing from "../../views/Categories/CategoriesListing";
import CreateCategory from "../../views/Categories/CreateCategory";
import EditCategory from "../../views/Categories/EditCategory";

const categories = {
    path: "/categories/list",
    parentPath: "/categories",
    name: "Categories",
    icon: WidgetsIcon,
    component: CategoriesListing,
    hideInMenu: false,
    matchUrl: '/categories/list',
    acl: ['products-read', 'sales-read', 'categories-read']
}

export const categoriesInner = [
    {
        path: "/categories/create",
        name: "Create Category",
        mini: "CC",
        component: CreateCategory,
        hideInMenu: false,
        matchUrl: '/categories/create',
        acl: ['categories-create']
    },
    {
        path: "/categories/edit/:id",
        name: "Edit Category",
        mini: "EC",
        component: EditCategory,
        hideInMenu: true,
        matchUrl: '/categories/edit',
        acl: ['categories-edit']
    },
];

export default categories;