import React from 'react';
import { compose, branch, renderComponent, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom'
import indexRoutes from "routes/index.jsx";
import { withRouter } from "react-router-dom";
import Error from './layouts/Error';
import getUser from './redux/auth/getUser';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

const Routes = () => {
    return (
        <Switch>
            {indexRoutes.map((prop, key) => {
                return <Route path={ prop.path } component={ prop.component } key={ key } />;
            })}
        </Switch>
    )
};

const mapStateToProps = (state) => {
    return {
        error: state.general.error,
        user: state.auth.user,
        token: state.auth.token,
        acl: state.auth.acl,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getUser
    }, dispatch);
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    branch(
        ({ error }) => {
            return !_.isNull(error);
        },
        renderComponent(Error)
    ),
    lifecycle({
        componentDidMount() {

            const { getUser, user, token } = this.props;

            if(_.isNull(user) && !_.isNull(token)) {
                getUser();
            }
        }
    })
)(Routes);