import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import _ from 'lodash';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { auth: { token } } = rest;
    return (
        <Route
            {...rest}
            render={props =>
                (!_.isNull(token) && !token.isExpired() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: { from: props.location },
                        }}
                    />
                ))
            }
        />
    )
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);