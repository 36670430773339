import React, { Component } from "react";
import _ from "lodash";
import Table from "./ReportsTable";
import ReportIcon from "@material-ui/icons/LibraryBooks";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AddIcon from "@material-ui/icons/Add";
import { Icon } from "material-ui";

import withConnected from "utilities/withConnected";
import styled from "styled-components";
import { LinearProgress } from "material-ui";
import GuardedComponent from "components/Authorization/GuardedComponent";

const Progress = styled(LinearProgress)`
  margin-left: 20px;
  margin-right: 3px;
  border-top-right-radius: 20px;
  bottom: -26px;
  visibility: ${(props) => (props.loading ? "inherited" : "hidden")};
`;

const Actions = styled.div`
  position: absolute;
  right: 200px;
  top: 8px;
`;

const ArchiveIcon = styled(Icon)`
  margin-top: 10px;
  cursor: pointer;
`;

class Reports extends Component {
  componentWillMount() {
    const { action } = this.props.rdx;

    action.loadReports(this.isArchive());
    action.loadClients();
  }

  reportsChecked = () => {
    const { reports } = this.props.rdx.state;
    const i = _.findIndex(reports.list, (item) => item.checked);

    return i > -1;
  };

  checkedReports = () => {
    const { reports } = this.props.rdx.state;
    return _.filter(reports.list, (r) => r.checked);
  };

  isArchive = () => {
    const { location } = this.props;
    return location.pathname === "/reports/archive";
  };

  render() {
    const { reports } = this.props.rdx.state;
    const { archiveReports } = this.props.rdx.action;

    let actions = null;
    if (this.reportsChecked()) {
      actions = (
        <Actions>
          <ArchiveIcon
            onClick={() => archiveReports(this.checkedReports())}
            fontSize="small"
          >
            archive
          </ArchiveIcon>
        </Actions>
      );
    }

    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <Progress loading={!!reports.loading} color="secondary" />
          <IconCard
            icon={ReportIcon}
            iconColor="rose"
            title={this.isArchive() ? "Archived Reports" : "Reports"}
            content={
              <div>
                {actions}
                {!this.isArchive() ? (
                  <GuardedComponent scope={["reports-create"]}>
                    <a href="/reports/create">
                      <Button
                        color="rose"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "2px",
                          right: "20px",
                        }}
                      >
                        <AddIcon />
                        Create report
                      </Button>
                    </a>
                  </GuardedComponent>
                ) : null}
                <Table
                  isArchive={this.isArchive()}
                  reports={reports.list}
                  clients={reports.clients}
                  settings={{
                    perPage: reports.meta?.pagination?.per_page || 15,
                    page: reports.meta?.pagination?.current_page - 1 || 0,
                    totalPages: reports.meta?.pagination?.total_pages || 1,
                    sorted: reports.meta?.pagination?.sorted || [],
                  }}
                />
              </div>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

export default withConnected(Reports);
