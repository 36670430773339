import React from "react";
import { BarLoader } from "react-css-loaders";
import { serviceAxios as axios } from "../../bootstrap";
import _ from "lodash";
import ProductsTable from "./ProductsTable";
import SweetAlert from "react-bootstrap-sweetalert";
import extractErrorMessage from "../../utilities/HttpErrorResponse";
import BarcodeScanner from "../Components/BarcodeScanner";
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { NotificationManager } from "react-notifications";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";

class ProductsListing extends React.Component {
  state = {
    isLoadingProducts: false,
    syncClientProductsCount: 0,
    syncClientProductsTotal: 0,
    syncProductsCount: 0,
    syncProductsErrors: 0,
    syncErrorDetails: [],
    products: null,
    tableSettings: null,
    clients: null,
    clientsMap: null,
    searchCriteria: "",
  };

  async componentDidMount() {
    document.title = "EPOS - Products";

    if (!this.state.isLoadingProducts) {
      this.getProducts();
    }
  }

  getProducts = ({ page = 1, pageSize = 15, sorted = [], search } = {}) => {
    this.setState({ isLoadingProducts: true }, async () => {
      try {
        const sort = sorted?.[0]?.id;
        const sortDirection = sorted?.[0]?.desc ? "desc" : "asc";
        const queryParams = {
          page,
          per_page: pageSize,
          sort,
          sort_direction: sortDirection,
        };
        if (search) {
          queryParams.search = search;
        }
        const productsResponse = await axios.get(`/api/products`, {
          params: queryParams,
        });
        if (!this.state.clientMap) {
          await this.getClients();
        }

        this.setState({
          isLoadingProducts: false,
          products: productsResponse.data.data.map((product) => {
            const client = this.state.clientMap[product.client_id];
            if (!client) {
              return product;
            }
            product.clientName = `${client.firstName} ${client.lastName}`;
            return product;
          }),
          tableSettings: {
            sorted,
            search,
            ...productsResponse.data.meta?.pagination,
          },
        });
      } catch (error) {
        this.setState({ submitting: false });
        this.showAlert(
          "Retreiving Products Failed",
          extractErrorMessage(error)
        );
      }
    });
  };

  getClients = async () => {
    return new Promise(async (resolve) => {
      const clientsResponse = await axios.get("/api/clients", {
        params: {
          per_page: Number.MAX_SAFE_INTEGER,
          sort: "code",
          sort_direction: "asc",
          enabled: 1,
        },
      });
      const clientMap = _.keyBy(clientsResponse.data.data, "id");
      this.setState(
        {
          clients: clientsResponse,
          clientMap,
        },
        () => resolve(clientMap)
      );
    });
  };

  /**
   * Search consignor codes, then sync by each one
   */
  syncProducts = async () => {
    try {
      let productsCount = 0;
      let errorsCount = 0;
      let errorDetails = [];
      // Set percentage
      await new Promise((resolve) =>
        this.setState(
          {
            syncClientProductsCount: 0,
            syncClientProductsTotal: 0,
            syncProductsCount: 0,
            syncProductsErrors: 0,
            syncErrorDetails: [],
          },
          resolve
        )
      );
      this.showLiveSyncAlert("Synchronization starting");
      const response = await axios.get("/api/clients", {
        params: {
          per_page: Number.MAX_SAFE_INTEGER,
          sort: "code",
          sort_direction: "asc",
        },
      });
      const clients = response.data.data;
      await new Promise((resolve) =>
        this.setState(
          {
            syncClientProductsTotal: clients.length,
          },
          resolve
        )
      );
      // Iterate over each client/consignor
      for (let index = 0; index < clients.length; index++) {
        try {
          const client = clients[index];
          // Sync client products with Square
          const clientSynchronized = await axios.post(
            `/api/products/syncByClient/${client.code}`
          );
          const { completed, errors } = clientSynchronized.data;
          // Add count to products/errors
          productsCount += Object.keys(completed).length;
          errorsCount += Object.keys(errors).length;
          errorDetails = [
            ...errorDetails,
            ...Object.keys(errors).map(
              (key) =>
                `Sync Error: Square ID: ${key}, Client: ${client.code} - ${errors[key].message} \n`
            ),
          ];
          // eslint-disable-next-line no-loop-func
          await new Promise((resolve) =>
            this.setState(
              {
                syncClientProductsCount: index + 1,
                syncProductsCount: productsCount,
                syncProductsErrors: errorsCount,
                syncErrorDetails: errorDetails,
              },
              resolve
            )
          );
        } catch (error) {
          errorsCount += 1;
          // Unexpected errors from API
          errorDetails = [
            ...errorDetails,
            `Item Sync Error: ${
              error.response
                ? `Failed with code: ${error.response.status}`
                : "Unexpected Error"
            } \n`,
          ];
          // eslint-disable-next-line no-loop-func
          await new Promise((resolve) =>
            this.setState(
              {
                syncClientProductsCount: index + 1,
                syncProductsErrors: errorsCount,
                syncErrorDetails: errorDetails,
              },
              resolve
            )
          );
        } finally {
          // Re-render modal every time the state changes
          this.showLiveSyncAlert("Synchronization in progress");
        }
      }
      this.showLiveSyncAlert("Synchronization Completed", true);
    } catch (error) {
      // Hide alert in case of incomplete progress
      this.showAlert("Sync Failed", extractErrorMessage(error));
    }
  };

  deleteProduct = (id) => {
    axios
      .delete(`/api/products/${id}`)
      .then(() => {
        this.getProducts();
      })
      .catch((error) => {
        this.showAlert("Delete Failed", extractErrorMessage(error));
      });
  };

  codeScanned = (code) => {
    NotificationManager.success(`Barcode scanned - SKU: ${code}`, "Success");
    NotificationManager.success(`Switching to create product page`, "Success");

    this.props.history.push(`/products/create?sku=${code}`);
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  showLiveSyncAlert(title, success = false) {
    const {
      syncProductsCount,
      syncProductsErrors,
      syncClientProductsCount,
      syncClientProductsTotal,
      syncErrorDetails,
    } = this.state;
    const messageLines = [
      `${syncClientProductsCount} of ${syncClientProductsTotal} consignors processed`,
      `${syncProductsCount} product(s) were synchronized`,
      `${syncProductsErrors} records contained errors (displaying last 5):`,
      ...syncErrorDetails.slice(-5),
    ];
    this.setState({
      alert: (
        <SweetAlert
          success={success}
          info={!success}
          title={title}
          showConfirm={false}
          showCancel
          cancelBtnText="Close"
          onCancel={() => this.hideAlert()}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          {messageLines.map((message) => (
            <p style={{ fontSize: "14px" }}>{message}</p>
          ))}
        </SweetAlert>
      ),
    });
  }

  showSyncAlert(title, success = false, messageLines = []) {
    this.setState({
      alert: (
        <SweetAlert
          success={success}
          danger={!success}
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button +
            " " +
            (success ? this.props.classes.primary : this.props.classes.danger)
          }
        >
          {messageLines.map((message) => (
            <p style={{ fontSize: "14px" }}>{message}</p>
          ))}
        </SweetAlert>
      ),
    });
  }

  showAlert(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  deleteProductAlert = (product) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={"Delete product - " + product.name + " ?"}
          showCancel
          confirmBtnText="Delete"
          onConfirm={() => {
            this.hideAlert();
            this.deleteProduct(product.id);
          }}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.default
          }
        >
          Are you sure you want to delete? The product will be deleted
          immediately, and can't be restored.
        </SweetAlert>
      ),
    });
  };

  debounceEventHandler = (...args) => {
    const debounced = _.debounce(...args);
    return function (e) {
      e.persist();
      return debounced(e);
    };
  };

  handleInputChange = (e) => {
    const searchCriteria = e.target.value.toLowerCase();
    this.setState({ searchCriteria }, async () => {
      await this.getProducts({
        ...this.state.tableSettings,
        search: this.state.searchCriteria,
      });
    });
  };

  render() {
    const classes = this.props.classes;
    const { products, tableSettings, isLoadingProducts } = this.state;

    return (
      <div>
        <CustomInput
          id="search"
          labelText="Search for SKU or product name"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            autoComplete: "off",
            onChange: this.debounceEventHandler(this.handleInputChange, 500),
            endAdornment: (
              <InputAdornment position="end">
                <Search className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
          meta={{ touched: false, error: false }}
        />
        {this.state.alert}
        {isLoadingProducts || !products ? (
          <BarLoader color="#d81b60" size={8} />
        ) : (
          <ProductsTable
            products={products}
            getProducts={this.getProducts}
            syncProducts={this.syncProducts}
            onClickDelete={this.deleteProductAlert}
            settings={{
              perPage: tableSettings?.per_page || 15,
              page: tableSettings?.current_page - 1 || 0,
              totalPages: tableSettings?.total_pages || 1,
              sorted: tableSettings?.sorted || [],
              search: tableSettings?.search || null,
            }}
          />
        )}
        <BarcodeScanner
          regexp="([A-Z]{3})\w+"
          prefix="#"
          onCodeScanned={this.codeScanned}
        />
      </div>
    );
  }
}

export default compose(withRouter, withStyles(buttonStyle))(ProductsListing);
