export const getConsignorsSelectData = (clients = [], all = true) => {
  const options = clients.map(({ id, code, company }) => ({
    value: id,
    label: `${code} - ${company}`,
  }));

  if (all) {
    options.unshift({
      value: "",
      label: "ALL",
    });
  }

  return options;
};
