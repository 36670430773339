import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import styles from "./ChartFooterItemStyles";
import { CHART_COLORS } from "constants/charts";

const ChartFooterItem = (props) => {
  const { classes, name, color } = props;

  return (
    <div className={classes.root}>
      <i className={`fas fa-circle ${classes[color]}`} />
      <span>{name}</span>
    </div>
  );
};

ChartFooterItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(CHART_COLORS),
};

export default withStyles(styles)(ChartFooterItem);
