import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles"

const checkboxStyle = {
    star: {
        visibility:'hidden',
        fontSize:'50px',
        cursor:'pointer',
        '&:before': {
            content: '"\\2606"',
            color:'lightgray',
            transparency:0.5,
            position: 'absolute',
            visibility:'visible',
        },
        '&:checked:before': {
            content: '"\\2605"',
            color:'#ffed66',
            position: 'absolute',
        }
    },
}

function StarCheckbox({ ...props }) {
  const {
    classes,
    title,
    disabled,
    children,
    customClass,
    input,
    ...rest
  } = props;
  const checkboxClasses = cx({
    [classes.star]: classes.star,
    [customClass]: customClass
  });
  
  return (
      <div>
        <input {...rest} className={checkboxClasses} disabled={disabled} title={title} checked={ input.value } onChange = { input.onChange } type="checkbox">
            {children}
        </input>
    </div>
  );
}

StarCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    customClass: PropTypes.string,
  };

export default withStyles(checkboxStyle)(StarCheckbox);
