import Group from "@material-ui/icons/Group";
import UsersListing from "../../views/Users/UsersListing";
import CreateUser from "../../views/Users/CreateUser";
import EditUser from "../../views/Users/EditUser";

const users = {
    path: "/users/list",
    parentPath: "/users/",
    name: "Users",
    icon: Group,
    component: UsersListing,
    hideInMenu: false,
    matchUrl: '/users/list',
    acl: ['users-read']
};

export const usersInner = [{
    path: "/users/create",
    name: "Create User",
    mini: "CU",
    component: CreateUser,
    hideInMenu: true,
    matchUrl: '/users/create',
    acl: ['users-create']
}, {
    path: "/users/edit/:id",
    name: "Edit User",
    mini: "EU",
    component: EditUser,
    hideInMenu: true,
    matchUrl: '/users/edit',
    acl: ['users-edit']
}];

export default users;