import React, { Component, Fragment } from 'react';
import { Dialog, Table, TableRow, TableCell, DialogContent, Button, Checkbox, CircularProgress } from 'material-ui';
import CreditCard from '@material-ui/icons/CreditCard'
import styled from 'styled-components';
import { compose } from 'recompose';
import update from 'immutability-helper'
import withConnected from 'utilities/withConnected';
import _ from 'lodash';
import ProductImage from '../Components/ProductImage';
import ReactToPrint from 'react-to-print';
import * as Sentry from '@sentry/browser';
import {isAndroid, isIPad13, isIPhone13, isMobileSafari} from 'react-device-detect';
const SQUARE_CALLBACK_URL = process.env.REACT_APP_SQUARE_CALLBACK_URL;
const SQUARE_CLIENT_ID = process.env.REACT_APP_SQUARE_CLIENT_ID;

const PaymentOptions = styled.div`
    .option {
        padding-top: 17px;
        button {
            color: #fff;
            background-color: #e91e63;
        }
        span {
            margin-left: 5px;
        }
    }
    .back-button {
        float: right;
        top: -40px;
    }
`;

const Cc = styled.div`
    width: 500px;
    margin-top: -37px;
    button {
        margin-top: 20px;
    }
    table {
        margin-bottom: 20px;
    }
    .back-button {
        float: right;
        top: -24px;
    }
`;

const OrderSummaryContainer = styled.div`
    margin: 20px;
`

const OrderSummary = styled.div`
    .total {
        text-align: right;
        font-size: 16px;
        margin-top: 20px;
        margin-right: 14px;

        span {
            font-weight: bold;
        }

    }
    button {
        margin: 22px 5px 0px;
        color: #fff;
        background-color: #e91e63;
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
`;

const Content = styled(DialogContent)`
    width: 550px;
    height: 402px;
`;

const CompletedTransaction = styled.div`
    color: #398a3c;
    font-weight: bold;
    h4 {
        margin-bottom: 20px;
    }
    button {
        margin-bottom: 20px;
        background-color: #e91e63;
        color: white;
        :hover {
            background-color: #e91e63;
        }
    }

    .close {
        position: absolute;
        bottom: 0;
        right: 20px;
    }
`;

const TAXES = 0.20;
function getSubTotal(items) {
    return _.reduce(items, (result, value) => {
        return result + parseFloat(Number(value.price), 10);
    }, 0)
}

class PrintOrderReceipt extends React.Component {
    render() {
        const { sales } = this.props.rdx.state;

        const items = sales.items.map((item) => (
            <TableRow>
                <TableCell>
                    <ProductImage id={item.productId} style={{ width: "50px" }} />
                </TableCell>
                <TableCell>
                    {item.sku}
                </TableCell>
                <TableCell>
                    <small>$</small>{parseFloat(Number(item.price)).toFixed(2)}
                </TableCell>
            </TableRow>
        ));

        return (
            <OrderSummaryContainer>
                <h4>
                    Order Summary
                </h4>
                <OrderSummary>
                    <Table>
                        {items}
                    </Table>
                    <div className='total'>
                        <span> Total </span> ${Number(getSubTotal(sales.items)).toFixed(2)}
                    </div>
                </OrderSummary>
            </OrderSummaryContainer>
        )
    }
}


class Checkout extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.state = {
            section: 'orderSummary',
            method: null,
            customer: null,
            completedCC: false,
            squareTotal: null,
            cash: false,
            isPaymentLoading: false
        }

        this.selectPayment = this.selectPayment.bind(this);
        this.toggleCustomer = this.toggleCustomer.bind(this);
        this.orderSummary = this.orderSummary.bind(this);
        this.cc = this.cc.bind(this);
        this.completedTransaction = this.completedTransaction.bind(this);
    }

    toggleCustomer() {
        this.setState(update(this.state, {
            $toggle: ['customer']
        }))
    }

    async launchMobileApp(platform, amount, subtotal, taxes, sale, cashOnly) {
        const saleDetails = {
            ...sale,
            user_id: this.props.rdx.state.auth.user.id,
            subtotal,
            taxes,
            total: amount
        }
        const saleNote = sale.items.map((item) => ` ${item.name}`).join(',');

        //Get the browser
        var dataParameter = {
            amount_money: {
                amount: amount * 100,
                currency_code: "USD"
            },
            callback_url: SQUARE_CALLBACK_URL,
            client_id: SQUARE_CLIENT_ID,
            version: "1.3",
            notes: saleNote,
            state: JSON.stringify(saleDetails),
            options: {
                supported_tender_types: null,
                auto_return: true
            }
        };
        dataParameter.options.supported_tender_types = cashOnly ? ["CASH"] : ["CREDIT_CARD", "OTHER", "SQUARE_GIFT_CARD", "CARD_ON_FILE", "CASH"];
        try {
            this.setState({isPaymentLoading: true}, ()=> {
                switch (platform) {
                    case 'iOS':
                        return window.location = "square-commerce-v1://payment/create?data=" +
                            encodeURIComponent(JSON.stringify(dataParameter));
                    case 'Android':
                        var transactionTotal = amount * 100;
                        var currencyCode = "USD";
                        var sdkVersion = "v2.0";
                        var tenderTypes = cashOnly ? "com.squareup.pos.TENDER_CASH" :
                            "com.squareup.pos.TENDER_CARD,com.squareup.pos.TENDER_CARD_ON_FILE,com.squareup.pos.TENDER_CASH,com.squareup.pos.TENDER_OTHER";
                        var posUrl =
                            "intent:#Intent;" +
                            "action=com.squareup.pos.action.CHARGE;" +
                            "package=com.squareup;" +
                            "S.com.squareup.pos.WEB_CALLBACK_URI=" + SQUARE_CALLBACK_URL + ";" +
                            "S.com.squareup.pos.CLIENT_ID=" + SQUARE_CLIENT_ID + ";" +
                            "S.com.squareup.pos.API_VERSION=" + sdkVersion + ";" +
                            "i.com.squareup.pos.TOTAL_AMOUNT=" + transactionTotal + ";" +
                            "S.com.squareup.pos.CURRENCY_CODE=" + currencyCode + ";" +
                            "S.com.squareup.pos.TENDER_TYPES=" + tenderTypes + ";" +
                            "l.com.squareup.pos.AUTO_RETURN_TIMEOUT_MS=" + 5000 + ";" +
                            "S.com.squareup.pos.NOTE="+ saleNote + ";" +
                            "S.com.squareup.pos.REQUEST_METADATA="+ JSON.stringify(saleDetails) + ";" +
                            "end";
                        return window.open(posUrl);
                    default:
                        return;
                }
            });

        } catch (e) {
            Sentry.captureException(`launchMobileApp ERROR: ${e}`);
            this.setState({isPaymentLoading: false});
        }
    }

    async submit(amount, subtotal, taxAmount, sale) {
        const { cash } = this.state;
        const userAgentHeader = navigator.userAgent;
        // Using react-device-detect
        if (isIPhone13 || isMobileSafari || isIPad13) {
            this.launchMobileApp('iOS', amount, subtotal, taxAmount, sale, cash);
        } else if (isAndroid) {
            this.launchMobileApp('Android', amount, subtotal, taxAmount, sale, cash);
        } else {
            Sentry.captureException(new Error(`UNSUPPORTED DEVICE: ${userAgentHeader}`));
            return alert('Unsupported Device. Please contact support.');
        }
    }

    cc() {
        const { sales } = this.props.rdx.state;
        let taxAmount = TAXES;
        const subTotal = getSubTotal(sales.items);
        const { customer, isPaymentLoading } = this.state;
        let taxes = (
            <TableRow>
                <TableCell>
                    Taxes
                </TableCell>
                <TableCell>
                    $ {TAXES}
                </TableCell>
            </TableRow>
        );

        if (customer) {
            taxes = null;
            taxAmount = 0;
        }

        //this.setState({ squareTotal: (subTotal + taxAmount) * 100 });
        const _total = parseFloat((subTotal + taxAmount).toFixed(10));
        return (
            <Fragment>
                <h4>
                    Checkout
                </h4>
                <Cc>
                    <Button onClick={() => this.setState({ section: 'paymentOptions', cash: false })} className='back-button'>
                        back
                    </Button>
                    <Table>
                        <TableRow>
                            <TableCell>
                                Subtotal
                            </TableCell>
                            <TableCell>
                                $ {subTotal}
                            </TableCell>
                        </TableRow>
                        {taxes}
                        <TableRow>
                            <TableCell>
                                Total
                            </TableCell>
                            <TableCell>
                                $ {_total}
                            </TableCell>
                        </TableRow>
                        <span>
                            <Checkbox onChange={this.toggleCustomer} />
                            is a reseller
                        </span>
                    </Table>

                    {/*<CardElement onChange={(e) => this.setState({ completedCC: e.complete })} style={cardStyles}/>*/}
                    {isPaymentLoading
                        ? <CircularProgress style={{ marginLeft: 10 }} size={100} />
                        : <Button fullWidth variant="raised" color="primary"
                            onClick={() => this.submit(_total, subTotal, taxAmount, sales)}>pay ${_total}</Button>
                    }

                </Cc>
            </Fragment>
        )
    }

    selectPayment() {
        return (
            <Fragment>
                <h4>
                    Select a payment method
                </h4>
                <PaymentOptions>
                    <Button onClick={() => this.setState({ section: 'orderSummary' })} className='back-button'>
                        back
                    </Button>
                    <div className='option'>
                        <Button fullWidth size='large' variant="raised" color="primary" onClick={() => this.setState({ section: 'cc' })}>
                            <CreditCard />
                            <span>
                                Proceed to Square App
                            </span>
                        </Button>
                    </div>
                </PaymentOptions>
            </Fragment>
        );
    }

    orderSummary() {
        const { sales } = this.props.rdx.state;

        const items = sales.items.map((item) => (
            <TableRow>
                <TableCell>
                    <ProductImage id={item.productId} style={{ width: "50px" }} />
                </TableCell>
                <TableCell>
                    {item.sku}
                </TableCell>
                <TableCell>
                    <small>$</small>{parseFloat(Number(item.price)).toFixed(2)}
                </TableCell>
            </TableRow>
        ));

        return (
            <Fragment>
                <h4>
                    Order Summary
                </h4>
                <OrderSummary>
                    <Table>
                        {items}
                    </Table>
                    <div className='total'>
                        <span> Total </span> ${Number(getSubTotal(sales.items)).toFixed(2)}
                    </div>
                    <Button onClick={() => this.setState({ section: 'paymentOptions' })} variant="raised">
                        Continue
                    </Button>
                </OrderSummary>
            </Fragment>
        )
    }

    completedTransaction() {
        const { rdx } = this.props;
        return (
            <CompletedTransaction>
                <h4>
                    Transaction Completed!
                </h4>
                <Button fullWidth variant="contained" >
                    <span>
                        Email
                    </span>
                </Button>
                <div>
                    <ReactToPrint
                        trigger={() => (
                            <Button fullWidth size='large' variant="contained" >
                                <span>
                                    Print
                        </span>
                            </Button>
                        )}
                        content={() => this.componentRef}
                    />
                    <Button onClick={rdx.action.purchaseCompleted} className='close' size='small'>
                        <span>
                            close
                        </span>
                    </Button>
                </div>

                <div style={{ display: "none" }}>
                    <PrintOrderReceipt {...this.props} ref={el => (this.componentRef = el)} />
                </div>
            </CompletedTransaction>
        )
    }

    getContent() {
        let sections = {
            cc: this.cc,
            orderSummary: this.orderSummary,
            paymentOptions: this.selectPayment,
            completedTransaction: this.completedTransaction
        };

        return sections[this.state.section](this.props);
    }

    render() {
        const { rdx } = this.props;
        if (rdx.state.sales.items.length === 0) {
            return null;
        }

        return (
            <Dialog onClose={rdx.action.toggleCheckout} open={this.props.rdx.state.sales.checkout}>
                <Content>
                    {this.getContent()}
                </Content>
            </Dialog>
        );
    }
}

export default compose(
    withConnected
)(Checkout)