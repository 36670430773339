import Unarchive from "@material-ui/icons/Unarchive";
import ProductsListing from "../../views/Products/ProductsListing";
import CreateProduct from "../../views/Products/CreateProduct";
import EditProduct from "../../views/Products/EditProduct";

const products = {
    path: "/products/list",
    parentPath: "/products/",
    name: "Products",
    icon: Unarchive,
    component: ProductsListing,
    hideInMenu: false,
    matchUrl: '/products/list',
    acl: ['products-read']
};

export const productsInner = [{
    path: "/products/create",
    name: "Create Product",
    mini: "CP",
    component: CreateProduct,
    hideInMenu: true,
    matchUrl: '/products/create',
    acl: ['products-create']
}, {
    path: "/products/edit/:id",
    name: "Edit Product",
    mini: "EP",
    component: EditProduct,
    hideInMenu: true,
    matchUrl: '/products/edit',
    acl: ['products-edit']
}];

export default products;