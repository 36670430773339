import React from "react";
import PropTypes from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import bgImage from "assets/img/register.jpeg";
import { compose } from 'recompose';
import GeneralError from '../components/Error/GeneralError';

const Error = (props) => {

    const { classes, ...rest } = props;

    return (
        <div>
            <PagesHeader {...rest} />
            <div className={classes.wrapper}>
                <div className={classes.fullPage}>
                    <GeneralError />
                    <Footer white />
                    <div
                        className={classes.fullPageBackground}
                        style={{ backgroundImage: "url(" + bgImage + ")" }}
                    />
                </div>
            </div>
        </div>
    );
};

Error.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(pagesStyle),
)(Error);
