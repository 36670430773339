import axios from "axios";

import {
  FORGOT_START,
  FORGOT_SUCCESS,
  FORGOT_FAILED,
} from "./recover-reducer";

export default function forgot(email) {
  return (dispatch) => {
    dispatch({ type: FORGOT_START });

    axios
      .post("/recover/forgot", { email })
      .then((response) => {
        dispatch({ type: FORGOT_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: FORGOT_FAILED,
          payload: { errorText: error.toString() },
        });
      });
  };
}
