import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import auth from 'redux/auth/auth-reducer';
import general from './general/error-reducer';
import sales from './reducer/sales';
import reports from './reducer/reports';
import recover from './recover/recover-reducer';

const rootReducer = combineReducers({
    routing: routerReducer,
    form: formReducer,
    auth,
    general,
    sales,
    reports,
    recover,
});

export default rootReducer;