import React, { Component } from "react";
import _ from "lodash";
import { compose } from "recompose";
// material-ui components
import withStyles from "material-ui/styles/withStyles";
// material-ui icons
import Close from "@material-ui/icons/Close";
// core components
import Table from "../../components/Table/Table";
import Button from "../../components/CustomButtons/Button";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

import withConnected from "utilities/withConnected";
import ProductImage from "../Components/ProductImage";
import GuardedComponent from "components/Authorization/GuardedComponent";

class ShoppingCart extends Component {
  constructor() {
    super();
    this.doItem = this.doItem.bind(this);
    this.getTotal = this.getTotal.bind(this);
  }

  doItem(item) {
    const { classes, rdx: { action } } = this.props;
    return [
        <div>
            <ProductImage id={item.productId} alt="..." className={classes.img} style={{ width: "50px" }} />
        </div>,
        <span>
            <small>
                {item.sku}
            </small>
        </span>,
        <span>
            <small className={classes.tdNumberSmall}>$</small>{parseFloat(Number(item.price)).toFixed(2)}
        </span>,
        <Button color="simple" customClass={classes.actionButton} style={{ paddingLeft: "0px", paddingRight: "0px"}} onClick={() => action.removeCartItem(item.id)}>
            <Close className={classes.icon} />
        </Button>
    ]
  }

  getTotal() {
    const { rdx } = this.props;
    const {
      state: {
        sales: { items },
      },
    } = rdx;
    return _.reduce(
      items,
      (result, value) => {
        return result + parseFloat(Number(value.price));
      },
      0
    );
  }

  render() {

    const { rdx } = this.props;
    const {
      state: {
        sales: { items },
      },
    } = rdx;
    let data = [];
    if (items.length > 0) {
      data = items.map((item) => this.doItem(item));
      data.push(
        {
          total: true,
          amount: (
            <span>
              <small>$</small>
              {Number(this.getTotal()).toFixed(2)}
            </span>
          ),
        },
        {
          purchase: true,
          col: {
            colspan: 2,
            text: (
              <GuardedComponent scope={['sales-create']}>
                <Button onClick={rdx.action.toggleCheckout} color="rose">
                  Check Out
                </Button>
              </GuardedComponent>
            ),
          },
        }
      );
    } else {
      data = [[<span>No items added.</span>]];
    }

    return (
      <div>
        <h5>Shopping Cart</h5>
        <Table tableData={data} />
      </div>
    );
  }
}

export default compose(withConnected, withStyles(buttonStyle))(ShoppingCart);
