import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import PermIdentity from "@material-ui/icons/PermIdentity";
import { compose, withProps } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import PictureUpload from 'components/CustomUpload/PictureUpload.jsx';
import styled from 'styled-components';
import IsEnabledSwitch from "../Components/IsEnabledSwitch";
import FileUpload from 'components/CustomUpload/FileUpload';
import GuardedComponent from "components/Authorization/GuardedComponent";

const ResellerLicence = styled.div`
    padding-top: 20px;
    span {
        position: absolute
    }
    input {
        padding-left: 32px;
    }
    a {
        margin-left: 5px;
        background: #01BCD4;
        padding: 2px 8px;
        border-radius: 12px;
        color: white;
        font-size: 12px;
        font-weight: 500;
        :hover {
            color: white;
        }
    }
`;

const ResellerLicenseNumber = styled(ItemGrid)`
    div {
        width: 70%;
    }
    
    .verify-link {
        border: 2px solid #e91e63;
        padding: 8px;
        border-radius: 4px;
    }
`;

const EditCustomerForm = (props) => {

    let {
        handleSubmit,
        submitting,
        image,
        resellerLicence
    } = props;

    let viewLicence = null;
    if (resellerLicence) {
        viewLicence = (
            <a href={resellerLicence} >
                view
            </a>
        )
    }

    return (
        <ItemGrid xs={12} sm={12} md={12}>
            <IconCard
                icon={PermIdentity}
                iconColor="rose"
                title='Edit Customer'
                content={
                    <div>
                        <form onSubmit={ handleSubmit }>
                            <GridContainer>
                                <ItemGrid 
                                    xs={12} 
                                    sm={12} 
                                    md={6} 
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                    <Field 
                                        component={ PictureUpload }
                                        imageURL = { image }
                                        name = "picture" />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name= "firstName"
                                                type= "text"
                                                labelText= "First Name"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name="lastName"
                                                type="text"
                                                labelText="Last Name"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name="email"
                                                type="text"
                                                labelText="Email"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput }
                                        name= "phone"
                                        type= "text"
                                        labelText= "Phone"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "company"
                                        type= "text"
                                        labelText= 'Company'
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <Field component={ CustomInput } 
                                        name= "street"
                                        type= "text"
                                        labelText= "Address"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={ CustomInput } 
                                        name= "city"
                                        type= "text"
                                        labelText= "City"
                                        formControlProps= {{
                                            fullWidth: true
                                        }}/>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={ CustomInput }
                                        name= "state"
                                        type= "text"
                                        labelText= "State"
                                        formControlProps= {{
                                            fullWidth: true
                                        }}/>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={ CustomInput }
                                         name= "zip"
                                         type= "text"
                                         labelText= "Zip"
                                         formControlProps= {{
                                             fullWidth: true
                                         }}/>
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={8}>
                                    <ResellerLicence>
                                        Copy of Resellers Licence
                                        {viewLicence}
                                        <div>
                                            <span>
                                                Edit:
                                            </span>
                                            <Field 
                                                component={ FileUpload }
                                                name = "resellerLicence" 
                                            />
                                        </div>
                                    </ResellerLicence>
                                </ItemGrid>                                
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <GridContainer>
                                        <ResellerLicenseNumber xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name= "reseller_number"
                                                type= "text"
                                                labelText= "Reseller License Number"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} 
                                            />
                                            <a href='https://services.cdtfa.ca.gov/webservices/verification.jsp?action=SALES' target='blank' className='verify-link'>
                                                Verify
                                            </a>
                                        </ResellerLicenseNumber>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <p>Status</p>
                                            <Field
                                                component = { IsEnabledSwitch }
                                                name = "enabled" />
                                        </ItemGrid>                                     
                                    </GridContainer>
                                </ItemGrid>                                
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <Field component={ CustomInput } 
                                        name= "notes"
                                        type= "text"
                                        inputProps={{
                                            multiline: true,
                                            rowsMax:4,
                                            rows:4
                                        }}
                                        labelText= "Notes"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <h4>Additional Information</h4>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "contact_info_name"
                                        type= "text"
                                        labelText= "Additional User Name"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "contact_info_relationship"
                                        type= "text"
                                        labelText= "Relationship"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput }
                                        name= "contact_info_phone"
                                        type= "text"
                                        labelText= "Additional Phone Number"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "contact_info_email"
                                        type= "text"
                                        labelText= 'Additional Email'
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GuardedComponent scope={['customers-edit']}>
                                <Button color="rose" right type="submit" disabled={ submitting }>
                                    Save
                                </Button>
                            </GuardedComponent>
                            <Clearfix />
                        </form>
                    </div>
                }
            />
        </ItemGrid>
    )
};

export default compose(
    withProps(({ customer }) => {
        return {
            initialValues: {
                email: customer.email,
                firstName: customer.firstName,
                lastName: customer.lastName,
                company: customer.company,
                street: customer.street,
                phone: customer.phone,
                zip: customer.zip,
                city: customer.city,
                state: customer.state,
                reseller_number: customer.reseller_number,
                enabled: customer.enabled,
                notes: customer.notes,
                contact_info_name: customer.contact_info_name,
                contact_info_phone: customer.contact_info_phone,
                contact_info_email: customer.contact_info_email,
                contact_info_relationship: customer.contact_info_relationship
            }
        }
    }),
    reduxForm({
        form: 'edit_customer'
    })
)(EditCustomerForm);