import React from 'react';
import { compose } from 'recompose';
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { BarLoader } from 'react-css-loaders';
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import ViewProductForm from './ViewProductForm';
import withConnected from 'utilities/withConnected';
class ViewProduct extends React.Component {

    constructor() {
        super();
        this.handleAddToCard = this.handleAddToCard.bind(this);
        this.state = {
            alert: null,
            isLoadingProduct: false,
            product: null,
            image: null
        }
    }

    componentDidMount() {
        document.title = 'EPOS - Product Details'
        if(!this.state.isLoadingProduct) {
            this.getProduct();
        }
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(message, title) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title= {title}
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    getImage = (id) => {
        axios.get(`/api/files/products/${id}/photo.jpg`, { responseType: 'blob' })
        .then(response => {
            if (response.data.size>10) {
                var data_url = URL.createObjectURL(response.data);
                this.setState({ image: data_url });
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    getClient = async (clientId) => {
        if (clientId) {
            try {
                const clientResponse = await axios.get(`/api/clients/${clientId}`)
                const client = clientResponse.data.data
                this.setState(state => ({
                    product: { ...state.product, clientName: `${client.firstName} ${client.lastName}` }
                }))
            } catch (error) {
                this.showAlert("Whose product?", "Failed to get product's owner data.")
            }
        }
    }

    getProduct = () => {
        const productId = this.props.productId
        this.getImage(productId);
        this.setState({isLoadingProduct: true})
        axios.get(`/api/products/${productId}`)
        .then(response => {
            this.setState({
                 product: response.data.data,
                 isLoadingProduct: false
            })
            this.getClient(response.data.data.client_id);
        })
        .catch((error) => {
            this.setState({ isLoadingProduct: false })
            this.showAlert(extractErrorMessage(error), "Getting Product Failed")
        });
    }

    handleAddToCard(e, v) {
        e.preventDefault();
        const { productId, rdx: { action }} = this.props;
        action.addToCart({ productId , ...this.state.product });
        this.props.history.push('/sale')
    }

    render() {
        return (
            <div>
                { this.state.alert }
                { (this.state.isLoadingProduct || _.isNull(this.state.product) ) ?
                    <BarLoader color="#d81b60" size={ 8 } />
                :
                    <ViewProductForm handleAddToCart={this.handleAddToCard} product={this.state.product} image={this.state.image}  submitting={this.state.submitting} onSubmit={this.updateProduct}  />
                }
            </div>
        )
    }
}

export default compose(
    withRouter,
    withConnected,
    withStyles(buttonStyle)
)(ViewProduct);