import React from 'react'

import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";

import withStyles from "material-ui/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { ListItem } from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import _ from 'lodash';

function SelectStatus(props) {
        const {
            input: {
                value,
                onChange
            },
            multiple,
            classes,
            meta
        } = props;
        const options = ['Sold', 'Not Sold', 'Donation', 'Client Retain', 'Debris', 'On Hold', 'Returned'];

        let labelClass = classes.selectLabel
        let selectFormClass = classes.selectFormControl
        if(meta && meta.error && (meta.touched || meta.submitFailed) ) {
            labelClass =  classes.labelError
            selectFormClass = classes.selectFormControlError
        }

        let items = options.map((item, i) => {
            return (
                <ListItem
                    key={i}
                    value={item}
                >
                    <Checkbox
                        checked={ _.findIndex(value, (o) => o === item ) > -1}
                        onChange={() => {
                            return [null, item, null, 'status']
                        }}
                    />
                    {item}
                </ListItem>
            )
        })

        const _v = typeof value === 'string' ? [value] : value;
        let status = _.filter(_v, (s) => (s !== ''))
        if (!multiple) {
            status = value;
            items = options.map((item, i) => {
                return (
                    <MenuItem key={i} value={item} classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    >
                        {item}
                    </MenuItem>
                )
            })
        }
        return (
            <FormControl
                fullWidth
                style={{marginTop: 10}}
                className={selectFormClass}
            >
                <InputLabel
                    className={labelClass}
                    htmlFor="simple-select">
                    SELECT STATUS
                </InputLabel>
                <Select
                    multiple={multiple}
                    value={status}
                    renderValue={(selected) => _.isArray(selected) ? selected.join(', ') : selected}
                    onChange={onChange}
                    disabled={props.disabled}
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                    }}>
                    {items}
                </Select>
            </FormControl>
        )
}

export default withStyles(extendedFormsStyle)(SelectStatus)