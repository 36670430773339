import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import resetPassword from "redux/recover/reset";
import verify from "redux/recover/verify";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";

// @material-ui/icons
import Lock from "@material-ui/icons/Lock";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      fields: {
        newPassword: {
          name: "newPassword",
          type: "password",
          labelText: "New Password",
          formControlProps: {
            fullWidth: true,
          },
          inputProps: {
            type: "password",
            endAdornment: (
              <InputAdornment position="end">
                <Lock className={this.props.classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          },
        },
        confirmNewPassword: {
          name: "confirmNewPassword",
          type: "password",
          labelText: "Confirm New Password",
          formControlProps: {
            fullWidth: true,
          },
          inputProps: {
            type: "password",
            endAdornment: (
              <InputAdornment position="end">
                <Lock className={this.props.classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          },
        },
      },
      recoveryToken: this.props.match.recoveryToken,
      userId: this.props.match.userId,
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    document.title = "EPOS - Reset your Password";

    const { recoveryToken, userId } = this.props.match.params;
    if (recoveryToken && userId) {
      this.props.dispatch(verify(recoveryToken, userId));
    }
  }

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      verifyError,
      loadingVerify,
      resetSuccess,
      loadingReset,
      errorText,
    } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <ItemGrid xs={12} sm={6} md={4}>
              <form onSubmit={handleSubmit}>
                <LoginCard
                  customCardClass={classes[this.state.cardAnimaton]}
                  headerColor="rose"
                  cardTitle="Reset your Password"
                  footerAlign="center"
                  footer={
                    <Button
                      color="roseNoBackground"
                      wd
                      size="lg"
                      type="submit"
                      disabled={submitting || loadingVerify}
                    >
                      Submit
                    </Button>
                  }
                  content={
                    <div>
                      {verifyError ? (
                        <SnackbarContent
                          message={`Reset Password failed. Please try again. ( ${errorText} )`}
                          close
                          color="danger"
                        />
                      ) : loadingVerify ? (
                        "Verifying your token, please wait..."
                      ) : (
                        <>
                          {resetSuccess ? (
                            <SnackbarContent
                              message={`Password updated correctly!`}
                              close
                              color="success"
                            />
                          ) : null}

                          <Field
                            component={CustomInput}
                            {...this.state.fields.newPassword}
                          />

                          <Field
                            component={CustomInput}
                            {...this.state.fields.confirmNewPassword}
                          />
                        </>
                      )}
                    </div>
                  }
                />
              </form>
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgotError: state.recover.forgotError,
    forgotSuccess: state.recover.forgotSuccess,
    loadingForgot: state.recover.loadingForgot,
    verifyError: state.recover.verifyError,
    verifySuccess: state.recover.verifySuccess,
    resetSuccess: state.recover.resetSuccess,
    loadingReset: state.recover.loadingReset,
    loadingVerify: state.recover.loadingVerify,
    errorText: state.recover.errorText,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verify,
      resetPassword,
    },
    dispatch
  );
};

export default compose(
  withStyles(loginPageStyle),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "reset",
    validate: (values) => {
      const errors = {};
      const { newPassword, confirmNewPassword } = values;
      if (newPassword !== confirmNewPassword) {
        errors.confirmNewPassword = "Passwords doesn't match";
      }
      return errors;
    },
    onSubmit: ({ newPassword }, dispatch, { resetPassword, match }) => {
      const { userId } = match.params;
      resetPassword(newPassword, userId);
    },
  })
)(ResetPasswordPage);
