import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Grid from "material-ui/Grid";
import { compose, withProps } from "recompose";
import { Field, reduxForm } from "redux-form";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import FormSwitch from "../Components/FormSwitch";
import ClientSelect from "./ClientSelect";
import CategorySelect from "./CategorySelect";
import { withRouter } from "react-router-dom";
import RegularCard from "components/Cards/RegularCard.jsx";
import { connect } from "react-redux";
import GuardedComponent from "components/Authorization/GuardedComponent";

const ViewProductForm = (props) => {
  const { image, cartItemIds, handleAddToCart, history, product } = props;

  const addedToCart =
    cartItemIds.findIndex((itemId) => itemId === product.id) !== -1;
  const isItemAvailable = product.status.toLowerCase() === "not sold";

  const itemStyles = { paddingBottom: "7px" };

  return (
    <RegularCard
      content={
        <div>
          <form onSubmit={handleAddToCart}>
            <Grid container>
              <ItemGrid xs={6}>
                <Grid container direction="column">
                  <Grid item style={itemStyles}>
                    <Field
                      component={CategorySelect}
                      name="category_id"
                      input={{ value: product.category_id }}
                      disabled
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <Field
                      component={CustomInput}
                      name="name"
                      type="text"
                      labelText="Product Name"
                      input={{ disabled: true, value: product.name }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <Field
                      component={CustomInput}
                      input={{ disabled: true, value: product.description }}
                      name="description"
                      type="text"
                      labelText="Notes"
                      inputProps={{ multiline: true, rows: 2, maxRows: 4 }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <Field
                      disabled
                      component={ImageUpload}
                      imageURL={image}
                      name="picture"
                    />
                  </Grid>
                </Grid>
              </ItemGrid>
              <ItemGrid xs={6}>
                <Grid container direction="column">
                  <Grid item style={itemStyles}>
                    <Field
                      component={CustomInput}
                      name="sku"
                      type="text"
                      labelText="SKU"
                      input={{ disabled: true, value: product.sku }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <Field
                      component={ClientSelect}
                      name="client_id"
                      input={{ value: product.client_id }}
                      disabled
                      clientName={props.clientName}
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <Field
                      component={CustomInput}
                      name="notes"
                      type="text"
                      labelText="Notes"
                      input={{ disabled: true, value: product.notes }}
                      inputProps={{ multiline: true, rows: 2, maxRows: 4 }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <Field
                      component={CustomInput}
                      name="price"
                      type="text"
                      labelText={
                        product.is_reserved_price ? "Reserved Price" : "Price"
                      }
                      input={{ disabled: true, value: product.price }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <Field
                      component={CustomInput}
                      name="status"
                      type="text"
                      labelText="Status"
                      input={{ disabled: true, value: product.status }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={itemStyles}>
                    Signed
                    <Field component={FormSwitch} name="signed" disabled />
                  </Grid>
                  <Grid item style={itemStyles}>
                    <GuardedComponent scope={["sales-create"]}>
                      <Button
                        color="rose"
                        type="submit"
                        disabled={addedToCart || !isItemAvailable}
                        fullWidth={true}
                      >
                        {`Add to Cart${
                          addedToCart || !isItemAvailable ? "*" : ""
                        }`}
                      </Button>
                    </GuardedComponent>
                    <div style={{ fontSize: "11px" }}>
                      {!isItemAvailable &&
                        `* Cannot add the item to the cart. The item has the status "${product.status}"`}
                      {addedToCart && `* Item already added to the cart.`}
                    </div>
                  </Grid>
                </Grid>
              </ItemGrid>
            </Grid>
          </form>
        </div>
      }
    />
  );
};
const mapStateToProps = (state) => {
  const { items } = state.sales;
  return { cartItemIds: items.map((item) => item.id) };
};
export default compose(
  withRouter,
  withProps(({ product, handleAddToCart }) => {
    return {
      product,
      clientName: product.clientName,
    };
  }),
  reduxForm({
    form: "edit_product",
  }),
  connect(mapStateToProps)
)(ViewProductForm);
