import React from "react";
import Form from './Form';
import Card from './Card';
import GridContainer from "components/Grid/GridContainer.jsx";

class Profile extends React.Component {
    componentDidMount() {
        document.title = "EPOS - User Profile";
    }

    render() {
        return (
            <div>
                <GridContainer>
                    <Form />
                    <Card />
                </GridContainer>
            </div>
        )
    }
};

export default Profile