import Fingerprint from "@material-ui/icons/Fingerprint";
import ResetPasswordPage from "../../views/Pages/ResetPasswordPage.jsx";

const verify = {
    path: "/recover/verify/:recoveryToken/:userId",
    name: "Reset Password",
    short: "Reset",
    mini: "R",
    icon: Fingerprint,
    component: ResetPasswordPage
};

export default verify;