import React from 'react';
import { compose, withProps } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import Button from "components/CustomButtons/Button.jsx";
import styled from 'styled-components';

import ItemGrid from "components/Grid/ItemGrid.jsx";
import SelectClient from './SelectClient';
import SelectStatus from './SelectStatus';
import { Icon } from 'material-ui';

import CategorySelect from 'views/Products/CategorySelect'
import GridContainer from "components/Grid/GridContainer.jsx";
import update from 'immutability-helper';

import DatePicker from "react-datepicker";

const FilterContainer = styled.div`
    margin-bottom: 10px;
`;

const InputWrapper = styled.div`
    .input-field { 
        width: 90%;
    }
`;

const ActionsWrapper = styled.div`
    margin-right: 33px;
`;

const DatesWrapper = styled.div`
    margin-top: 41px;
    .react-datepicker-wrapper {
        margin-left: 40px;
    }
    input {
        font-size: 14px;
    }
    .material-icons {
        position: absolute;
    }
`;

const DatePick = styled(DatePicker)`
    background: none;
    border: none;
    border-bottom: 1px solid gray;
`;


const EditReportForm = (props) => {
    const { rdx } = props;
    const { form } = rdx.state;

    if (!form || !form.update_report) { 
        return null;
    }
    
    const values = form.update_report.values;
    
    let button = (
        <Button 
            onClick={props.onSave} 
            style={{ float: 'right', marginRight: 20 }} color="rose" size=''
        >
            save
        </Button>
    );

    function onChange(other) {
        const { rdx, change } = props;
        const { form } = rdx.state;
        let values = form.update_report.values
        const field = other[3];
        let value = other[1];
        
        change(field, value);

        if (field === 'start_date' && !values.end_date ) {
            // values = update(values, { ['end_date']: { $set: new Date() }})
            change('end_date', new Date() );
        }
        
        rdx.action.loadProducts(update(values, { [field]: { $set: value }}));
    }

    return (
        <FilterContainer>
                <form>
                    <GridContainer>
                        <ItemGrid xs={6}>
                            <Field
                                component = { SelectClient }
                                name = "client_id"
                                onChange={(...change) => onChange(change)}
                            /> 
                            <DatesWrapper>
                                <GridContainer>
                                    <ItemGrid className='end_date' xs={6} sm={6} md={6}>
                                        <Icon color="primary">
                                            date_range
                                        </Icon>
                                        <Field
                                            name = "start_date" 
                                            component = { () => {
                                                return (
                                                    <DatePick
                                                        placeholderText='Start date'
                                                        selected={values.start_date} 
                                                        dateFormat="MMMM d, yyyy"
                                                        onChange={(date) => {  onChange([null, date, null, 'start_date'])} }
                                                    />
                                                )} 
                                            }
                                        />
                                    </ItemGrid>
                                    <ItemGrid className='start_date' xs={6} sm={6} md={6}>
                                        <Icon color="primary">
                                        date_range
                                        </Icon>
                                        <Field
                                            name = "end_date" 
                                            component = { () => {
                                                return (
                                                    <DatePick  
                                                        placeholderText='End date'
                                                        selected={values.end_date} 
                                                        dateFormat="MMMM d, yyyy"
                                                        onChange={(date) => onChange([null, date, null, 'end_date'])}  
                                                    />
                                                )} 
                                            }
                                        />
                                    </ItemGrid>
                                </GridContainer>
                            </DatesWrapper>
                        </ItemGrid>
                        <ItemGrid xs={6}>
                            <InputWrapper>
                                <Field
                                    component = { CategorySelect }
                                    name = "category_id" 
                                    onChange={(...props) => onChange(props)}
                                    onOpen={() => {}}
                                    classes={{ selectFormControl: 'input-field'}}
                                />
                              
                            </InputWrapper>
                            <InputWrapper>
                                <Field
                                    component = {SelectStatus}
                                    name = "status"
                                    multiple
                                    onChange={(...props) => onChange(props)}
                                    classes={{ selectFormControl: 'input-field'}}
                                />
                                
                            </InputWrapper>
                            <ActionsWrapper>
                                {button}
                            </ActionsWrapper>
                        </ItemGrid> 
                    </GridContainer>
                </form>
            </FilterContainer>
    )
}

export default compose(
    withProps(({ report }) => {
        
        return {
            initialValues: {
                client_id: report.query.client_id,
                category_id: report.query.category_id,
                start_date: report.query.start_date,
                end_date: report.query.end_date,
                status: [report.query.status]
            }
        }
    }),
    reduxForm({
        form: 'update_report',
    }),
)(EditReportForm);