import React from 'react';
import { BarLoader } from 'react-css-loaders';

import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { serviceAxios as axios } from '../../bootstrap';
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import extractErrorMessage from "../../utilities/HttpErrorResponse";
import Grid from "material-ui/Grid";
import Fade from "material-ui/transitions/Fade";
import SweetAlert from 'react-bootstrap-sweetalert'
import CategoryProduct from "./CategoryProduct"

class CategoryProducts extends React.Component {

    state = {
        isLoadingProducts: false,
        alert: null,
        products: []
    }

    componentDidMount() {
        if(!this.state.isLoadingProducts) {
            this.getProductsInCategory(this.props.categoryId);
        }
    }

    getProductsInCategory = (categoryId) => {
        this.setState({isLoadingProducts: true})
        axios.get(`/api/products`)
            .then(response => {
                this.setState({
                    products: response.data.data.filter(x => x.category_id === categoryId),
                    isLoadingProducts: false
                })
            })
            .catch((error) => {
                this.setState({ isLoadingProducts: false })
                this.showAlert("Retrieving Products in Category Failed", extractErrorMessage(error))
            });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    productClicked = (productId) => {
        this.props.onClick(productId)
    }

    showAlert(title, message) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title={title}
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    render() {
        return (
        <div style={{ height:"100%" }} >
            { this.state.alert }
            {(this.state.isLoadingProducts || !this.state.products) ?
                <BarLoader color="#d81b60" size={8}/>
                :(
                        <Grid container style={{height:"auto"}}>
                            {this.state.products.length > 0 ?
                                this.state.products.map( (product, i) => (
                                    <Grid item key = { product.id } xs={6} sm={3} md={3}  >
                                        <Fade in timeout={800}>
                                            <CategoryProduct product={product} onClick={this.productClicked} />
                                        </Fade>
                                    </Grid>
                                )) : (
                                    <h5 style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        marginTop: '2rem',
                                    }}>
                                        No products
                                    </h5>
                                )
                            }
                        </Grid>
                )
            }
        </div>
        )
    }
}

export default compose(
    withRouter,
    withStyles(buttonStyle)
)(CategoryProducts);