import approve from 'approvejs';
import isNotEmptyArray from './isNotEmptyArray';
import sameAs from './sameAs';
import _ from 'lodash';
import requiredIf from './requiredIf';

const rules = {
    isNotEmptyArray,
    requiredIf,
    sameAs,
};

_.forEach(_.keys(rules), (ruleName) => {
    approve.addTest(rules[ruleName], `${ruleName}`);
});