import React, { Component } from 'react';
import { serviceAxios as axios } from '../../bootstrap';
import SortableTree, { addNodeUnderParent, changeNodeAtPath, getFlatDataFromTree, getNodeAtPath, getTreeFromFlatData } from 'react-sortable-tree'
import WidgetsIcon from "@material-ui/icons/Widgets";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Done from "@material-ui/icons/Done";
import Tooltip from "material-ui/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import './CategoriesTree.css'
import GuardedComponent from 'components/Authorization/GuardedComponent';

class CategoriesTree extends Component {
  constructor(props) {
    super(props)

    this.state = {
      treeData: getTreeFromFlatData({
        flatData: props.categories.map(node => ({ ...node, title: node.name, parent: node.parent_id })), getKey: node => node.id,
        getParentKey: node => node.parent_id,
        rootKey: 0,
      }),
    };
  }

  saveCategory = async (params) => {
    try {
      const { id } = params
      if (id) {
        await axios.post(`/api/categories/${id}`, params)
        alert('Category updated successfully')
      } else {
        await axios.post(`/api/categories/`, params)
        alert('Category added successfully')
      }
    } catch (e) {
      console.log(e)
    } finally {
      window.location.reload()
    }
  }



  render() {
    const { searchString } = this.props

    const customSearchMethod = ({ node, searchQuery }) => (
      searchQuery &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)

    const getNodeKey = ({ node: { id } }) => id
    const getNodeKeyTreeIndex = ({ treeIndex }) => treeIndex

    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <IconCard
            icon={WidgetsIcon}
            iconColor="rose"
            title="Categories"
            content={
              <div>
                <GuardedComponent scope={['categories-create']}>
                  <Button color="rose" size='sm' style={{ position: 'absolute', top: '2px', right: '20px' }}
                    onClick={() =>
                      this.setState(state => ({
                        treeData: state.treeData.concat({
                          title: ``,
                          level: 0,
                          parent_id: 0,
                        }),
                      }))}
                  >
                    <AddIcon />
                      Add new category at end of list
                  </Button>
                </GuardedComponent>

                <div style={{ height: '70vh' }}>
                  <SortableTree
                    canDrag={false}
                    generateNodeProps={({ node, path }) => ({
                      buttons: [
                        <Tooltip
                          title="Save changes in category name"
                          aria-label="save"
                        >
                          <Button
                            color="success"
                            size="xs"
                            key="save"
                            onClick={() => {
                              const { node } = getNodeAtPath({
                                treeData: this.state.treeData,
                                path,
                                getNodeKey: getNodeKeyTreeIndex
                              })
                              const { id, name, level, parent_id } = node
                              this.saveCategory({
                                id,
                                name,
                                level,
                                parent_id,
                              })
                            }
                            }
                          >
                            <Done />
                          </Button>
                        </Tooltip>,
                        <Tooltip
                          title="Add child category"
                          aria-label="add"
                        >
                          <Button
                            color="rose"
                            size="xs"
                            key="add"
                            onClick={() => {
                              this.setState(state => ({
                                treeData: addNodeUnderParent({
                                  treeData: state.treeData,
                                  parentKey: node.id,
                                  expandParent: true,
                                  getNodeKey,
                                  newNode: {
                                    title: ``,
                                    parent: node.id,
                                    parent_id: node.id,
                                    level: path.length
                                  },
                                  addAsFirstChild: state.addAsFirstChild,
                                }).treeData,
                              }))
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>,
                      ],
                      title: (
                        <input
                          value={node.title}
                          onChange={event => {
                            const title = event.target.value;
                            this.setState(state => ({
                              treeData: changeNodeAtPath({
                                treeData: state.treeData,
                                path,
                                getNodeKey: getNodeKeyTreeIndex,
                                newNode: { ...node, title, name: title },
                              }),
                            }))
                          }
                          }
                        />
                      )
                    })}
                    onChange={treeData => this.setState({ treeData })}
                    searchFocusOffset={0}
                    searchMethod={customSearchMethod}
                    searchQuery={searchString}
                    treeData={this.state.treeData}
                  />
                </div>
              </div>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

export default CategoriesTree