import DashboardIcon from "@material-ui/icons/Dashboard";
import Sale from "views/Sale/Sale";

const sale = {
    path: "/sale/:category_id?",
    name: "Sale",
    icon: DashboardIcon,
    component: Sale,
    hideInMenu: false,
    matchUrl: '/sale',
    acl: ['sales-read']
};

export default sale;