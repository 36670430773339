import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from 'react-router-redux';
import promise from "redux-promise-middleware";
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from 'redux/index';
import createHistory from 'history/createBrowserHistory';

export const history = createHistory();

const middlewares = [];

middlewares.push(routerMiddleware(history));
middlewares.push(promise());
middlewares.push(thunk);

if(process.env.NODE_ENV === `development`) {
    middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
