import React, { useCallback } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

import _ from "lodash";

// @material-ui/icons
import InsertChart from "@material-ui/icons/InsertChart";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Badge from "components/Badge/Badge.jsx";
import IconCard from "components/Cards/IconCard.jsx";

const ClientReportTable = ({ classes, settings, getClientReport, report }) => {
  let total = 0;
  const {
    perPage: pageSize = 15,
    page,
    totalPages = 1,
    sorted = [],
    search = null,
  } = settings || {};

  const data = report.map((entry, i) => {
    total += 0;
    return {
      ...entry,
      soldat: "---",
      rowid: i + 1,
      status: <Badge color={false ? "success" : "gray"}>{entry.status}</Badge>,
    };
  });

  const tableColumns = [
    {
      Header: "#",
      accessor: "id",
      width: 50,
    },
    {
      Header: "SKU",
      accessor: "sku",
      width: 100,
    },
    {
      Header: "Product Name",
      accessor: "name",
      width: 200,
    },
    {
      Header: "Notes",
      accessor: "description",
    },
    {
      Header: "Priced at",
      Cell: (item) => (
        <div style={{ textAlign: "right", paddingRight: 30 }}>
          {" "}
          {item.original.price}
        </div>
      ),
      width: 110,
      Footer: <div style={{ fontSize: 20, textAlign: "right" }}>Total: </div>,
    },
    {
      Header: "Sold for",
      Cell: (item) => <div style={{ textAlign: "center" }}> ----</div>,
      width: 110,
      Footer: (
        <div style={{ fontSize: 20, textAlign: "center" }}>
          {" "}
          {total === 0 ? "---" : total}{" "}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      headerStyle: {
        textAlign: "center",
      },
      maxWidth: 90,
    },
  ];

  const handleFetchData = useCallback(
    async ({ page: nextPage, pageSize: nextPageSize, sorted: nextSorted }) => {
      const fetchDetails = { page, pageSize, sorted, search };
      const nextFetch = {
        page: nextPage,
        pageSize: nextPageSize,
        sorted: nextSorted,
        search,
      };
      if (!_.isEqual(fetchDetails, nextFetch)) {
        await getClientReport({
          page: nextPage + 1,
          pageSize: nextPageSize,
          sorted: nextSorted,
          search,
        });
      }
    },
    [page, pageSize, sorted, search, getClientReport]
  );

  return (
    <GridContainer>
      <ItemGrid xs={12}>
        <IconCard
          icon={InsertChart}
          title="Consignor Report"
          content={
            <ReactTable
              data={data}
              // filterable
              columns={tableColumns}
              defaultPageSize={pageSize}
              showPageSizeOptions
              showPageJump={false}
              showPaginationTop={false}
              multiSort={false}
              manual
              page={page}
              pages={totalPages}
              pageSizeOptions={[5, 10, 15, 20, 25, 50]}
              defaultSorted={sorted}
              onFetchData={handleFetchData}
              showPaginationBottom
              // className="-striped -highlight"
            />
          }
        />
      </ItemGrid>
    </GridContainer>
  );
};

export default ClientReportTable;
