import React, { useRef, useState, useCallback } from "react";

import Webcam from "react-webcam";

import Dialog from "material-ui/Dialog";
import DialogContent from "material-ui/Dialog/DialogContent";
import DialogTitle from "material-ui/Dialog/DialogTitle";
import DialogActions from "material-ui/Dialog/DialogActions";

import Button from "components/CustomButtons/Button.jsx";

import withStyles from "material-ui/styles/withStyles";

const styles = {
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    minWidth: "50vw",
    maxWidth: "99vw",
  },
};

const WebcamUploadDialog = (props) => {
  const { classes, onChange, onClose } = props;

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onChange(imageSrc);
    onClose();
  }, [webcamRef, setImgSrc]);

  const close = () => {
    onClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={true}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>Take a photo</DialogTitle>
      <DialogContent>
        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>Close</Button>
        <Button onClick={capture} color="rose">
          Take Photo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(WebcamUploadDialog);
