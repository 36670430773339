import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import forgot from "redux/recover/forgot";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";

// @material-ui/icons
import Mail from "@material-ui/icons/Mail";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class ForgotPage extends React.Component {
  constructor(props) {
    super(props);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      fields: {
        email: {
          name: "email",
          type: "text",
          labelText: "Registered Email",
          formControlProps: {
            fullWidth: true,
          },
          inputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <Mail
                  className={this.props.classes.inputAdornmentIcon}
                />
              </InputAdornment>
            ),
          },
        },
      },
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    document.title = "EPOS - Recover your Password";
  }

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      forgotError,
      forgotSuccess,
      isLoggingIn,
      loadingForgot,
      errorText,
    } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <ItemGrid xs={12} sm={6} md={4}>
              <form onSubmit={handleSubmit}>
                <LoginCard
                  customCardClass={classes[this.state.cardAnimaton]}
                  headerColor="rose"
                  cardTitle="Recover your Password"
                  footerAlign="center"
                  footer={
                    <Button
                      color="roseNoBackground"
                      wd
                      size="lg"
                      type="submit"
                      disabled={isLoggingIn || submitting || loadingForgot}
                    >
                      Submit
                    </Button>
                  }
                  content={
                    <div>
                      {forgotError ? (
                        <SnackbarContent
                          message={`Recover Password failed. Please try again. ( ${errorText} )`}
                          close
                          color="danger"
                        />
                      ) : null}

                      {forgotSuccess ? (
                        <SnackbarContent
                          message={`An Email with a recovery link has been sent to the provided email`}
                          close
                          color="success"
                        />
                      ) : null}

                      <Field
                        component={CustomInput}
                        {...this.state.fields.email}
                      />
                    </div>
                  }
                />
              </form>
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgotError: state.recover.forgotError,
    forgotSuccess: state.recover.forgotSuccess,
    loadingForgot: state.recover.loadingForgot,
    errorText: state.recover.errorText,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgot,
    },
    dispatch
  );
};

export default compose(
  withStyles(loginPageStyle),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "forgot",
    onSubmit: ({ email }, dispatch, { forgot }) => {
      forgot(email);
    },
  })
)(ForgotPage);
