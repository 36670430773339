import Contacts from "@material-ui/icons/Contacts";
import CreateClient from "../../views/Clients/CreateClient";
import EditClient from "../../views/Clients/EditClient";
import ClientsListing from "../../views/Clients/ClientsListing";
import ClientReport from "../../views/Clients/ClientReport";

const clients = {
    path: "/clients/list",
    parentPath: "/clients/",
    name: "Consignor",
    icon: Contacts,
    component: ClientsListing,
    hideInMenu: false,
    matchUrl: '/clients/list',
    acl: ['clients-read']
};

export const clientsInner = [{
    path: "/clients/create",
    name: "Create Consignor",
    mini: "CC",
    component: CreateClient,
    hideInMenu: true,
    matchUrl: '/clients/create',
    acl: ['clients-create']
}, {
    path: "/clients/edit/:id",
    name: "Edit Consignor",
    mini: "EC",
    component: EditClient,
    hideInMenu: true,
    matchUrl: '/clients/edit',
    acl: ['clients-edit']
}, {
    path: "/clients/report/:id",
    name: "Consignor Report",
    mini: "CR",
    component: ClientReport,
    hideInMenu: true,
    matchUrl: '/categories/edit',
    acl: ['reports-read']
}];

export default clients;