import React from 'react'
import BreadcrumbCard from './BreadcrumbCard';
import CategoryThumbView from './CategoryThumbView';
import SweetAlert from 'react-bootstrap-sweetalert';
import { serviceAxios as axios } from '../../bootstrap';
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import { BarLoader } from 'react-css-loaders';
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

class CategoriesView extends React.Component {

    state = {
        categoryId: 0,
        categories: null,
        categoriesById: null,
        isLoadingCategories: false
    }

    componentDidMount() {
        this.setState({ categoryId: this.props.categoryId })
        if(!this.state.isLoadingCategories || !this.state.categories) {
            this.getCategories();
        }
    }

    setThumbView = element => {
        this.thumbView = element;
    }

    showCategory = (id) => {
        if (this.props.modal) {
            this.setState({ categoryId: id })
            this.thumbView.scrollTop = 0
        } else {
            this.props.history.replace(`/sale/${id}`)
        }
    }

    getCategoryPath = () => {
        let id = this.state.categoryId
        let path = [];

        while (id > 0) {
            let cat = this.state.categoriesById[id]
            path.unshift({ name: cat.name, id: id })
            id = cat.parent_id
        }
        path.unshift({ name: 'Home', id: id })
        return path
    }

    getCategories = () => {
        axios.get('/api/categories')
        .then( response => {
            this.setState({
                isLoadingCategories: false,
                categories: response.data.data,
                categoriesById: response.data.data
                .reduce((map, cat) => {
                    map[cat.id] = cat
                    return map
                }, {})
            })
        }).catch( error => {
            this.setState({isLoadingCategories: false})
            this.showAlert("Retreiving Categories Failed", extractErrorMessage(error))
        })
    }

    showAlert(title, message) {
        this.setState({
          alert: (
            <SweetAlert
                danger
                title= {title}
                confirmBtnText="Continue"
                onConfirm={() => this.hideAlert()}
                confirmBtnCssClass= {
                    this.props.classes.button + " " + this.props.classes.danger
                }
            >
                {message}
            </SweetAlert>
          )
        });
    }

    hideAlert() {
        this.setState({
          alert: null
        });
    }


    render() {
        return (
            <div>
                { this.state.alert }
                { (this.state.isLoadingCategories || !this.state.categories) ?
                    <BarLoader color="#d81b60" size={8} /> : (
                    <div>
                        <div>
                            <BreadcrumbCard
                                onClick={this.showCategory}
                                headerColor="rose"
                                tabs={this.getCategoryPath().map(cat => ({ tabName: cat.name, id: cat.id }))}
                                modal={this.props.modal}
                                onSelect={this.props.onSelect}
                            />
                        </div>
                        <div>
                            <div ref={this.setThumbView} style={{ height: this.props.height || "72vh", width: this.props.width, overflow: "auto" }}>
                            <CategoryThumbView categories={this.state.categories.filter(x => x.parent_id===this.state.categoryId)}
                                    onClick={this.showCategory}
                                    productId={this.props.productId}>
                                    {
                                        React.cloneElement(this.props.children, {categoryId: this.state.categoryId})
                                    }
                                </CategoryThumbView>
                            </div>
                        </div>
                    </div>
                    )
                }
            </div>
        )
    }
}

export default compose(
    withRouter,
    withStyles(buttonStyle)
) (CategoriesView);
