import React from 'react';
import { compose, withProps } from 'recompose';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import withStyles from "material-ui/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Contacts from "@material-ui/icons/Contacts";
import { Field, reduxForm } from 'redux-form';
import CreateValidator from '../../validators/clients/CreateValidator';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IsEnabledSwitch from '../Components/IsEnabledSwitch';
import GuardedComponent from 'components/Authorization/GuardedComponent';

const CreateClientForm = (props) => {

    const {
        handleSubmit,
        submitting
    } = props;

    return (
        <GridContainer>
            <ItemGrid xs={12} sm={12} md={12}>
                <IconCard
                    icon={Contacts}
                    iconColor="rose"
                    title="Create Consignor"
                    content={
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='firstName'
                                        labelText="First Name"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='lastName'
                                        labelText="Last Name"
                                        formControlProps={{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>

                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput} 
                                        name='email'
                                        labelText= 'Email'
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "email"
                                        }}
                                    />
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='phone'
                                        labelText= "Phone"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>

                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <Field component={CustomInput} 
                                        name= 'street'
                                        labelText= "Street"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>



                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={CustomInput} 
                                         name='city'
                                         labelText="City"
                                         formControlProps= {{
                                             fullWidth: true
                                         }}/>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={CustomInput}
                                        name= 'state'
                                        labelText= "State"
                                        formControlProps= {{
                                            fullWidth: true
                                        }}/>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={CustomInput}
                                        name= 'zip'
                                        labelText= "Zip"
                                        formControlProps= {{
                                            fullWidth: true
                                        }}/>
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <Field component={CustomInput}
                                           name= 'company'
                                           labelText= "Consignor/Estate Code"
                                           formControlProps= {{
                                               fullWidth: true
                                           }}/>
                                </ItemGrid>
                            </GridContainer>

                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <Field component={CustomInput}
                                           name='notes'
                                           labelText= "Notes"
                                           inputProps={{
                                                multiline: true,
                                                rowsMax:4,
                                                rows:4
                                           }}
                                           formControlProps= {{
                                               fullWidth: true
                                           }}/>
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>

                                <ItemGrid xs={12} sm={12} md={4}>
                                    <p>Status</p>
                                    <Field
                                        component = { IsEnabledSwitch }
                                        name = "enabled" />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                            <h4>Secondary Contact Information</h4>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='contact_info_name'
                                        labelText="Full Name"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='contact_info_address'
                                        labelText="Address"
                                        formControlProps={{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='contact_info_email'
                                        labelText="Email"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='contact_info_phone'
                                        labelText="Phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='contact_info_role'
                                        labelText="Role"
                                        formControlProps={{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GuardedComponent scope={['clients-create']}>
                                <Button color="rose" right type="submit" disabled={submitting}>
                                    Create
                                </Button>
                            </GuardedComponent>
                        </form>
                    }
                />
            </ItemGrid>
        </GridContainer>
    );
};

export default compose(
  withStyles(regularFormsStyle),
  withProps(() => {
    return {
        initialValues: {
          enabled: true
        }
      }
  }),
  reduxForm({
    form: 'createClient',
    validate: (values) => {
      const validator = new CreateValidator();
      const result = validator.validate(values);
      return result.valid ? {} : result.errors;
    }
  })
)(CreateClientForm);