const ChartFooterIntervalStyles = {
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
  },
  input: {
    "& .select": {
      padding: "2px 8px",
      fontSize: "14px",
    },
  },
  item: {
    padding: "4px 8px",
    margin: 0,
  },
};

export default ChartFooterIntervalStyles;
