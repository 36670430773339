import React from "react";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";
import Grid from "material-ui/Grid";

// @material-ui/icons
import Search from "@material-ui/icons/Search";

// core components
import SweetAlert from "react-bootstrap-sweetalert";
import ShoppingCart from "./ShoppingCart";
import RegularCard from "components/Cards/RegularCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import CategoriesView from "./CategoriesView";
import BarcodeScanner from "../Components/BarcodeScanner";
import { serviceAxios as axios } from "../../bootstrap";
import CategoryProducts from "./CategoryProducts";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import qs from "qs";
import ViewProduct from "../Products/ViewProduct";
import { NotificationManager } from "react-notifications";
import Checkout from "./Checkout";
import withConnected from "utilities/withConnected";

class Sale extends React.Component {
  state = {
    productId: null,
  };

  componentDidMount() {
    document.title = "EPOS - Sale";
    // Display successful transaction message
    if (this.props.location.search) {
      const query = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      if (query && query.transactionCompleted) {
        this.showAlert(
          query.transactionCompleted === "true"
            ? "Transaction completed successfully!"
            : "Transaction failed. Please try again",
          query.transactionCompleted === "true"
        );
        // Clear url path
        this.props.history.push("/sale");
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let productId = null;
    if (nextProps.location.search) {
      const query = qs.parse(nextProps.location.search, {
        ignoreQueryPrefix: true,
      });
      if (query && query.product_id) {
        productId = query.product_id;
      }
    }
    if (productId !== prevState.productId) {
      return {
        productId,
      };
    }
    return null;
  }

  codeScanned = async (code) => {
    NotificationManager.success(`Barcode Scanned - ${code}`, "Success");
    try {
      const codeResponse = await axios.get(`/api/products?sku=${code}`);
      if (codeResponse.data.data.length === 1) {
        const product = codeResponse.data.data[0];
        NotificationManager.success(`Opening product details`, "Success");
        this.props.history.push(
          `/sale/${product.category_id}?product_id=${product.id}`
        );
      } else {
        NotificationManager.warning(`Unknown SKU`, "Warning");
      }
    } catch (error) {
      this.showAlert("Problem with getting a product for a SKU");
    }
  };

  clickProduct = (productId) => {
    let newURL = `/sale/${this.props.match.params.category_id}?product_id=${productId}`;
    this.props.history.push(newURL);
  };

  showAlert = (title, success = false, message = "") => {
    this.setState({
      alert: (
        <SweetAlert
          success={success}
          danger={!success}
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${
            success ? this.props.classes.primary : this.props.classes.danger
          }`}
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    const classes = this.props.classes;
    let category_id = this.props.match.params.category_id;
    let categoriesContent = null;

    if (this.state.productId) {
      categoriesContent = (
        <ViewProduct
          key={this.state.productId}
          productId={this.state.productId}
        />
      );
    } else {
      categoriesContent = <CategoryProducts onClick={this.clickProduct} />;
    }

    if (!category_id) {
      category_id = 0;
    } else {
      category_id = parseInt(category_id, 10);
    }
    return (
      <div>
        {this.state.alert}
        <Grid container spacing={8}>
          <Grid item xs={12} sm={7} md={8} style={{ padding: "2px 7px" }}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  id="search"
                  labelText="Search for SKU or product name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onKeyPress: this.handleSearchKeyPress,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                  meta={{ touched: false, error: false }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <CategoriesView
                  key={category_id}
                  categoryId={category_id}
                  modal={false}
                  productId={this.state.productId}
                  height="75vh"
                >
                  {categoriesContent}
                </CategoriesView>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} md={4} style={{ padding: "2px 15px" }}>
            <RegularCard content={<ShoppingCart />} contentAlign="center" />
          </Grid>
        </Grid>
        <BarcodeScanner
          regexp="([A-Z]{3})\w+"
          prefix="#"
          onCodeScanned={this.codeScanned}
        />
        <Checkout />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withConnected,
  withStyles(buttonStyle)
)(Sale);
