export const FORGOT_START = "FORGOT_START";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAILED = "FORGOT_FAILED";
export const VERIFY_START = "VERIFY_START";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILED = "VERIFY_FAILED";
export const RESET_START = "RESET_START";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAILED = "RESET_FAILED";

const defaultState = {
  forgotError: false,
  forgotSuccess: false,
  loadingForgot: false,
  verifyError: false,
  verifySuccess: false,
  loadingVerify: false,
  resetError: false,
  resetSuccess: false,
  loadingReset: false,
  errorText: null,
};

const recover = (state = defaultState, action) => {
  switch (action.type) {
    case FORGOT_START:
      return {
        ...state,
        loadingForgot: true,
        forgotError: false,
        forgotSuccess: false,
        errorText: null,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        loadingForgot: false,
        forgotError: false,
        forgotSuccess: true,
        errorText: null,
      };
    case FORGOT_FAILED:
      return {
        ...state,
        loadingForgot: false,
        forgotError: true,
        forgotSuccess: false,
        errorText: action.payload.errorText,
      };
    case VERIFY_START:
      return {
        ...state,
        loadingVerify: true,
        verifyError: false,
        verifySuccess: false,
        errorText: null,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        loadingVerify: false,
        verifyError: false,
        verifySuccess: true,
        errorText: null,
      };
    case VERIFY_FAILED:
      return {
        ...state,
        verifyError: true,
        verifySuccess: false,
        loadingVerify: false,
        errorText: action.payload.errorText,
      };
    case RESET_START:
      return {
        ...state,
        loadingReset: true,
        resetError: false,
        resetSuccess: false,
        errorText: null,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        loadingReset: false,
        resetError: false,
        resetSuccess: true,
        errorText: null,
      };
    case RESET_FAILED:
      return {
        ...state,
        loadingReset: false,
        resetError: true,
        resetSuccess: false,
        errorText: action.payload.errorText,
      };
    default:
      return state;
  }
};

export default recover;
