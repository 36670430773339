import React, { useState, useEffect, useCallback } from "react";
import { serviceAxios as axios } from "../../bootstrap";

import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";

import withStyles from "material-ui/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

class RoleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
    };
  }

  componentDidMount() {
    this.getRoles();
  }

  getRoles = () => {
    axios
      .get("/api/roles")
      .then((response) => {
        this.setState({ roles: response.data.data });
      })
      .catch((error) => {});
  };

  render() {
    const {
      input: { value, onChange },
      classes,
    } = this.props;
    const { roles } = this.state;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel className={classes.selectLabel} htmlFor="simple-select">
          User Role
        </InputLabel>
        <Select
          value={value}
          onChange={onChange}
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select",
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Choose Role
          </MenuItem>
          {roles.map((role) => (
            <MenuItem
              value={role.id}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
            >
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(extendedFormsStyle)(RoleSelect);
