import React from "react";
import PropTypes from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "components/Cards/RegularCard.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

const GeneralError = (props) => {

    const { classes, error } = props;

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <ItemGrid xs={12} sm={12} md={12}>
                    <RegularCard
                        cardTitle={ error.title }
                        titleAlign="center"
                        customCardTitleClasses={ classes.cardTitle }
                        customCardClasses={ classes.cardClasses }
                        content={
                            <GridContainer justify="center">
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <InfoArea
                                        title={ error.httpCode ? `${error.httpCode}` : '' }
                                        description={ error.message }
                                        icon={ErrorOutline}
                                        iconColor="rose"
                                    />
                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                </ItemGrid>
            </GridContainer>
        </div>
    );
};

GeneralError.propTypes = {
    classes: PropTypes.object.isRequired,
    p: PropTypes.shape({
        t: PropTypes.func.isRequired,
        tc: PropTypes.func.isRequired,
        tu: PropTypes.func.isRequired,
        tm: PropTypes.func.isRequired,
    }),
    error: PropTypes.shape({
        title: PropTypes.any,
        httpCode: PropTypes.number,
        message: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = (state) => {
    return {
        error: state.general.error,
    }
};

export default compose(
    withStyles(registerPageStyle),
    connect(mapStateToProps),
)(GeneralError);
