import {
  LOADING_PRODUCTS,
  DELETED_REPORT,
  LOADING_REPORT,
  LOADED_REPORT,
  LOAD_CLIENTS,
  LOAD_SQUARE_TAXES,
  LOAD_SQUARE_LOCATIONS,
  LOADED_PRODUCTS,
  LOADED_REPORT_DETAIL,
  EXPORTING_FILE,
  EXPORTED_FILE,
  CLOSE_EXPORTING_FILE,
  TOGGLE_CHECK_REPORT,
  REQUEST_ARCHIVE_REPORTS,
  RECEIVE_ARCHIVE_REPORTS,
} from "../types";

import { serviceAxios as axios } from "../../bootstrap";

import moment from "moment";

export const loadReports = (archive, page, perPage, sorted) => {
  return (dispatch) => {
    const sort = sorted?.[0]?.id;
    const sortDirection = sorted?.[0]?.asc ? "asc" : "desc";
    const queryParams = {
      page,
      per_page: perPage,
      sort,
      sort_direction: sortDirection,
      archived: archive ? 1 : 0,
    };
    dispatch({ type: LOADING_REPORT });
    axios.get(`/api/reports`, { params: queryParams }).then((response) => {
      dispatch({ type: LOADED_REPORT, payload: response.data });
    });
  };
};
export const loadArchivedReports = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_REPORT });
    axios.get("/api/reports?archived=1").then((response) => {
      dispatch({ type: LOADED_REPORT, payload: response.data });
    });
  };
};

export const loadClients = (args) => {
  const params= {
    per_page: Number.MAX_SAFE_INTEGER,
    sort: "code",
    sort_direction: "asc",
  };
  if(args?.enabled != null) { 
    params.enabled = args.enabled;
  }
  return (dispatch) => {
    axios
      .get("/api/clients", {
        params,
      })
      .then((response) => {
        dispatch({ type: LOAD_CLIENTS, payload: response.data });
      });
  };
};

export const loadSquareTaxes = () => {
  return (dispatch) => {
    axios.get("/api/square/taxes").then((response) => {
      dispatch({ type: LOAD_SQUARE_TAXES, payload: response.data });
    });
  };
};

export const loadSquareLocations = () => {
  return (dispatch) => {
    axios.get("/api/square/locations").then((response) => {
      dispatch({ type: LOAD_SQUARE_LOCATIONS, payload: response.data });
    });
  };
};

export const loadProducts = (values) => {
  const defaultParams = {
    page: 1,
    per_page: 15,
  };
  if (values.start_date) {
    values.start_date = moment(values.start_date).format("YYYY-MM-DD");
  }
  if (values.end_date) {
    values.end_date = moment(values.end_date).format("YYYY-MM-DD");
  }

  Object.keys(values).forEach(
    (key) => values[key] === undefined && delete values[key]
  );

  return (dispatch) => {
    dispatch({ type: LOADING_PRODUCTS });
    axios
      .get("api/products", {
        params: {
          ...defaultParams,
          ...values,
        },
      })
      .then((response) => {
        dispatch({ type: LOADED_PRODUCTS, payload: response.data, values });
      });
  };
};

export const editReport = (id, values) => {
  if (values.start_date) {
    values.start_date = moment(values.start_date).format("YYYY-MM-DD");
  }
  if (values.end_date) {
    values.end_date = moment(values.end_date).format("YYYY-MM-DD");
  }
  return (dispatch) => {
    axios
      .put("api/reports/" + id, { params: values })
      .then(() => (window.location.href = "/reports/detail/" + id));
  };
};

export const createReport = (values) => {
  if (values.query.start_date) {
    values.query.start_date = moment(values.query.start_date).format(
      "YYYY-MM-DD"
    );
  }
  if (values.query.end_date) {
    values.query.end_date = moment(values.query.end_date).format("YYYY-MM-DD");
  }
  return (dispatch) => {
    axios
      .post("api/reports", values)
      .then(() => (window.location.href = "/reports/list"));
  };
};

export const getReport = (id) => {
  return (dispatch) => {
    axios.get("api/reports/" + id).then((response) => {
      dispatch({ type: LOADED_REPORT_DETAIL, payload: response.data });

      dispatch(loadProducts(Object.assign({}, response.data.data.query)));
    });
  };
};

export const deleteReport = (id) => {
  return (dispatch) => {
    axios.delete("api/reports/" + id).then((response) => {
      dispatch({ type: DELETED_REPORT, payload: response.data });
      window.location.href = "/reports/list";
    });
  };
};

export const exportFileEmail = (type, reportId, values) => {
  return (dispatch) => {
    dispatch({ type: EXPORTING_FILE });
    axios
      .post(`api/reports/${reportId}/send_email/${type}`, values)
      .then(() => {
        dispatch({ type: EXPORTED_FILE });
        setTimeout(() => {
          dispatch({ type: CLOSE_EXPORTING_FILE });
        }, 4000);
      });
  };
};

export const exportFile = (type, reportId) => {
  return (dispatch) => {
    dispatch({ type: EXPORTING_FILE });
    axios.get(`api/reports/${reportId}/download/${type}`).then((res) => {
      dispatch({ type: EXPORTED_FILE });
      let { link } = res.data;
      window.open(`${axios.defaults.baseURL}/${link}`);
      setTimeout(() => {
        dispatch({ type: CLOSE_EXPORTING_FILE });
      }, 4000);
    });
  };
};

export const toggleCheckReport = (reportId) => {
  return {
    type: TOGGLE_CHECK_REPORT,
    reportId,
  };
};

export const archiveReports = (reports) => {
  const ids = reports.map((r) => r.id);

  return (dispatch) => {
    dispatch({ type: REQUEST_ARCHIVE_REPORTS, ids });
    axios
      .put(`/api/update_many/reports`, { ids, data: { archived: 1 } })
      .then((res) => {
        dispatch({ type: RECEIVE_ARCHIVE_REPORTS });
        dispatch(loadReports());
      });
  };
};
