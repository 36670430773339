import React from "react";
import { BarLoader } from "react-css-loaders";
import { serviceAxios as axios } from "../../bootstrap";
import _ from "lodash";
import ClientsTable from "./ClientsTable";
import SweetAlert from "react-bootstrap-sweetalert";
import extractErrorMessage from "../../utilities/HttpErrorResponse";

import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import BarcodesPrinter from "./BarcodesPrinter";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";

class ClientsListing extends React.Component {
  state = {
    isLoadingClients: false,
    clients: null,
    tableSettings: null,
    alert: null,
    openBarcodePrinting: null,
    searchCriteria: "",
  };

  componentDidMount() {
    document.title = "EPOS - Consignor";

    if (!this.state.isLoadingClients) {
      this.getClients();
    }
  }

  getClients = ({ page = 1, pageSize = 15, sorted = [], search } = {}) => {
    this.setState({ isLoadingClients: true }, () => {
      const sort = sorted?.[0]?.id;
      const sortDirection = sorted?.[0]?.desc ? "desc" : "asc";
      const queryParams = {
        page,
        per_page: pageSize,
        sort,
        sort_direction: sortDirection,
      };
      if (search) {
        queryParams.search = search;
      }
      axios
        .get("/api/clients", {
          params: queryParams,
        })
        .then((response) => {
          this.setState({
            isLoadingClients: false,
            clients: response.data.data,
            tableSettings: {
              sorted,
              search,
              ...response.data.meta?.pagination,
            },
          });
        })
        .catch((error) => {
          this.setState({ submitting: false });
          this.showAlert(
            "Retreiving Consignor Failed",
            extractErrorMessage(error)
          );
        });
    });
  };

  deleteClient = (id) => {
    axios
      .delete(`/api/clients/${id}`)
      .then((response) => {
        this.getClients();
      })
      .catch((error) => {
        this.showAlert("Delete Failed", extractErrorMessage(error));
      });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  showAlert(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  deleteClientAlert = (client) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Delete"
          title={
            "Delete Consignor - " +
            client.firstName +
            " " +
            client.lastName +
            "?"
          }
          onConfirm={() => {
            this.hideAlert();
            this.deleteClient(client.id);
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.default
          }
        >
          Are you sure you want to delete? The Consignor will be deleted
          immediately, and can't be restored.
        </SweetAlert>
      ),
    });
  };

  debounceEventHandler = (...args) => {
    const debounced = _.debounce(...args);
    return function (e) {
      e.persist();
      return debounced(e);
    };
  };

  handleInputChange = (e) => {
    const searchCriteria = e.target.value.toLowerCase();
    this.setState({ searchCriteria });
    this.setState({ searchCriteria }, async () => {
      await this.getClients({
        ...this.state.tableSettings,
        search: this.state.searchCriteria,
      });
    });
  };

  render() {
    const classes = this.props.classes;
    const { openBarcodePrinting, isLoadingClients, tableSettings, clients } =
      this.state;

    return (
      <div>
        <CustomInput
          id="search"
          labelText="Search for Consignor name"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            autoComplete: "off",
            onChange: this.debounceEventHandler(this.handleInputChange, 500),
            endAdornment: (
              <InputAdornment position="end">
                <Search className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
          meta={{ touched: false, error: false }}
        />
        {this.state.alert}
        {isLoadingClients || !clients ? (
          <BarLoader color="#d81b60" size={8} />
        ) : (
          <ClientsTable
            onBarcodePrinting={(client) =>
              this.setState({ openBarcodePrinting: client })
            }
            clients={clients}
            getClients={this.getClients}
            onClickDelete={this.deleteClientAlert}
            settings={{
              perPage: tableSettings?.per_page || 15,
              page: tableSettings?.current_page - 1 || 0,
              totalPages: tableSettings?.total_pages || 1,
              sorted: tableSettings?.sorted || [],
              search: tableSettings?.search || null,
            }}
          />
        )}
        <BarcodesPrinter
          onClose={() => this.setState({ openBarcodePrinting: null })}
          client={openBarcodePrinting}
        />
      </div>
    );
  }
}

export default withStyles(buttonStyle)(ClientsListing);
