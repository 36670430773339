import React from "react";
import { BarLoader } from "react-css-loaders";
import { serviceAxios as axios } from "../../bootstrap";
import _ from "lodash";
import CustomersTable from "./CustomersTable";
import SweetAlert from "react-bootstrap-sweetalert";
import extractErrorMessage from "../../utilities/HttpErrorResponse";

import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";

class CustomersListing extends React.Component {
  state = {
    isLoadingCustomers: false,
    customers: null,
    tableSettings: null,
    searchCriteria: "",
  };

  componentDidMount() {
    document.title = "EPOS - Customers";

    if (!this.state.isLoadingCustomers) {
      this.getCustomers();
    }
  }

  getCustomers = ({ page = 1, pageSize = 15, sorted = [], search } = {}) => {
    this.setState(
      {
        isLoadingCustomers: true,
      },
      () => {
        const sort = sorted?.[0]?.id;
        const sortDirection = sorted?.[0]?.desc ? "desc" : "asc";
        const queryParams = {
          page,
          per_page: pageSize,
          sort,
          sort_direction: sortDirection,
        };
        if (search) {
          queryParams.search = search;
        }
        axios
          .get("/api/customers", {
            params: queryParams,
          })
          .then((response) => {
            this.setState({
              isLoadingCustomers: false,
              customers: response.data.data,
              tableSettings: {
                sorted,
                search,
                ...response.data.meta?.pagination,
              },
            });
          })
          .catch((error) => {
            this.setState({ submitting: false });
            this.showAlert(
              "Retreiving Customers Failed",
              extractErrorMessage(error)
            );
          });
      }
    );
  };

  syncCustomers = () => {
    this.setState(
      {
        isLoadingCustomers: true,
      },
      () => {
        axios
          .post(`/api/customers/sync`)
          .then((response) => {
            const { completed, errors } = response.data;
            const messageLines = [
              `${Object.keys(completed).length} customer(s) were synchronized`,
              `${Object.keys(errors).length} records contained errors:`,
              ...Object.keys(errors).map(
                (key) =>
                  `Square ID: ${key} - ${
                    errors[key] ? errors[key].message : ""
                  } \n`
              ),
            ];
            this.showSyncAlert("Customers Sync Completed", true, messageLines);
            this.getCustomers();
          })
          .catch((error) => {
            const { completed, errors } = error.response.data;
            if (completed || errors) {
              const messageLines = [
                `${
                  Object.keys(completed).length
                } customer(s) were synchronized`,
                `${Object.keys(errors).length} records contained errors:`,
                ...Object.keys(errors).map(
                  (key) =>
                    `Square ID: ${key} - ${
                      errors[key] ? errors[key].message : ""
                    } \n`
                ),
              ];
              this.showSyncAlert("Customers Sync Failed", false, messageLines);
            } else {
              this.showAlert("Sync Failed", extractErrorMessage(error));
            }
            this.setState({ isLoadingCustomers: false });
          });
      }
    );
  };

  deleteCustomer = (id) => {
    axios
      .delete(`/api/customers/${id}`)
      .then((response) => {
        this.getCustomers();
      })
      .catch((error) => {
        this.showAlert("Delete Failed", extractErrorMessage(error));
      });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  showAlert(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  showSyncAlert(title, success = false, messageLines = []) {
    this.setState({
      alert: (
        <SweetAlert
          success={success}
          danger={!success}
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button +
            " " +
            (success ? this.props.classes.primary : this.props.classes.danger)
          }
        >
          {messageLines.map((message) => (
            <p style={{ fontSize: "14px" }}>{message}</p>
          ))}
        </SweetAlert>
      ),
    });
  }

  deleteCustomerAlert = (customer) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={
            "Delete customer - " +
            customer.firstName +
            " " +
            customer.lastName +
            " ?"
          }
          showCancel
          confirmBtnText="Delete"
          onConfirm={() => {
            this.hideAlert();
            this.deleteCustomer(customer.id);
          }}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.default
          }
        >
          Are you sure you want to delete? The customer will be deleted
          immediately, and can't be restored.
        </SweetAlert>
      ),
    });
  };

  debounceEventHandler = (...args) => {
    const debounced = _.debounce(...args);
    return function (e) {
      e.persist();
      return debounced(e);
    };
  };

  handleInputChange = (e) => {
    const searchCriteria = e.target.value.toLowerCase();
    this.setState({ searchCriteria }, async () => {
      await this.getCustomers({
        ...this.state.tableSettings,
        search: this.state.searchCriteria,
      });
    });
  };

  render() {
    const classes = this.props.classes;
    const { isLoadingCustomers, tableSettings, customers } = this.state;

    return (
      <div>
        <CustomInput
          id="search"
          labelText="Search for Customer name"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            autoComplete: "off",
            onChange: this.debounceEventHandler(this.handleInputChange, 500),
            endAdornment: (
              <InputAdornment position="end">
                <Search className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
          meta={{ touched: false, error: false }}
        />
        {this.state.alert}
        {isLoadingCustomers || !customers ? (
          <BarLoader color="#" size={8} />
        ) : (
          <CustomersTable
            customers={customers}
            getCustomers={this.getCustomers}
            syncCustomers={this.syncCustomers}
            onClickDelete={this.deleteCustomerAlert}
            settings={{
              perPage: tableSettings?.per_page || 15,
              page: tableSettings?.current_page - 1 || 0,
              totalPages: tableSettings?.total_pages || 1,
              sorted: tableSettings?.sorted || [],
              search: tableSettings?.search || null,
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(buttonStyle)(CustomersListing);
