import React from "react";

/**
 *  This component depends on the barcode reader being programmed with a single char prefix
 *  which is then send to this component in the prefix prop.
 *  It also depends that the barcode reader sends char ' ' (space) as a sufix
 *  It will prevent you typing the prefix from the keyboard.
 *  FIXME: Differentiate between keys from the barcode reader and the keyboard, so that the prefix key
 *         remains usable from the keyboard. One possible solution is checking the evt.char which doesn't
 *         seem to be populated by the barcode, but it is when typed from the keyboard. We would need to do
 *         testing with different types of barcode readers / browser types
 */

class BarcodeScanner extends React.Component {
  state = {
    scannedCode: "",
    isScanning: false,
    scanTimeoutHandler: null,
  };

  componentDidMount() {
    document.addEventListener("keypress", this.keyClicked, true);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.keyClicked, true);
  }

  keyClicked = (ev) => {
    ev = ev || window.event;
    if (!ev.charCode) {
      return;
    }

    const char = String.fromCharCode(ev.charCode);
    const charCode = ev.charCode;
    const regexp = new RegExp(this.props.regexp);

    // This block ENDS the char capture (scanning) process
    // it validates if there is an 'H' and the end or an `Enter` during the
    // isScanning state
    if ((char === "H" || charCode === 13) && this.state.isScanning) {
      if (this.state.scanTimeoutHandler) {
        clearTimeout(this.state.scanTimeoutHandler);
      }
      const { scannedCode } = this.state;
      // Validates if the Scanned Code is correct
      if (regexp.test(scannedCode)) {
        this.props.onCodeScanned(this.state.scannedCode);
      }

      this.setState((state) => ({
        scannedCode: "",
        isScanning: false,
        scanTimeoutHandler: null,
      }));
      ev.preventDefault();
      return;
    }

    // It STARTS the char capture (scanning) process
    if (
      char === this.props.prefix ||
      (!this.state.isScanning && ev.target.localName === "body")
    ) {
      let scanTimeoutHandler = setTimeout(this.scanTimeout, 500);
      this.setState({
        scannedCode: char === this.props.prefix ? "" : char, // Removes the prefix if there is one
        isScanning: true,
        scanTimeoutHandler: scanTimeoutHandler,
      });
      ev.preventDefault();
      return;
    }

    // It captures any set character DURING the scanning process
    if (this.state.isScanning) {
      this.setState((state) => ({
        scannedCode: state.scannedCode + char,
      }));
      ev.preventDefault();
    }
  };

  scanTimeout = () => {
    this.setState({ isScanning: false, scanTimeoutHandler: null });
  };

  render() {
    return <span> {/* Nothing to show! */} </span>;
  }
}

export default BarcodeScanner;
