import React from 'react'

import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";

import withConnected from 'utilities/withConnected';
import withStyles from "material-ui/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

function SelectClient(props) {
    console.log(props)
        const {
            clientName,
            clientCode,
            input: {
                value,
                onChange,
                onUpdate,
            },
            classes,
            rdx: {
                state
            },
            meta
        } = props;

        let labelClass = classes.selectLabel
        let selectFormClass = classes.selectFormControl
        if(meta && meta.error && (meta.touched || meta.submitFailed) ) {
            labelClass =  classes.labelError
            selectFormClass = classes.selectFormControlError
        }

        const items = state.reports.clients.map((client, i) => {
            return (
                <MenuItem key={i} value={client.id} classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                    }}
                >
                    {client.code} - {client.company}
                </MenuItem>
            )
        })
        if(clientCode && state.reports.clients.findIndex((client) => client.id === value) === -1) {
            items.push(
                <MenuItem key={state.reports.clients.length} value={value} classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                    }}
                >
                    (Disabled consignor) {clientName} - {clientCode}
                </MenuItem>
            
            )
        }
        return (
            <FormControl
            fullWidth
            className={selectFormClass}
            >
                <InputLabel
                    className={labelClass}
                    htmlFor="simple-select">
                    SELECT A CONSIGNOR *
                </InputLabel>
                <Select
                    value={value}
                    onChange={onChange}
                    onUpdate={onUpdate}
                    disabled={props.disabled}
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select,
                    }}
                    inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                    }}>
                    {items}
                </Select>
            </FormControl>
        )
}

export default withConnected(withStyles(extendedFormsStyle)(SelectClient))