import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import PermIdentity from "@material-ui/icons/PermIdentity";
import { Field, reduxForm } from 'redux-form';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileValidator from 'validators/auth/ProfileValidator';
import editProfile from 'redux/auth/editProfile';

const Form = (props) => {

    const {
        handleSubmit,
        fields,
        isUpdatingUser,
        submitting,
    } = props;

    return (
        <ItemGrid xs={12} sm={12} md={8}>
            <IconCard
                icon={PermIdentity}
                iconColor="rose"
                title="Edit Profile"
                content={
                    <div>
                        <form onSubmit={ handleSubmit }>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } { ...fields.role } />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } { ...fields.email } />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } { ...fields.firstName } />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } { ...fields.lastName } />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } { ...fields.password } />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } { ...fields.password_confirmation } />
                                </ItemGrid>
                            </GridContainer>
                            <Button color="rose" right type="submit" disabled={isUpdatingUser || submitting}>
                                Submit
                            </Button>
                            <Clearfix />
                        </form>
                    </div>
                }
            />
        </ItemGrid>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        isUpdatingUser: state.auth.isUpdatingUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        editProfile,
    }, dispatch);
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withProps(({ user }) => {
       return {
           initialValues: {
                role: user.role.toLowerCase(),
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
           },
           fields: {
               role: {
                    name: "role",
                    type: "text",
                    labelText: "Role",
                    formControlProps: {
                        fullWidth: true
                    },
                    inputProps: {
                        disabled: true
                    }
                },
               email: {
                   name: "email",
                   type: "text",
                   labelText: "Email",
                   formControlProps: {
                       fullWidth: true
                   }
               },
               firstName: {
                   name: "firstName",
                   type: "text",
                   labelText: "First Name",
                   formControlProps: {
                       fullWidth: true
                   }
               },
               lastName: {
                   name: "lastName",
                   type: "text",
                   labelText: "Last Name",
                   formControlProps: {
                       fullWidth: true
                   }
               },
               password: {
                   name: "password",
                   labelText: "Password",
                   formControlProps: {
                       fullWidth: true
                   },
                   inputProps: {
                       type: "password",
                   }
               },
               password_confirmation: {
                   name: "password_confirmation",
                   labelText: "Confirm Password",
                   formControlProps: {
                       fullWidth: true
                   },
                   inputProps: {
                       type: "password",
                   }
               }
           }
       }
    }),
    reduxForm({
        form: 'edit_profile',
        validate: (values) => {

            const validator = new ProfileValidator();

            const result = validator.validate(values);

            return result.valid ? {} : result.errors;
        },
        onSubmit: (values, dispatch, { user, editProfile }) => {
            editProfile(user, values);
        }
    })
)(Form);