import axios from 'axios';
import Token from 'models/Token';
import User from 'models/User';
import { api } from 'config';
import { setupCache } from 'axios-cache-adapter'

axios.defaults.baseURL = api.url
axios.defaults.timeout = api.timeout
axios.defaults.headers.common['Accept'] = 'application/json';


const cache = setupCache({
    maxAge: 60 * 60 * 1000,
    exclude: {
        // for now cache only categories as they are rarely modified, if ever
        filter: req => !req.url.endsWith('api/categories')
    }
})

const serviceAxios = axios.create({
    baseURL: api.url,
    timeout: api.timeout,
    adapter: cache.adapter
})

serviceAxios.defaults.headers.common['Accept'] = 'application/json'
let xAuthToken = Token.createFromLocalStorage()

if(xAuthToken) {
    serviceAxios.defaults.headers.common['Authorization'] = `Bearer ${xAuthToken.access_token}`
}

serviceAxios.interceptors.response.use(response => response , error => {
    if (error && error.response && error.response.status === 401) {
        Token.saveToLocalStorage(null)
        User.saveToLocalStorage(null)
    
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }
    return Promise.reject(error)
});

export { serviceAxios }