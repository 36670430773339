import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import styles from "./ChartFooterIntervalStyles";
import Select from "components/Select/Select";
import { INTERVAL_OPTIONS } from "constants/selects";

const ChartFooterInterval = (props) => {
  const { classes, value, onChange } = props;

  return (
    <div className={classes.root}>
      <span>Items in/out per</span>
      <Select
        className={classes.input}
        itemClass={classes.item}
        value={value}
        items={INTERVAL_OPTIONS}
        onChange={onChange}
      />
    </div>
  );
};

ChartFooterInterval.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default withStyles(styles)(ChartFooterInterval);
