import React, { useCallback } from "react";
import ReactTable from "react-table";
import _ from "lodash";
import { compose } from "recompose";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Contacts from "@material-ui/icons/Contacts";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import withStyles from "material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import AddIcon from "@material-ui/icons/Add";
import GuardedComponent from "components/Authorization/GuardedComponent";

const UsersTable = ({
  classes,
  users,
  onClickDelete,
  history,
  settings,
  getUsers,
}) => {
  const {
    perPage: pageSize = 15,
    page,
    totalPages = 1,
    sorted = [],
    search = null,
  } = settings || {};
  const tableColumns = [
    {
      Header: "ID",
      accessor: "id",
      width: 45,
    },
    {
      Header: "First name",
      accessor: "firstName",
      width: 110,
    },
    {
      Header: "Last name",
      accessor: "lastName",
      width: 110,
    },
    {
      Header: "Role",
      accessor: "role",
      sortable: false,
      width: 70,
    },
    {
      Header: "Status",
      id: "enabled",
      accessor: (row) => (row?.enabled ? "Active" : "Disabled"),
      width: 70,
    },
    {
      Header: "Actions",
      headerStyle: {
        textAlign: "center",
      },
      sortable: false,
      Cell: (user) => (
        <div>
          <GuardedComponent scope={["users-edit"]}>
            <IconButton
              color="success"
              customClass={
                classes.actionButton + " " + classes.actionButtonRound
              }
              key="edit"
              onClick={() => history.push(`/users/edit/${user.original.id}`)}
            >
              <Edit className="Edit" />
            </IconButton>
          </GuardedComponent>
          <GuardedComponent scope={["users-delete"]}>
            <IconButton
              color="danger"
              customClass={
                classes.actionButton + " " + classes.actionButtonRound
              }
              key="delete"
              onClick={() => onClickDelete(user.original)}
            >
              <Close className="Close" />
            </IconButton>
          </GuardedComponent>
        </div>
      ),
      width: 160,
    },
  ];

  const handleFetchData = useCallback(
    async ({ page: nextPage, pageSize: nextPageSize, sorted: nextSorted }) => {
      const fetchDetails = { page, pageSize, sorted, search };
      const nextFetch = {
        page: nextPage,
        pageSize: nextPageSize,
        sorted: nextSorted,
        search,
      };
      if (!_.isEqual(fetchDetails, nextFetch)) {
        await getUsers({
          page: nextPage + 1,
          pageSize: nextPageSize,
          sorted: nextSorted,
          search,
        });
      }
    },
    [page, pageSize, sorted, search, getUsers]
  );

  return (
    <GridContainer>
      <ItemGrid xs={12}>
        <IconCard
          icon={Contacts}
          iconColor="rose"
          title="Users"
          content={
            <div>
              <Button
                color="rose"
                size="sm"
                style={{ position: "absolute", top: "2px", right: "20px" }}
                onClick={() => history.push("/users/create")}
              >
                <AddIcon />
                Add User
              </Button>

              <ReactTable
                data={users}
                columns={tableColumns}
                defaultPageSize={pageSize}
                showPageSizeOptions
                showPageJump={false}
                showPaginationTop={false}
                multiSort={false}
                manual
                page={page}
                pages={totalPages}
                pageSizeOptions={[5, 10, 15, 20, 25, 50]}
                defaultSorted={sorted}
                onFetchData={handleFetchData}
                showPaginationBottom
              />
            </div>
          }
        />
      </ItemGrid>
    </GridContainer>
  );
};

export default compose(withRouter, withStyles(extendedTablesStyle))(UsersTable);
