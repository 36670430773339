import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import ProfileCard from "components/Cards/ProfileCard.jsx";
import defaultAvatar from "assets/img/faces/default-user-icon.png";
import { compose } from "recompose";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";

const Card = (props) => {
  const { user } = props;

  return (
    <ItemGrid xs={12} sm={12} md={4}>
      <ProfileCard
        avatar={defaultAvatar}
        subtitle={user.role}
        title={`${user.firstName} ${user.lastName}`}
        description={user.email}
        content={
          <Button color="rose" round>
            Update Image
          </Button>
        }
      />
    </ItemGrid>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default compose(connect(mapStateToProps))(Card);
