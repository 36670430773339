import React from 'react';
import { compose } from 'recompose';
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import EditCustomerForm from './EditCustomerForm';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { BarLoader } from 'react-css-loaders';
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

import { NotificationManager } from 'react-notifications';

class EditCustomer extends React.Component {

    state = {
        alert: null,
        isLoadingCustomer: false,
        submitting: false,
        customer: null,
        image: null,
        resellerLicence: null
    }

    componentDidMount() {
        document.title = 'EPOS - Edit Customer'

        if (!this.state.isLoadingCustomer) {
            this.getCustomer();
        }
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(message) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title="Update Failed"
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    getImage = (id) => {
        axios.get(`/api/files/customer/${id}/photo.jpg`, { responseType: 'blob' })
        .then(response => {
            if (response.data.size>10) {
                var data_url = URL.createObjectURL(response.data);
                this.setState({ image: data_url });
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    getResellerLicence(id)  {
        axios.get(`/api/files/customer/${id}/reseller_licence`, { responseType: 'blob' })
        .then(response => {
            if (response.data.size>10) {
                var data_url = URL.createObjectURL(response.data);
                this.setState({ resellerLicence: data_url });
            }
        })
    }

    getCustomer = () => {
        const custId = this.props.match.params.id
        this.getImage(custId);
        this.getResellerLicence(custId);
        this.setState({isLoadingCustomer: true})
        axios.get(`/api/customers/${custId}`)
        .then(response => {
            this.setState({
                 customer: response.data.data,
                 isLoadingCustomer: false
            })
        })
        .catch((error) => {
            this.setState({ isLoadingCustomer: false })
            this.showAlert(extractErrorMessage(error))
        });
    }

    updateCustomer = async (json) => {
        this.setState({ submitting: true })

        const file = json.picture && json.picture.file
        delete json.picture

        const resellerLicence = json.resellerLicence && json.resellerLicence.file;
        delete json.resellerLicence;
        const customerId = this.props.match.params.id

        try {
            let response = await axios.put(`/api/customers/${customerId}`, json)
            if (file) {
                await axios.post(`/api/files/customer/${customerId}/photo.jpg`
                , file, {
                    headers: {
                    'Content-Type': file.type
                    }
                });
            }

            if (resellerLicence) {
                await axios.post(`/api/files/customer/${customerId}/reseller_licence`, resellerLicence, {
                    headers: {
                        'Content-Type': resellerLicence.type
                    }
                });
            }
            
            NotificationManager.success("Customer Modified", "Success")
            this.setState({
                customer: response.data.data,
                submitting: false
           })
        } catch (error) {
            this.setState({ submitting: false })
            this.showAlert(extractErrorMessage(error))
        }
    }

    render() {
        const { customer, image, submitting, alert, isLoadingCustomer, resellerLicence } = this.state;
        return (
            <div>
                { alert }
                { (isLoadingCustomer || _.isNull(customer) ) ? 
                    <BarLoader color="#d81b60" size={ 8 } />
                :
                    <EditCustomerForm 
                        resellerLicence={resellerLicence} 
                        customer={customer} 
                        image={image}  
                        submitting={submitting} 
                        onSubmit={this.updateCustomer}  
                    />
                }
            </div>
        )
    }
}

export default compose(
    withRouter,
    withStyles(buttonStyle)
)(EditCustomer);