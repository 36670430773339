import React, { Component } from "react";
import { compose } from "recompose";
import Table from "./ProductsTable";
import TotalsTable from "./TotalsTable";
import withConnected from "utilities/withConnected";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import { reduxForm, Field } from "redux-form";
import {
  Button,
  Menu,
  IconButton,
  MenuItem,
  Icon,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Snackbar,
} from "material-ui";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReportIcon from "@material-ui/icons/LibraryBooks";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import CategorySelect from "views/Products/CategorySelect";
import Close from "@material-ui/icons/Close";
import SelectStatus from "./SelectStatus";
import update from "immutability-helper";
import CButton from "components/CustomButtons/Button.jsx";

const HamMenu = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const FilterContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const InputWrapper = styled.div`
  .input-field {
    width: 90%;
  }
`;

const DatesWrapper = styled.div`
  margin-top: 41px;
  margin-left: 20px;
  .react-datepicker-wrapper {
    margin-left: 40px;
  }
  input {
    font-size: 14px;
  }
  .material-icons {
    position: absolute;
  }
`;

const DatePick = styled(DatePicker)`
  background: none;
  border: none;
  border-bottom: 1px solid gray;
`;

class Reports extends Component {
  state = {
    anchorEl: false,
    values: null,
    option: null,
    emailModal: false,
    email: null,
  };

  componentWillMount() {
    const { rdx, match } = this.props;
    rdx.action.getReport(match.params.id);
    rdx.action.loadClients({enabled: 1});
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: false });
  };

  onReset = (field) => {
    const { rdx } = this.props;
    const query = rdx.state.reports.detail.query;
    let updated;
    if (query[field] !== undefined) {
      updated = update(this.state.values.query, {
        [field]: { $set: query[field] },
      });
    } else {
      delete this.state.values.query[field];
      updated = this.state.values.query;
    }

    this.setState({ values: { query: updated } });
    rdx.action.loadProducts(updated);
  };

  onChange = (props) => {
    const { rdx } = this.props;
    const value = props[1];
    const field = props[3];
    const query = update(this.state.values.query, { [field]: { $set: value } });
    this.setState({ values: { query } });
    rdx.action.loadProducts(query);
  };

  filters = () => {
    const { rdx } = this.props;
    const { form } = rdx.state;
    if (this.state.values === null) {
      this.setState({ values: rdx.state.reports.detail });
      return null;
    }
    const values = this.state.values.query;
    const startDate = values?.start_date ? new Date(values.start_date) : null;
    const endDate = values?.end_date ? new Date(values.end_date) : null;

    return (
      <FilterContainer>
        <form>
          <GridContainer>
            <ItemGrid xs={6}>
              <DatesWrapper>
                <GridContainer>
                  <ItemGrid className="end_date" xs={6} sm={6} md={6}>
                    <Icon color="primary">date_range</Icon>
                    <Field
                      name="start_date"
                      component={() => {
                        return (
                          <DatePick
                            placeholderText="Start date"
                            selected={startDate}
                            dateFormat="MMMM d, yyyy"
                            onChange={(date) => {
                              this.onChange([null, date, null, "start_date"]);
                            }}
                          />
                        );
                      }}
                    />
                  </ItemGrid>
                  <ItemGrid className="start_date" xs={6} sm={6} md={6}>
                    <Icon color="primary">date_range</Icon>
                    <Field
                      name="end_date"
                      component={() => {
                        return (
                          <DatePick
                            placeholderText="End date"
                            onChange={(date) =>
                              this.onChange([null, date, null, "end_date"])
                            }
                            dateFormat="MMMM d, yyyy"
                            selected={endDate}
                          />
                        );
                      }}
                    />
                  </ItemGrid>
                </GridContainer>
              </DatesWrapper>
            </ItemGrid>
            <ItemGrid xs={6}>
              <InputWrapper>
                <Field
                  component={CategorySelect}
                  name="category_id"
                  value={values?.category_id}
                  onChange={(...props) => this.onChange(props)}
                  classes={{ selectFormControl: "input-field" }}
                />
                {values?.category_id ? (
                  <IconButton onClick={() => this.onReset("category_id")}>
                    <Close className="Close" />
                  </IconButton>
                ) : null}
              </InputWrapper>
              <InputWrapper>
                <Field
                  component={SelectStatus}
                  name="status"
                  multiple
                  value={values?.status}
                  onChange={(...props) => this.onChange(props)}
                  classes={{ selectFormControl: "input-field" }}
                />
                {values?.status ? (
                  <IconButton onClick={() => this.onReset("status")}>
                    <Close className="Close" />
                  </IconButton>
                ) : null}
              </InputWrapper>
            </ItemGrid>
          </GridContainer>
        </form>
      </FilterContainer>
    );
  };

  exportClick = (option) => {
    const { rdx } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.handleClose();
    if (option === "Email CSV" || option === "Email PDF") {
      this.setState({ option, emailModal: true });
    }
    if (option === "Export PDF") {
      rdx.action.exportFile("pdf", id);
    } else if (option === "Export CSV") {
      rdx.action.exportFile("csv", id);
    } else if (option === "Edit") {
      window.location.href = `/reports/edit/${id}`;
    }
  };

  onSend = () => {
    const { action } = this.props.rdx;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    let func = [];
    func["Email CSV"] = () => {
      action.exportFileEmail("csv", id, { emails: [this.state.email] });
    };
    func["Email PDF"] = () => {
      action.exportFileEmail("pdf", id, { emails: [this.state.email] });
    };

    func[this.state.option]();
    this.setState({ emailModal: false, email: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { rdx } = this.props;
    const { reports } = rdx.state;

    if (reports.detail === null) {
      return null;
    }
    const { processingFile } = reports;

    const openSnack = processingFile !== null;

    let snackMessage = null;
    if (processingFile === "processing") {
      snackMessage = "Generating File ... ";
    } else if (processingFile === "done") {
      snackMessage = "Done!";
    }

    return (
      <div>
        <Dialog open={this.state.emailModal}>
          <DialogTitle>{this.state.option}</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="Email"
              fullWidth
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ emailModal: false, mail: null })}
            >
              Cancel
            </Button>
            <CButton onClick={this.onSend} color="rose">
              Send
            </CButton>
          </DialogActions>
        </Dialog>
        {this.filters()}
        <ItemGrid xs={12} sm={12} md={12}>
          <IconCard
            icon={ReportIcon}
            iconColor="purple"
            title={`Report Detail - ${reports.detail.name}`}
            content={
              <div>
                <HamMenu>
                  <IconButton
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                  >
                    {[
                      "Edit",
                      "Email CSV",
                      "Email PDF",
                      "Export CSV",
                      "Export PDF",
                    ].map((option) => (
                      <MenuItem onClick={() => this.exportClick(option)}>
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </HamMenu>
                <Table
                  products={reports.products}
                  settings={{
                    perPage: reports.tableSettings?.per_page || 10,
                    page: reports.tableSettings?.current_page - 1 || 0,
                    totalPages: reports.tableSettings?.total_pages || 1,
                    sorted: reports.tableSettings?.sorted || [],
                    search: reports.tableSettings?.values || null,
                  }}
                />
                <TotalsTable products={reports.products} />
              </div>
            }
          />
        </ItemGrid>
        <Snackbar
          open={openSnack}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={snackMessage}
        />
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: "detail_filter",
    initialValues: {
      detail_filter: { values: {} },
    },
  })
)(withConnected(Reports));
