export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const TOGGLE_CHECKOUT = 'TOGGLE_CHECKOUT';
export const PURCHASE_COMPLETED = 'PURCHASE_COMPLETED';

export const LOADING_REPORT = 'LOADING_REPORT';
export const LOADED_REPORT = 'LOADED_REPORT';
export const LOAD_CLIENTS = 'LOAD_CLIENTS';
export const LOADED_PRODUCTS = 'LOADED_PRODUCTS';
export const LOAD_SQUARE_TAXES = 'LOAD_SQUARE_TAXES';
export const LOAD_SQUARE_LOCATIONS = 'LOAD_SQUARE_LOCATIONS';
export const LOADED_REPORT_DETAIL = 'LOADED_REPORT_DETAIL';
export const LOADING_PRODUCTS = 'LOADING_PRODUCTS';
export const DELETED_REPORT = 'DELETED_REPORT';
export const LOAD_REPORTS = 'LOAD_REPORTS';

export const EXPORTING_FILE = 'EXPORTING_FILE';
export const EXPORTED_FILE  = 'EXPORTED_FILE'
export const CLOSE_EXPORTING_FILE = 'CLOSE_EXPORTING_FILE';
export const TOGGLE_CHECK_REPORT = 'TOGGLE_CHECK_REPORT';
export const REQUEST_ARCHIVE_REPORTS = 'REQUEST_ARCHIVE_REPORTS'
export const RECEIVE_ARCHIVE_REPORTS = 'RECEIVE_ARCHIVE_REPORTS'