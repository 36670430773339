import { handleActions } from "redux-actions";
import _ from "lodash";
import {
  LOADING_REPORT,
  LOADING_PRODUCTS,
  LOADED_REPORT_DETAIL,
  LOADED_REPORT,
  LOAD_CLIENTS,
  LOADED_PRODUCTS,
  EXPORTING_FILE,
  EXPORTED_FILE,
  CLOSE_EXPORTING_FILE,
  TOGGLE_CHECK_REPORT,
  LOAD_SQUARE_TAXES,
  LOAD_SQUARE_LOCATIONS,
} from "../types";
import update from "immutability-helper";

const INITIAL_REPORTS_STATE = {
  products: [],
  clients: [],
  squareTaxes: [],
  squareLocations: [],
  list: [],
  detail: null,
  loading: false,
  processingFile: null,
  tableSettings: {},
};

export default handleActions(
  {
    [LOADED_REPORT]: (state, action) => {
      return update(state, {
        loading: { $set: false },
        list: { $set: action.payload.data },
        meta: { $set: action.payload.meta },
      });
    },
    [LOADING_REPORT]: (state, action) => {
      return update(state, { loading: { $set: true } });
    },
    [LOAD_CLIENTS]: (state, action) => {
      return update(state, { clients: { $set: action.payload.data } });
    },
    [LOADED_PRODUCTS]: (state, action) => {
      return update(state, {
        loading: { $set: false },
        products: { $set: action.payload.data },
        tableSettings: {
          $set: { ...action.payload.meta.pagination, values: action.values },
        },
      });
    },
    [LOAD_SQUARE_TAXES]: (state, action) => {
      return update(state, {
        loading: { $set: false },
        squareTaxes: { $set: action.payload.data },
      });
    },
    [LOAD_SQUARE_LOCATIONS]: (state, action) => {
      return update(state, {
        loading: { $set: false },
        squareLocations: { $set: action.payload.data },
      });
    },
    [LOADED_REPORT_DETAIL]: (state, action) => {
      return update(state, { detail: { $set: action.payload.data } });
    },
    [LOADING_PRODUCTS]: (state) => {
      return update(state, { loading: { $set: true } });
    },
    [EXPORTING_FILE]: (state) => {
      return update(state, { processingFile: { $set: "processing" } });
    },
    [EXPORTED_FILE]: (state) => {
      return update(state, { processingFile: { $set: "done" } });
    },
    [CLOSE_EXPORTING_FILE]: (state) => {
      return update(state, { processingFile: { $set: null } });
    },
    [TOGGLE_CHECK_REPORT]: (state, action) => {
      const i = _.findIndex(state.list, (item) => item.id === action.reportId);
      return update(state, {
        list: { [i]: { $toggle: ["checked"] } },
      });
    },
  },
  INITIAL_REPORTS_STATE
);
