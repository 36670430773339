import { find } from 'lodash';

function getCategoryPath(categoryId, categories) {
    if (!categoryId) {
        return ['Uncategorized']
    }

    let path = [];
    while (categoryId !== 0) {
        let cat = find(categories, {id: categoryId})
        path.unshift(cat.name)
        categoryId = cat.parent_id
    }

    return path;
}

export { getCategoryPath };
