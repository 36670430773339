import React from 'react';
import { compose } from 'recompose';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import withStyles from "material-ui/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { Field, reduxForm } from 'redux-form';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload';
import GuardedComponent from 'components/Authorization/GuardedComponent';

const CreateCategoryForm = (props) => {

    const {
        handleSubmit,
        submitting
    } = props;

    return (
        <GridContainer>
            <ItemGrid xs={12} sm={12} md={12}>
                <IconCard
                    icon={WidgetsIcon}
                    iconColor="rose"
                    title="Create Category"
                    content={
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6} style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                    <div style={{ margin: "auto" }}>
                                        <Field 
                                            component={ ImageUpload }
                                            name = "picture" />
                                    </div>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={CustomInput}
                                        name='name'
                                        labelText="Name"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>

                            <GuardedComponent scope={['categories-create']}>
                                <Button color="rose" right type="submit" disabled={submitting}>
                                    Create
                                </Button>
                            </GuardedComponent>
                        </form>
                    }
                />
            </ItemGrid>
        </GridContainer>
    );
};

export default compose(
    withStyles(regularFormsStyle),
    reduxForm({
        form: 'create_category'
    })
)(CreateCategoryForm);