import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom"
import { serviceAxios as axios } from '../../bootstrap';
import ReactToPrint from "react-to-print";
import { Button, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField } from "material-ui";
import extractErrorMessage from "../../utilities/HttpErrorResponse";
import ClientBarcodes from "./ClientBarcodes";


const MAX_BARCODES = 10000;

class BarcodesPrinter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      printEnabled: false,
      barcodesToGenerate: 0,
      barcodesQuantity: '',
      isConfirmationLoading: false,
      isConfirmationOpen: false,
      startNumber: 1
    };
    this.close = this.close.bind(this);
    this.handleBarcodesQuantityChange = this.handleBarcodesQuantityChange.bind(this);
    this.getLatestBarcode = this.getLatestBarcode.bind(this);
    this.saveLatestBarcode = this.saveLatestBarcode.bind(this);
    this.printBarcodes = this.printBarcodes.bind(this);
    this.onConfirmationClose = this.onConfirmationClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.client && (!prevProps.client || prevProps.client.id !== this.props.client.id)) {
      this.setState({ printEnabled: false }, () => this.getLatestBarcode());
    }
  }

  getLatestBarcode() {
    const { id } = this.props.client;
    axios.get(`/api/clients/${id}/barcodes/last`)
      .then(response => {
        this.setState({
          startNumber: (response.data.barcodeNumber + 1) || 1,
          printEnabled: true
        })
      })
      .catch((error) => {
        console.error("Retrieving Latest Generated Barcode Failed", extractErrorMessage(error))
      });
  }

  saveLatestBarcode() {
    const { id } = this.props.client;
    const { barcodesToGenerate, startNumber } = this.state;
    axios.post(`/api/clients/${id}/barcodes`, { barcodeNumber: (startNumber + barcodesToGenerate - 1) })
      .catch((error) => {
        console.error("Retrieving Latest Generated Barcode Failed", extractErrorMessage(error))
      })
      .then(response => {
        this.setState({ loading: true })
      });
  }

  handleBarcodesQuantityChange(e) {
    const { value } = e.target;
    this.setState({ barcodesQuantity: value ? Math.max(Math.min(Math.floor(value), MAX_BARCODES), 0) : '' });
  }

  printBarcodes() {
    const { barcodesQuantity } = this.state;
    // Sequential update to handle scenarios where a loader icon is required
    this.setState({ isConfirmationLoading: true },
      () => setTimeout(() => this.setState({ barcodesToGenerate: barcodesQuantity },
        // Timeout to display loading icon while the barcodes canvases are being generated
        () => setTimeout(() =>this.setState({ isConfirmationOpen: true }), 1000)
      ), 500)
    );
  }

  onConfirmationClose() {
    this.setState({ isConfirmationOpen: false, isConfirmationLoading: false });
  }

  close() {
    this.props.onClose();
    this.setState({ loading: false, isConfirmationOpen: false, isConfirmationLoading:false, 
      printEnabled: false, barcodesQuantity: '', barcodesToGenerate: 0 })
  }

  render() {
    const { client } = this.props;
    const { isConfirmationOpen, isConfirmationLoading, printEnabled, loading, barcodesQuantity, startNumber, barcodesToGenerate } = this.state;
    if (!client) {
      return null;
    }

    const button = (
      <Button color="primary" disabled={loading}>
        Continue
        {loading && (
          <CircularProgress style={{ marginLeft: 10 }} size={20} />
        )}
      </Button>
    );

    const barcodeHandlerDialog = (
      <Dialog open={!isConfirmationOpen}>
        <DialogTitle id="form-dialog-title">Barcode Generator</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the total number of individual barcodes that you would like to generate:
          </DialogContentText>
          <TextField
            autoFocus
            value={barcodesQuantity}
            onChange={this.handleBarcodesQuantityChange}
            margin="dense"
            type="number"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} > Close </Button>
          <Button onClick={this.printBarcodes} color="primary" disabled={!printEnabled || isConfirmationOpen || loading || (!barcodesQuantity || barcodesQuantity <= 0)}>
            Print Barcodes
              {isConfirmationLoading && (
              <CircularProgress style={{ marginLeft: 10 }} size={20} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )

    const confirmationDialog = (
      <Dialog open={isConfirmationOpen}>
        <DialogTitle id="form-dialog-title">Barcode Generator</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Barcodes with unique SKUs are about to be generated and cannot be recovered afterwards. Do you want to proceed?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onConfirmationClose} > Cancel </Button>
          <ReactToPrint
            pageStyle="@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; margin:0; padding:0 } }"
            copyStyles={false}
            key={client.id}
            trigger={() => button}
            content={() => this.componentRef}
            onBeforePrint={this.saveLatestBarcode}
            onAfterPrint={this.close}
          />
        </DialogActions>
      </Dialog>
    );

    return (
      <Fragment>
        { barcodeHandlerDialog}
        { confirmationDialog}

        <div style={{ display: "none" }}>
          <ClientBarcodes
            clientCode={client.code}
            clientId={client.id}
            startNumber={startNumber}
            barcodesQuantity={barcodesToGenerate}
            ref={el => (this.componentRef = el)}
          />
        </div>

      </Fragment>

    );
  }
}
export default withRouter(BarcodesPrinter);