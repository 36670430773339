import React, { Component } from 'react';

class FileUpload extends Component {

    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.state = {
            fileUrl: null
        }
    }

    onChange(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            fileUrl: reader.result,
            removed: false
          });
          this.props.input.onChange({ file })
        };
        reader.readAsDataURL(file);
    }
    
    render() {
        return (
            <div>
                <input onChange={this.onChange} type="file" />
            </div>
        );
    }
}

export default FileUpload;
