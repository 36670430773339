import React from "react";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Grid from "material-ui/Grid";

const style = (theme) => ({
  grid: {
    margin: "0 -7px",
    [theme.breakpoints.up("sm")]: {
      margin: "0 -15px",
    },
  },
});

function GridContainer({ ...props }) {
  const { classes, children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
