import DashboardIcon from "@material-ui/icons/Dashboard";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ContactsIcon from "@material-ui/icons/Contacts";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import RepeatIcon from '@material-ui/icons/Repeat';

export const MAX_CHART_ITEMS = 8;

export const PIE_OPTIONS = {
  height: "230px",
};

export const BAR_OPTIONS = {
  seriesBarDistance: 10,
  axisX: {
    showGrid: false,
  },
  height: "300px",
};

export const CHART_COLORS = [
  "info",
  "danger",
  "warning",
  "primary",
  "success",
  "rose",
  "gray",
  "default",
];

export const pieSummaryCharts = [
  {
    id: 'category',
    title: "Products by Category",
    icon: DashboardIcon,
    data: {
      labels: ["62%", "32%"],
      series: [62, 32],
    },
    items: ["Art", "Furniture", "Others"],
  },
  {
    id: 'status',
    title: "Products by Status",
    icon: RepeatIcon,
    iconColor: "green",
    data: {
      labels: ["60%", "40%"],
      series: [60, 40],
    },
    items: ["Solded", "In stock"],
  },
  {
    id: 'price',
    title: "Products by Price",
    icon: MonetizationOnIcon,
    iconColor: "purple",
    data: {
      labels: ["20%", "40%", "40"],
      series: [20, 40, 40],
    },
    items: ["100-200$", "10-15$", "0-10$"],
  },
  {
    id: 'consignor',
    title: "Products by Consignor",
    icon: ContactsIcon,
    iconColor: "orange",
    data: {
      labels: ["50%", "50%"],
      series: [50, 50],
    },
    items: ["436", "124"],
  },
];

export const barSummaryCharts = [
  {
    id: 'interval',
    title: "Operations by Period",
    icon: QueryBuilderIcon,
    iconColor: "rose",
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [
        [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
        [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695],
      ],
    },
  },
];
