import React from 'react';
import { compose } from 'recompose';
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import EditClientForm from './EditClientForm';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { BarLoader } from 'react-css-loaders';
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import { NotificationManager} from 'react-notifications';

class EditClient extends React.Component {

    state = {
        alert: null,
        isLoadingClient: false,
        submitting: false,
        client: null
    }

    componentDidMount() {
        document.title = 'EPOS - Edit Consignor'

        if(!this.state.isLoadingClient) {
            this.getClient();
        }
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(message) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title="Update Failed"
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    getClient = (id) => {
        this.setState({isLoadingClient: true})
        axios.get(`/api/clients/${this.props.match.params.id}`)
        .then(response => {
            this.setState({
                 client: response.data.data,
                 isLoadingClient: false
            })
        })
        .catch((error) => {
            this.setState({ isLoadingClient: false })
            this.showAlert(extractErrorMessage(error))
        });
    }

    updateClient = (json) => {
        this.setState({ submitting: true })
        axios.put(`/api/clients/${this.props.match.params.id}`, json)
        .then(response => {
            NotificationManager.success("Consignor Modified", "Success")
            this.setState({
                 client: response.data.data,
                 submitting: false
            })
        })
        .catch((error) => {
            this.setState({ submitting: false })
            this.showAlert(extractErrorMessage(error))
        });
    }

    render() {
        return (
            <div>
                { this.state.alert }
                { (this.state.isLoadingClient || _.isNull(this.state.client) ) ? 
                    <BarLoader color="#d81b60" size={ 8 } />
                :
                    <EditClientForm client={this.state.client} submitting={this.state.submitting} onSubmit={this.updateClient}  />
                }
            </div>
        )
    }
}

export default compose(
    withRouter,
    withStyles(buttonStyle)
)(EditClient);