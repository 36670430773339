import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";

const ProductChangelog = ({ productChangelog }) => {
  return (
    <ItemGrid xs={12} sm={12} md={12}>
      <div>
        <h4>Product Changelog</h4>
        {productChangelog.map((changelogRow, i) => {
          const {
            user,
            field,
            old_value: oldValue,
            new_value: newValue,
            action,
            created_at: createdAt,
            updated_at: updatedAt,
          } = changelogRow;

          const primaryNode = (
            <p>
              <span style={{ fontWeight: "bold" }}>
                {`${user.firstName} ${user.lastName} `}
              </span>
              {action === "creation" ? (
                "created the product "
              ) : (
                <>
                  updated the{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    {field}{" "}
                  </span>{" "}
                </>
              )}
              at{" "}
              {new Date(
                action === "creation"
                  ? createdAt.replace(/-/g, "/")
                  : updatedAt.replace(/-/g, "/")
              ).toLocaleString("en-US")}
            </p>
          );

          const secondaryNode = action === "update" && (
            <div>
              <span>{oldValue}</span>
              <span style={{ fontWeight: "bold", margin: "0px 30px" }}>
                {"=>"}
              </span>
              <span>{newValue}</span>
            </div>
          );
          return (
            <React.Fragment key={i}>
              <div>
                <p>{primaryNode}</p>
                <p>{secondaryNode}</p>
                <hr />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </ItemGrid>
  );
};

export default ProductChangelog;
