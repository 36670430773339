import React from 'react'
import _ from 'lodash';
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";

import withStyles from "material-ui/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

export const codes = {
    1: 'art',
    2: 'coins',
    3: 'fashion/jewelry',
    4: 'furniture',
    5: 'household/collectibles',
    6: 'media',
    7: 'silver'
}


function shortCodes(props) {
        const {
            input: {
                value,
                onChange
            },
            classes,
            disabled
        } = props;

        return (
            <FormControl
            fullWidth
            className={classes.selectFormControl}>
                <InputLabel
                    className={classes.selectLabel}
                    htmlFor="simple-select">
                    Short Code
                </InputLabel>
                <Select
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                    }}
                >
                    <MenuItem disabled classes={{
                        root: classes.selectMenuItem
                        }}
                    >
                        Choose a short code
                    </MenuItem>
                    {
                        Object.keys(codes).map((k) => {
                            return (
                                <MenuItem value={k} key={k} classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                }}>
                                    {k} - {_.capitalize(codes[k])}
                                </MenuItem>
                            )
                        })
                    }

                </Select>
            </FormControl>
        )
}

export default withStyles(extendedFormsStyle)(shortCodes)
