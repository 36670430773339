// ##############################
// // // LoginPage view styles
// #############################

import { container } from "assets/jss/material-dashboard-pro-react.jsx";

import {
    primaryColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = {
  content: {
    paddingTop: "18vh",
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4"
  },
  container,
  customButtonClass: {
    "&,&:focus,&:hover":{
      color: "#FFFFFF",
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  checkedIcon: {
      width: "20px",
      height: "20px",
      border: "1px solid rgba(0, 0, 0, .54)",
      borderRadius: "3px"
  },
  uncheckedIcon: {
      width: "0px",
      height: "0px",
      padding: "9px",
      border: "1px solid rgba(0, 0, 0, .54)",
      borderRadius: "3px"
  },
  checked: {
      color: primaryColor
  },
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "rgba(0, 0, 0, 0.26)",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex",
        transition: "0.3s ease all"
    },
};

export default loginPageStyle;
