import Fingerprint from "@material-ui/icons/Fingerprint";
import ForgotPage from "../../views/Pages/ForgotPage.jsx";

const forgot = {
    path: "/recover/forgot",
    name: "Forgot Password",
    short: "Forgot",
    mini: "F",
    icon: Fingerprint,
    component: ForgotPage
};

export default forgot;