import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";

const filterRulesACL = (userAcl, scope) => {
  return [...scope, '*'].some(innerRule => (userAcl || []).includes(innerRule))
}

function GuardedComponent({ ...props }) {
  const {
    children,
    scope,
    acl: userAcl,
    ...rest
  } = props;
  const grant = filterRulesACL(userAcl, scope);
  return (
    <>
      {grant ? children : null}
    </>
  );
}

GuardedComponent.propTypes = {
  scope: PropTypes.arrayOf(PropTypes.string).isRequired,
  acl: PropTypes.arrayOf(PropTypes.string)
};

const mapStateToProps = (state) => {
  return {
    acl: state.auth.acl,
  };
};

export default compose(connect(mapStateToProps))(GuardedComponent);
