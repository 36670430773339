import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import PermIdentity from "@material-ui/icons/PermIdentity";
import { Field, reduxForm } from 'redux-form';
import PictureUpload from 'components/CustomUpload/PictureUpload.jsx';
import FileUpload from 'components/CustomUpload/FileUpload';
import IsEnabledSwitch from "../Components/IsEnabledSwitch";
import styled from 'styled-components';
import GuardedComponent from "components/Authorization/GuardedComponent";
import { compose, withProps } from "recompose";

const ResellerLicenseNumber = styled(ItemGrid)`
    div {
        width: 70%;
    }
    
    .verify-link {
        border: 2px solid #e91e63;
        padding: 8px;
        border-radius: 4px;
    }
`;

const CreateCustomerForm = (props) => {

    const {
        handleSubmit,
        submitting,
    } = props;

    return (
        <ItemGrid xs={12} sm={12} md={12}>
            <IconCard
                icon={PermIdentity}
                iconColor="rose"
                title='Create Customer'
                content={
                    <div>
                        <form onSubmit={ handleSubmit }>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                        <Field 
                                            component={ PictureUpload }
                                            name = "picture" />
                                </ItemGrid>                                
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name= "firstName"
                                                type= "text"
                                                labelText= "First Name"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name="lastName"
                                                type="text"
                                                labelText="Last Name"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name="email"
                                                type="text"
                                                labelText="Email"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} />
                                        </ItemGrid>                                        
                                    </GridContainer>
                                </ItemGrid>                                
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput }
                                        name= "phone"
                                        type= "text"
                                        labelText= "Phone"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "company"
                                        type= "text"
                                        labelText= 'Company'
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <Field component={ CustomInput } 
                                        name= "street"
                                        type= "text"
                                        labelText= "Address"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={ CustomInput } 
                                        name= "city"
                                        type= "text"
                                        labelText= "City"
                                        formControlProps= {{
                                            fullWidth: true
                                        }}/>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={ CustomInput }
                                        name= "state"
                                        type= "text"
                                        labelText= "State"
                                        formControlProps= {{
                                            fullWidth: true
                                        }}/>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <Field component={ CustomInput }
                                         name= "zip"
                                         type= "text"
                                         labelText= "Zip"
                                         formControlProps= {{
                                             fullWidth: true
                                         }}/>
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={8}>
                                    Copy of Resellers Licence
                                    <Field 
                                        component={ FileUpload }
                                        name = "resellerLicence" 
                                    />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={4}>
                                    <GridContainer>
                                         <ResellerLicenseNumber xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name= "reseller_number"
                                                type= "text"
                                                labelText= "Reseller License Number"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} 
                                            />
                                            <a href='https://services.cdtfa.ca.gov/webservices/verification.jsp?action=SALES' target='blank' className='verify-link'>
                                                Verify
                                            </a>
                                        </ResellerLicenseNumber>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <p>Status</p>
                                            <Field
                                                component = { IsEnabledSwitch }
                                                name = "enabled" />
                                        </ItemGrid>                                     
                                    </GridContainer>
                                </ItemGrid>                                
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <Field component={ CustomInput } 
                                        name= "notes"
                                        type= "text"
                                        labelText= "Notes"
                                        inputProps={{
                                            multiline: true,
                                            rowsMax:4,
                                            rows:4
                                        }}
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <h4>Additional Information</h4>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "contact_info_name"
                                        type= "text"
                                        labelText= "Additional User Name"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "contact_info_relationship"
                                        type= "text"
                                        labelText= "Relationship"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput }
                                        name= "contact_info_phone"
                                        type= "text"
                                        labelText= "Additional Phone Number"
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <Field component={ CustomInput } 
                                        name= "contact_info_email"
                                        type= "text"
                                        labelText= 'Additional Email'
                                        formControlProps= {{
                                            fullWidth: true
                                        }} />
                                </ItemGrid>
                            </GridContainer>
                            <GuardedComponent scope={['customers-create']}>
                                <Button color="rose" right type="submit" disabled={ submitting }>
                                    Create
                                </Button>
                            </GuardedComponent>
                            <Clearfix />
                        </form>
                    </div>
                }
            />
        </ItemGrid>
    )
};

export default compose(
  withProps(() => {
    return {
        initialValues: {
          enabled: true
        }
      }
  }),
  reduxForm({
    form: 'create_customer'
  })
)(CreateCustomerForm);