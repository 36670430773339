import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const ChartFooterItemStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: 6,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  primary: {
    color: primaryColor,
  },
  warning: {
    color: warningColor,
  },
  danger: {
    color: dangerColor,
  },
  success: {
    color: successColor,
  },
  info: {
    color: infoColor,
  },
  rose: {
    color: roseColor,
  },
  gray: {
    color: grayColor,
  },
  default: {
    color: "darkgray",
  },
};

export default ChartFooterItemStyles;
