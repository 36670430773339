import React from 'react'

import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";

import withStyles from "material-ui/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { serviceAxios as axios } from '../../bootstrap';
import {getCategoryPath} from '../../utilities/helpers/category'
import CategoryDialog from './CategoryDialog';

class CategorySelect extends React.Component {

    state = {
        categoryId: null,
        categories: null,
        fullCategoryName: '',
        showDialog: false,
        selectOpen: false
    }

    componentWillMount() {
        const categoryId = this.props.input.value
        this.setState({
            categoryId,
            fullCategoryName: "---"
        })
        axios.get(`/api/categories`).then(response => {
            const categories = response.data.data
            this.setState({
                categories,
                categoryId,
                fullCategoryName: getCategoryPath(categoryId, categories || []).join(' / ')
            })
        })
    }

    showModal = (e) => {
        this.setState({
            showDialog: true
        })
    }

    onClose = () => {
        this.setState({
            showDialog: false,
            selectOpen: false
        });
    }

    onChange = (categoryId) => {
        if (categoryId) {
            this.setState({
                categoryId,
                fullCategoryName: getCategoryPath(categoryId, this.state.categories || []).join(' / '),
                showDialog: false,
                selectOpen: false
            })
            this.props.input.onChange(categoryId)
        }
    }

    render() {
        const {
            input: {
                onChange,
                value
            },
            classes,
            disabled,
            meta
        } = this.props;

        let labelClass = classes.selectLabel
        let selectFormClass = classes.selectFormControl
        if(meta && meta.error && (meta.touched || meta.submitFailed) ) {
            labelClass =  classes.labelError
            selectFormClass = classes.selectFormControlError
        }

        return (
            <React.Fragment>
                <FormControl
                fullWidth
                className={selectFormClass}>
                    <InputLabel
                        className={labelClass}
                        htmlFor="simple-select">
                        CATEGORY
                    </InputLabel>
                    <Select
                        open={this.state.selectOpen}
                        onOpen={() => {}}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        onClick={() => !disabled && this.showModal()}
                        MenuProps={{
                            className: classes.selectMenu,
                        }}
                        classes={{
                            select: classes.select
                        }}
                        inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                        }}>
                        <MenuItem disabled classes={{
                            root: classes.selectMenuItem
                        }}>
                            Choose Category
                        </MenuItem>
                        <MenuItem value={this.state.categoryId} classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}>
                            { this.state.fullCategoryName }
                        </MenuItem>
                    </Select>
                </FormControl>
                { this.state.showDialog &&  (
                    <CategoryDialog onChange={this.onChange} onClose={this.onClose}/>
                )}
            </React.Fragment>

        )
    }
}

export default withStyles(extendedFormsStyle)(CategorySelect)