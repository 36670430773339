import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Hidden from '@material-ui/core/Hidden';

import WebcamUploadDialog from "./WebcamUploadDialog";

class ImageUpload extends React.Component {
  state = {
    renderURL: null,
    removed: false,
    showWebcamDialog: false,
  };

  fileElement = null;

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        renderURL: reader.result,
        removed: false,
      });
      console.log(file);
      this.props.input.onChange({ file });
    };
    reader.readAsDataURL(file);
  };

  handleClickImage = (evt) => {
    const elem = evt.target;
    try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleClick = () => {
    if (this.fileElement) this.fileElement.click();
  };

  handleRemove = () => {
    this.setState({
      renderURL: null,
      removed: true,
    });
    this.props.input.onChange({ file: -1 });
  };

  setFileInputRef = (element) => {
    this.fileElement = element;
  };

  onChangeWebcamUpload = (element) => {
    this.setState({
      renderURL: element,
      removed: false,
    });
    this.fileElement = element;

    const filename = `photo-${Date.now()}.jpg`;

    let arr = element.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const file = new File([u8arr], filename, { type: mime });

    console.log(file);

    this.props.input.onChange({ file });
  };

  showWebcamUploadDialog = (e) => {
    this.setState({
      showWebcamDialog: true,
    });
  };

  onCloseWebcamUploadDialog = () => {
    this.setState({
      showWebcamDialog: false,
    });
  };

  render() {
    const { imageURL, disabled, meta, required } = this.props;
    let imgActions = null;
    if (!disabled) {
      let errorStyle = {};

      if (meta && meta.error && (meta.touched || meta.submitFailed)) {
        errorStyle = { color: "red", fontWeight: "bold" };
      }

      imgActions = (
        <div>
          {required ? <div style={errorStyle}>Required *</div> : null}
          {this.state.renderURL === null &&
            (!imageURL || this.state.removed) ? (
            <>
              <Button round color="rose" onClick={() => this.handleClick()}>
                {this.props.avatar ? "Add Photo" : "Select image"}
              </Button>
              <Hidden smDown>
                <Button
                  round
                  color="rose"
                  size="sm"
                  onClick={() => this.showWebcamUploadDialog()}
                >
                  <CameraAltIcon />
                </Button>
              </Hidden>
            </>
          ) : (
            <span>
              <Button round color="rose" onClick={() => this.handleClick()}>
                Change
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button color="danger" round onClick={() => this.handleRemove()}>
                <i className="fa fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      );
    }

    return (
      <div className="fileinput text-center" style={{ width: "100%" }}>
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img
            src={
              this.state.renderURL ||
              (!this.state.removed && imageURL) ||
              (this.props.avatar ? defaultAvatar : defaultImage)
            }
            alt="..."
            onClick={this.handleClickImage}
            style={{ cursor: "pointer" }}
          />
        </div>
        {imgActions}
        <p style={{ visibility: "hidden" }}>
          {" "}
          <input
            onChange={this.handleImageChange}
            type="file"
            ref={this.setFileInputRef}
          />{" "}
        </p>

        {this.state.showWebcamDialog && (
          <WebcamUploadDialog
            onChange={this.onChangeWebcamUpload}
            onClose={this.onCloseWebcamUploadDialog}
          />
        )}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;
