import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import SelectUI from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import withStyles from "material-ui/styles/withStyles";

import styles from "./SelectStyles";

const Select = (props) => {
  const { className, itemClass, classes, meta = {}, fullWidth } = props;
  const { label = "", value = "", items = [] } = props;
  const { onChange, onUpdate } = props;

  const hasError = meta && meta.error && (meta.touched || meta.submitFailed);

  const rootClasses = cx(
    className,
    hasError ? classes.selectFormControlError : classes.selectFormControl
  );

  const labelClass = hasError ? classes.labelError : classes.selectLabel;

  return (
    <FormControl fullWidth={fullWidth} className={rootClasses}>
      {label && (
        <InputLabel className={labelClass} htmlFor="custom-select">
          {label}
        </InputLabel>
      )}
      <SelectUI
        value={value}
        onChange={onChange}
        onUpdate={onUpdate}
        disabled={props.disabled}
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: `${classes.select} select`,
        }}
        inputProps={{
          name: "customSelect",
          id: "custom-select",
        }}
      >
        {items.map(({ value, label }) => (
          <MenuItem
            key={value}
            className={itemClass}
            value={value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
          >
            {label}
          </MenuItem>
        ))}
      </SelectUI>
    </FormControl>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  itemClass: PropTypes.string,
  classes: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(Select);
