import {
    EDIT_PROFILE_START,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAILED,
} from './auth-reducer';
import { serviceAxios as axios } from '../../bootstrap';
import User from 'models/User';
import setError from 'redux/general/error-actioncreator';
import { NotificationManager} from 'react-notifications';


export default function editProfile(user, values) {
    return (dispatch) => {

        dispatch({ type: EDIT_PROFILE_START });

        const params = {
            ...values,
            role_id: user.role_id
        };

        axios.post(`/api/users/${user.id}`, params)
            .then(response => {
                NotificationManager.success("Profile succesfully updated", "Success")
                const user = User.createFromResponse(response.data.data);

                dispatch({type: EDIT_PROFILE_SUCCESS, payload: { user: user }});
            })
            .catch((error) => {
                NotificationManager.error("Profile update failed", "Error")
                dispatch({ type: EDIT_PROFILE_FAILED });

                const httpCode = error.response ? error.response.status : 0

                dispatch(setError({
                    title: 'errors.general',
                    httpCode: httpCode,
                    message: 'users.failed_to_update_profile'
                }));
            });
    }
};