import React, { useCallback, useState } from "react";
import _ from "lodash";
import ReactTable from "react-table";
import withConnected from "utilities/withConnected";
import IconButton from "components/CustomButtons/IconButton.jsx";

import InsertChart from "@material-ui/icons/InsertChart";
import Close from "@material-ui/icons/Close";
import GuardedComponent from "components/Authorization/GuardedComponent";

function getClientName(clients, id) {
  return _.find(clients, (c) => c.id === id);
}

const ReportsTable = ({ reports, clients, settings, rdx, isArchive }) => {
  const { action } = rdx;

  const {
    perPage: pageSize = 10,
    page,
    totalPages = 1,
    sorted = [],
    search = null,
  } = settings || {};
  const confirmDelete = (id) => {
    if (window.confirm("Delete this report?")) {
      rdx.action.deleteReport(id);
    }
  };

  const tableColumns = [
    {
      Cell: (item) => {
        const { id, checked } = item.original;

        if (isArchive) {
          return null;
        }

        return (
          <input
            type="checkbox"
            checked={checked}
            onChange={() => rdx.action.toggleCheckReport(id)}
          />
        );
      },
      width: 60,
      sortable: false,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 150,
    },
    {
      Header: "Client",
      Cell: (item) => {
        const { query } = item.original;
        if (clients && query?.client_id) {
          const client = getClientName(clients, query.client_id);
          return client ? client.code : null;
        }
        return "";
      },
      width: 180,
      sortable: false,
    },
    {
      Header: "Date range",
      Cell: (item) => {
        const { query } = item.original;
        if (!query?.start_date) {
          return "no range";
        }
        return `${query.start_date} - ${query.end_date}`;
      },
      width: 180,
      sortable: false,
    },
    {
      Header: "Status",
      Cell: (item) => {
        const status = item.original?.query?.status;
        if (!status) {
          return "All";
        } else if (typeof status !== "string") {
          return status.join(", ");
        } else {
          return status;
        }
      },
      width: 180,
      sortable: false,
    },
    {
      Header: "",
      Cell: (item) => {
        return (
          <div>
            <GuardedComponent scope={["reports-read"]}>
              <a
                style={{ marginRight: 5 }}
                href={`/reports/detail/${item.original.id}`}
              >
                <IconButton color="success" key="edit">
                  <InsertChart className="Edit" />
                </IconButton>
              </a>
            </GuardedComponent>
            <GuardedComponent scope={["reports-delete"]}>
              <IconButton
                onClick={() => confirmDelete(item.original.id)}
                color="danger"
                key="delete"
              >
                <Close className="Close" />
              </IconButton>
            </GuardedComponent>
          </div>
        );
      },
      width: 95,
      sortable: false,
    },
  ];

  return (
    <ReactTable
      data={reports}
      columns={tableColumns}
      defaultPageSize={pageSize}
      showPageSizeOptions
      showPageJump={false}
      showPaginationTop={false}
      multiSort={false}
      manual
      page={page}
      pages={totalPages}
      pageSizeOptions={[5, 10, 15, 20, 25, 50]}
      onFetchData={useCallback(
        async ({ page: nextPage, pageSize, sorted }) => {
          await action.loadReports(isArchive, nextPage + 1, pageSize, sorted);
        },
        [isArchive, action]
      )}
      showPaginationBottom
    />
  );
};

export default withConnected(ReportsTable);
