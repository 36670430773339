import React, { useCallback } from "react";
import ReactTable from "react-table";
import _ from "lodash";
import { compose } from "recompose";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Contacts from "@material-ui/icons/Contacts";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import withStyles from "material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import AddIcon from "@material-ui/icons/Add";
import GuardedComponent from "components/Authorization/GuardedComponent";

const ProductsTable = ({
  classes,
  products,
  onClickDelete,
  syncProducts,
  settings,
  getProducts,
  history,
}) => {
  const {
    perPage: pageSize = 15,
    page,
    totalPages = 1,
    sorted = [],
    search = null,
  } = settings || {};

  const tableColumns = [
    {
      Header: "#",
      accessor: "id",
      width: 50,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (item) => (
        <a
          onClick={() => history.push(`/products/edit/${item.original.id}`)}
          style={{ cursor: "pointer" }}
        >
          {item.original.name}
        </a>
      ),
      width: 180,
    },
    {
      Header: "SKU",
      accessor: "sku",
      width: 100,
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: (item) => (
        <div>
          <p>{item.row.price ? `$${item.row.price}` : "Not set"}</p>
        </div>
      ),
      width: 130,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 80,
    },
    {
      Header: "Consignor",
      accessor: "clientName",
      width: 140,
      sortable: false,
    },
    {
      Header: "Actions",
      headerStyle: {
        textAlign: "center",
      },
      sortable: false,
      Cell: (item) => (
        <div>
          <GuardedComponent scope={["products-edit"]}>
            <IconButton
              color="success"
              customClass={
                classes.actionButton + " " + classes.actionButtonRound
              }
              key="edit"
              onClick={() => history.push(`/products/edit/${item.original.id}`)}
            >
              <Edit className="Edit" />
            </IconButton>
          </GuardedComponent>
          <GuardedComponent scope={["products-delete"]}>
            <IconButton
              color="danger"
              customClass={
                classes.actionButton + " " + classes.actionButtonRound
              }
              key="delete"
              onClick={() => onClickDelete(item.original)}
            >
              <Close className="Close" />
            </IconButton>
          </GuardedComponent>
        </div>
      ),
      maxWidth: 90,
    },
  ];

  const handleFetchData = useCallback(
    async ({ page: nextPage, pageSize: nextPageSize, sorted: nextSorted }) => {
      const fetchDetails = { page, pageSize, sorted, search };
      const nextFetch = {
        page: nextPage,
        pageSize: nextPageSize,
        sorted: nextSorted,
        search,
      };
      if (!_.isEqual(fetchDetails, nextFetch)) {
        await getProducts({
          page: nextPage + 1,
          pageSize: nextPageSize,
          sorted: nextSorted,
          search,
        });
      }
    },
    [page, pageSize, sorted, search, getProducts]
  );

  return (
    <GridContainer>
      <ItemGrid xs={12}>
        <IconCard
          icon={Contacts}
          iconColor="rose"
          title="Products"
          content={
            <div>
              <GuardedComponent scope={["products-create"]}>
                <Button
                  color="rose"
                  size="sm"
                  style={{ position: "absolute", top: "2px", right: "20px" }}
                  onClick={() => history.push("/products/create")}
                >
                  <AddIcon />
                  Add Product
                </Button>
              </GuardedComponent>

              <ReactTable
                data={products}
                columns={tableColumns}
                defaultPageSize={pageSize}
                showPageSizeOptions
                showPageJump={false}
                showPaginationTop={false}
                multiSort={false}
                manual
                page={page}
                pages={totalPages}
                pageSizeOptions={[5, 10, 15, 20, 25, 50]}
                defaultSorted={sorted}
                onFetchData={handleFetchData}
                showPaginationBottom
              />
            </div>
          }
        />
      </ItemGrid>
    </GridContainer>
  );
};

export default compose(
  withRouter,
  withStyles(extendedTablesStyle)
)(ProductsTable);
