import DashboardIcon from "@material-ui/icons/Dashboard";
import Profile from "views/Profile/Profile";

const profile = {
    path: "/profile",
    name: "User Profile",
    icon: DashboardIcon,
    component: Profile,
    hideInMenu: true,
    matchUrl: '/profile'
};

export default profile;