import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const SummaryStyles = (theme) => ({
  container: {
    minHeight: "50vh",
    display: "grid",
    placeItems: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  consignor: {
    width: 300,
  },
  pieCharts: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "24px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.up("xl")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
  barChart: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "24px",
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  chart: {
    '& .ct-label': {
      fill: '#000',
    },
    "& .ct-series-a .ct-slice-pie": {
      fill: infoColor,
    },
    "& .ct-series-b .ct-slice-pie": {
      fill: dangerColor,
    },
    "& .ct-series-c .ct-slice-pie": {
      fill: warningColor,
    },
    "& .ct-series-d .ct-slice-pie": {
      fill: primaryColor,
    },
    "& .ct-series-e .ct-slice-pie": {
      fill: successColor,
    },
    "& .ct-series-f .ct-slice-pie": {
      fill: roseColor,
    },
    "& .ct-series-g .ct-slice-pie": {
      fill: grayColor,
    },
    "& .ct-series-h .ct-slice-pie": {
      fill: "darkgray",
    },
  },
});

export default SummaryStyles;
