import React from "react";
import {
  compose,
  withProps,
  withState,
  branch,
  renderComponent,
} from "recompose";
import List from "material-ui/List/List";
import ListItem from "material-ui/List/ListItem";
import cx from "classnames";
import withStyles from "material-ui/styles/withStyles";
import { NavLink } from "react-router-dom";
import ListItemText from "material-ui/List/ListItemText";
import Collapse from "material-ui/transitions/Collapse";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import avatar from "assets/img/faces/martin.jpeg";
import defaultAvatar from "assets/img/faces/default-user-icon.png";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logout from "redux/auth/logout";
import { BarLoader } from "react-css-loaders";
import _ from "lodash";

const User = (props) => {
  const {
    userWrapperClass,
    photo,
    classes,
    openCollapse,
    openAvatar,
    caret,
    collapseItemMini,
    onLogoutClick,
    user,
    collapse = false,
  } = props;

  const itemTextClasses = cx(classes.itemText, {
    [classes.itemTextMini]: collapse,
  });

  const itemLinkClasses = cx(classes.itemLink, classes.userCollapseLinks);

  return (
    <div className={userWrapperClass}>
      <div className={photo}>
        {user.email === "martin@finesf.com" && (
          <img src={avatar} className={classes.avatarImg} alt="..." />
        )}
        {user.email !== "martin@finesf.com" && (
          <img src={defaultAvatar} className={classes.avatarImg} alt="..." />
        )}
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <NavLink
            to={"#"}
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={openCollapse}
          >
            <ListItemText
              primary={`${user.firstName} ${user.lastName}`}
              secondary={
                <b
                  className={
                    caret +
                    " " +
                    classes.userCaret +
                    " " +
                    (openAvatar ? classes.caretActive : "")
                  }
                />
              }
              disableTypography={true}
              className={cx(itemTextClasses, classes.userItemText)}
            />
          </NavLink>
          <Collapse in={openAvatar} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              <ListItem className={classes.collapseItem}>
                <NavLink to="/profile" className={itemLinkClasses}>
                  <span className={collapseItemMini}>
                    <AccountCircle />
                  </span>
                  <ListItemText
                    primary="Profile"
                    disableTypography={true}
                    className={cx(itemTextClasses, classes.userItemSubText)}
                  />
                </NavLink>
              </ListItem>
              <ListItem className={classes.collapseItem}>
                <NavLink
                  to="#"
                  className={itemLinkClasses}
                  onClick={onLogoutClick}
                >
                  <span className={collapseItemMini}>
                    <ExitToApp />
                  </span>
                  <ListItemText
                    primary="Logout"
                    disableTypography={true}
                    className={cx(itemTextClasses, classes.userItemSubText)}
                  />
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isGettingUser: state.auth.isGettingUser,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};

export default compose(
  withStyles(sidebarStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withState("openAvatar", "setOpenAvatar", false),
  withProps(
    ({
      classes,
      rtlActive,
      bgColor,
      setOpenAvatar,
      openAvatar,
      miniActive,
      logout,
    }) => {
      return {
        userWrapperClass:
          classes.user +
          " " +
          cx({ [classes.whiteAfter]: bgColor === "white" }),
        photo: classes.photo + " " + cx({ [classes.photoRTL]: rtlActive }),
        openCollapse: (event) => {
          event.preventDefault();
          setOpenAvatar(!openAvatar);
        },
        caret: classes.caret + " " + cx({ [classes.caretRTL]: rtlActive }),
        itemText:
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: miniActive,
            [classes.itemTextMiniRTL]: rtlActive && miniActive,
            [classes.itemTextRTL]: rtlActive,
          }),
        collapseItemText:
          classes.collapseItemText +
          " " +
          cx({ [classes.collapseItemTextMini]: miniActive }),
        collapseItemMini: classes.collapseItemMini,
        onLogoutClick: (event) => {
          event.preventDefault();
          logout();
        },
      };
    }
  ),
  branch(
    ({ isGettingUser, user }) => {
      return isGettingUser || _.isNull(user);
    },
    renderComponent(() => <BarLoader color="#fff" size={4} />)
  )
)(User);
