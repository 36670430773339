
import React, { Component } from 'react';
import defaultImage from "assets/img/image_placeholder.jpg";
import { serviceAxios as axios } from 'bootstrap';

class ProductImage extends Component {
    constructor() {
        super();
        this.state = {
            imageUrl: defaultImage
        }
    }

    componentDidMount() {
        const { id } = this.props;
        axios.get(`/api/files/products/${id}/photo.jpg`, { responseType: 'blob' })
        .then(response => {
            if (response.data.size>10) {
                const dataUrl = URL.createObjectURL(response.data);
                this.setState({ imageUrl: dataUrl });
            }
        })
    }

    render() {
        return (
            <img alt='...' src={this.state.imageUrl} style={{width: "50px"}}/>
        );
    }
}

export default ProductImage;
