import approve from 'approvejs';
import _ from 'lodash';
import './rules';

class Validator {

    constructor(rules, messages) {

        this.rules = {};

        this.messages = {};

        if(_.isPlainObject(rules) && !_.isEmpty(rules)) {
            this.rules = rules;
        }

        if(_.isPlainObject(messages) && !_.isEmpty(messages)) {
            this.messages = messages;
        }
    }

    getRules() {

        let tempRules = this.rules;

        const instanceDefinedRules = this.rulesDefinition();

        if(_.isPlainObject(instanceDefinedRules) && !_.isEmpty(instanceDefinedRules)) {
            tempRules = _.merge(tempRules, instanceDefinedRules);
        }

        return tempRules;
    }

    getMessages() {

        let tempMessages = this.messages;

        const instanceDefinedMessages = this.messagesDefinition();

        if(_.isPlainObject(instanceDefinedMessages) && !_.isEmpty(instanceDefinedMessages)) {
            tempMessages = _.merge(tempMessages, instanceDefinedMessages);
        }

        return tempMessages;
    }

    rulesDefinition()
    {}

    messagesDefinition()
    {}

    validate(input) {

        const errors = {};

        const rules = this.getRules();

        _.forEach(_.keys(rules), (field) => {

            if(!_.has(rules, field)) {
                input[field] = null;
            }

            _.forEach(_.keys(rules[field]), (ruleName) => {
                if(_.isObject(rules[field][ruleName]) && _.has(rules[field][ruleName], 'config.input')) {
                    rules[field][ruleName].config.input = input;
                }
            });

            let validation = approve.value(input[field], rules[field]);

            if(!validation.approved) {
                errors[field] = validation.errors[0];
            }
        });

        return {
            valid: _.isEmpty(errors),
            errors
        };
    }
}

export default Validator;