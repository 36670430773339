import { grayColor } from "assets/jss/material-dashboard-pro-react.jsx";

const ChartFooterStyles = {
  title: {
    color: grayColor,
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    "& div": {
      marginRight: 16,
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
};

export default ChartFooterStyles;
