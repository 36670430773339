import React from 'react'
import CreateCustomerForm from './CreateCustomerForm'
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import { NotificationManager } from 'react-notifications';
import OverlayLoader from '../Components/OverlayLoader';

class CreateCustomer extends React.Component {

    state = {
        alert: null,
        submitting: false
    }

    componentDidMount() {
        document.title = "EPOS - Create Customer";
    }

    hideAlert() {
        this.setState({
          alert: null
        });
    }

    showAlert(message) {
        this.setState({
          alert: (
            <SweetAlert
                danger
                title="Create Failed"
                confirmBtnText="Continue"
                onConfirm={() => this.hideAlert()}
                confirmBtnCssClass= { 
                    this.props.classes.button + " " + this.props.classes.danger
                }
            >
                {message}
            </SweetAlert>
          )
        });
    }
    
    createCustomer = async (json) => {
        this.setState({ submitting: true })
        
        const file = json.picture && json.picture.file
        delete json.picture

        const resellerLicence = json.resellerLicence && json.resellerLicence.file;
        delete json.resellerLicence;

        try {
            const response =  await axios.post(`/api/customers`, json)
            const customerId = response.data.data.id
            if (file) {
                await axios.post(`/api/files/customer/${customerId}/photo.jpg`, file, {
                    headers: {
                    'Content-Type': file.type
                    }
                });
            }
            if (resellerLicence) {
                await axios.post(`/api/files/customer/${customerId}/reseller_licence`, resellerLicence, {
                    headers: {
                        'Content-Type': resellerLicence.type
                    }
                });
            }
            NotificationManager.success("Customer Created", "Success")
            this.setState({ submitting: false })
        } catch (error) {
            this.setState({ submitting: false })
            this.showAlert(extractErrorMessage(error))
        }
    }

    render() {
        return (
            <div>
                { this.state.alert }
                <CreateCustomerForm submitting={this.state.submitting} onSubmit={this.createCustomer} />
                { this.state.submitting && <OverlayLoader />}
            </div>
        )
    }
}

export default withStyles(buttonStyle)(CreateCustomer)