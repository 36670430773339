import React from 'react'
import CreateUserForm from './CreateUserForm'
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import { NotificationManager } from 'react-notifications';
import OverlayLoader from '../Components/OverlayLoader';

class CreateUser extends React.Component {

    state = {
        alert: null,
        submitting: false
    }

    componentDidMount() {
        document.title = "EPOS - Create User";
    }

    hideAlert() {
        this.setState({
          alert: null
        });
    }

    showAlert(message) {
        this.setState({
          alert: (
            <SweetAlert
                danger
                title="Create Failed"
                confirmBtnText="Continue"
                onConfirm={() => this.hideAlert()}
                confirmBtnCssClass= { 
                    this.props.classes.button + " " + this.props.classes.danger
                }
            >
                {message}
            </SweetAlert>
          )
        });
    }
    
    createUser = async (json) => {
        this.setState({ submitting: true })
        
        const file = json.picture && json.picture.file
        delete json.picture

        try {
            const response =  await axios.post(`/api/users`, json)
            const userId = response.data.data.id
            if (file) {
                await axios.post(`/api/files/user/${userId}/photo.jpg`
                                , file, {
                                    headers: {
                                    'Content-Type': file.type
                                    }
                                });
            }
            NotificationManager.success("User Created", "Success")
            this.setState({ submitting: false })
        } catch (error) {
            this.setState({ submitting: false })
            this.showAlert(extractErrorMessage(error))
        }
    }

    render() {
        return (
            <div>
                { this.state.alert }
                <CreateUserForm submitting={this.state.submitting} onSubmit={this.createUser} />
                { this.state.submitting && <OverlayLoader />}
            </div>
        )
    }
}

export default withStyles(buttonStyle)(CreateUser)