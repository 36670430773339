import Validator from '../Validator';

class CreateValidator extends Validator {
    rulesDefinition() {
        return {
            firstName: {
                required: true,
                stop: true,
            },
            lastName: {
                required: true,
                stop: true,
            },
            company: {
                required: true,
                stop: true,
            },
            phone: {
                required: true,
                stop: true,
            },
        };
    }
}

export default CreateValidator;