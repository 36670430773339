import Token from 'models/Token';
import User from 'models/User';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const EDIT_PROFILE_START = 'EDIT_PROFILE_START';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED';

const defaultState = {
    user: User.createFromLocalStorage(),
    token: Token.createFromLocalStorage(),
    acl: Token.getAclFromLocalStorage(),
    isLoggingIn: false,
    loginError: false,
    errorText: null,
    isGettingUser: false,
    isUpdatingUser: false,
};

const auth = (state = defaultState, action) => {
    switch(action.type) {
        case(LOGIN_START):
            return ({
                ...state,
                isLoggingIn: true,
                loginError: false,
            });
        case(LOGIN_SUCCESS):
            return ({
                ...state,
                isLoggingIn: false,
                token: action.payload.token,
                acl: action.payload.acl,
            });
        case(LOGIN_FAILED):
            return ({
                ...state,
                isLoggingIn: false,
                loginError: true,
                errorText: action.payload.errorText,
                token: null,
                acl: null,
            });
        case(LOGOUT_SUCCESS):
            return ({
                ...state,
                token: null,
                acl: null,
                user: null,
            });
        case(GET_USER_START):
            return ({
                ...state,
                isGettingUser: true,
            });
        case(GET_USER_SUCCESS):
            return ({
                ...state,
                isGettingUser: false,
                user: action.payload.user
            });
        case(GET_USER_FAILED):
            return ({
                ...state,
                isGettingUser: false,
                user: null,
            });
        case(EDIT_PROFILE_START):
            return ({
                ...state,
                isUpdatingUser: true,
            });
        case(EDIT_PROFILE_SUCCESS):
            return ({
                ...state,
                isUpdatingUser: false,
                user: action.payload.user
            });
        case(EDIT_PROFILE_FAILED):
            return ({
                ...state,
                isUpdatingUser: false,
            });
        default:
            return state;
    }
};

export default auth;