import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import CustomersListing from "../../views/Customers/CustomersListing";
import CreateCustomer from "../../views/Customers/CreateCustomer";
import EditCustomer from "../../views/Customers/EditCustomer";
import CreateClient from "../../views/Clients/CreateClient";

const customers = {
    path: "/customers/list",
    parentPath: "/customers/",
    name: "Customers",
    icon: PermContactCalendar,
    component: CustomersListing,
    hideInMenu: false,
    matchUrl: '/customers/list',
    acl: ['customers-read']
};

export const customersInner = [{
    path: "/clients/create",
    name: "Create Consignor",
    mini: "CC",
    component: CreateClient,
    hideInMenu: true,
    matchUrl: '/clients/create',
    acl: ['clients-create']
}, {
    path: "/customers/create",
    name: "Create Customer",
    mini: "CC",
    component: CreateCustomer,
    hideInMenu: true,
    matchUrl: '/customers/create',
    acl: ['customers-create']
}, {
    path: "/customers/edit/:id",
    name: "Edit Customer",
    mini: "EC",
    component: EditCustomer,
    hideInMenu: true,
    matchUrl: '/customers/edit',
    acl: ['customers-edit']
}];

export default customers;