import axios from 'axios';
import { serviceAxios } from '../../bootstrap'
import Token from 'models/Token';
import { replace } from 'react-router-redux';
import getUser from './getUser';

import {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILED
} from './auth-reducer'

export default function login(email, password) {
    return (dispatch) => {

        dispatch({ type: LOGIN_START });

        axios.post('/accessToken', {email, password})
            .then((response) => {
                const token = Token.createFromResponse({
                    access_token: response.data.access_token,
                    refresh_token:  response.data.refresh_token,
                    expires_in: response.data.expires_in,
                });

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                serviceAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                dispatch({type: LOGIN_SUCCESS, payload: { token: token, acl: response.data.acl }});
                dispatch(getUser());
                dispatch(replace('/'));
            })
            .catch((error) => {
                dispatch({ type: LOGIN_FAILED, payload: {errorText: error.toString()} });
            });
    }
};