import React from 'react';
import { compose } from 'recompose';
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import EditUserForm from './EditUserForm';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { BarLoader } from 'react-css-loaders';
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import { NotificationManager } from 'react-notifications';

class EditUser extends React.Component {

    state = {
        alert: null,
        isLoadingUser: false,
        submitting: false,
        user: null,
        image: null
    }

    componentDidMount() {
        document.title = 'EPOS - Edit User'

        if(!this.state.isLoadingUser) {
            this.getUser();
        }
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(message, title) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title={title}
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    getImage = (id) => {
        axios.get(`/api/files/users/${id}/photo.jpg`, { responseType: 'blob' })
        .then(response => {
            if (response.data.size>10) {
                var data_url = URL.createObjectURL(response.data);
                this.setState({ image: data_url });
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    getUser = () => {
        const userId = this.props.match.params.id
        this.getImage(userId);
        this.setState({isLoadingUser: true})
        axios.get(`/api/users/${userId}`)
        .then(response => {
            this.setState({
                 user: response.data.data,
                 isLoadingUser: false
            })
        })
        .catch((error) => {
            this.setState({ isLoadingUser: false })
            this.showAlert(extractErrorMessage(error), "Getting User Failed")
        });
    }

    updateUser = async (json) => {
        let userId = this.props.match.params.id
        this.setState({ submitting: true })

        const file = json.picture && json.picture.file
        delete json.picture

        try {
            let response = await axios.post(`/api/users/${userId}`, json)
            if (file === -1) {
                await axios.delete(`/api/files/users/${userId}/photo.jpg`);
            } else if (file) {
                await axios.post(`/api/files/users/${userId}/photo.jpg`
                                , file, {
                                    headers: {
                                    'Content-Type': file.type
                                    }
                                });
            }
            this.setState({
                    user: response.data.data,
                    submitting: false
            })
            NotificationManager.success("User Modified", "Success")
        } catch ( error ) {
            this.setState({ submitting: false })
            this.showAlert(extractErrorMessage(error), "Update Failed")
        }
    }

    render() {
        return (
            <div>
                { this.state.alert }
                { (this.state.isLoadingUser || _.isNull(this.state.user) ) ? 
                    <BarLoader color="#d81b60" size={ 8 } />
                :
                    <EditUserForm user={this.state.user} image={this.state.image}  submitting={this.state.submitting} onSubmit={this.updateUser}  />
                }
            </div>
        )
    }
}

export default compose(
    withRouter,
    withStyles(buttonStyle)
)(EditUser);