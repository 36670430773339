import _ from 'lodash';
import BaseModel from './BaseModel';

class User extends BaseModel {

    static key = 'user';

    getDefaults() {
        return {
            id: null,
            firstName: null,
            lastName: null,
            email: null,
            role: null,
            role_id: null,
        };
    }

    static createFromLocalStorage() {

        const userFromStorage = JSON.parse(localStorage.getItem(User.key));

        if(!userFromStorage) {
            return null;
        }

        return new User({
            id: userFromStorage.id,
            firstName: userFromStorage.firstName,
            lastName: userFromStorage.lastName,
            email: userFromStorage.email,
            role: userFromStorage.role,
            role_id: userFromStorage.role_id,
        });
    }

    static createFromResponse(params) {

        const instance = new User({
            id: params.id,
            firstName: params.firstName,
            lastName: params.lastName,
            email: params.email,
            role: params.role,
            role_id: params.role_id,
        });

        User.saveToLocalStorage(instance);

        return instance;
    }

    static saveToLocalStorage(instance) {
        localStorage.setItem(
            User.key,
            JSON.stringify(instance),
        )
    }

    isAdmin() {
        return !_.isNull(this.role) && this.role.toLowerCase() === 'admin';
    }
}

export default User;