import _ from 'lodash';
import BaseModel from './BaseModel';
import moment from 'moment';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

class Token extends BaseModel {

    static key = 'token';

    constructor(params) {

        super(params);

        this.expirationDate = moment().seconds(params.expires_in);

        if(!this.expirationDate.isValid()) {
            throw new Error('Failed to calculate expiration date for the access token!')
        }
    }

    getDefaults() {
        return {
            access_token: null,
            refresh_token: null,
            expirationDate: null,
            expires_in: null,
        };
    }

    static createFromLocalStorage() {

        const tokenFromStorage = JSON.parse(localStorage.getItem(Token.key));

        if(!tokenFromStorage) {
            return null;
        }

        const expirationDate = moment(tokenFromStorage.expirationDate);

        if(!expirationDate.isValid() || !moment().isBefore(expirationDate)) {
            Token.saveToLocalStorage(null);
            return null;
        }

        return new Token({
            access_token: tokenFromStorage.access_token,
            refresh_token: tokenFromStorage.refresh_token,
            expires_in: tokenFromStorage.expires_in,
            expirationDate
        });
    }

    static getAclFromLocalStorage() {

        const tokenFromStorage = JSON.parse(localStorage.getItem(Token.key));

        if(!tokenFromStorage) {
            return null;
        }

        const expirationDate = moment(tokenFromStorage.expirationDate);

        if(!expirationDate.isValid() || !moment().isBefore(expirationDate)) {
            Token.saveToLocalStorage(null);
            return null;
        }

        const parsedToken = parseJwt(tokenFromStorage.access_token);
        
        return parsedToken?.scopes || [];
    }

    static createFromResponse({ access_token, refresh_token, expires_in }) {

        const instance = new Token({
            access_token,
            refresh_token,
            expires_in
        });

        Token.saveToLocalStorage(instance);

        return instance;
    }

    static saveToLocalStorage(instance) {
        localStorage.setItem(
            Token.key,
            JSON.stringify(instance),
        )
    }

    isExpired() {

        if(_.isNull(this.expirationDate)) {
            return true;
        }

        return !moment().isBefore(this.expirationDate);
    }
}

export default Token;