import React, { useCallback } from "react";
import ReactTable from "react-table";
import _ from "lodash";
import { compose } from "recompose";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Contacts from "@material-ui/icons/Contacts";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import withStyles from "material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import AddIcon from "@material-ui/icons/Add";
import GuardedComponent from "components/Authorization/GuardedComponent";

const CustomersTable = ({
  classes,
  customers,
  onClickDelete,
  syncCustomers,
  settings,
  getCustomers,
  history,
}) => {
  const {
    perPage: pageSize = 15,
    page,
    totalPages = 1,
    sorted = [],
    search = null,
  } = settings || {};
  const tableColumns = [
    {
      Header: "ID",
      accessor: "id",
      width: 45,
    },
    {
      Header: "First Name",
      id: "firstName",
      accessor: "firstName",
      width: 110,
    },
    {
      Header: "Last Name",
      id: "lastName",
      accessor: "lastName",
      width: 110,
    },
    {
      Header: "Company",
      accessor: "company",
      width: 120,
    },
    {
      Header: "Reseller",
      accesor: "reseller_number",
      sortable: false,
      Cell: (customer) =>
        customer.original.reseller_number ? (
          <i className="material-icons">check_circle_outline</i>
        ) : null,
      width: 100,
    },
    {
      Header: "Actions",
      headerStyle: {
        textAlign: "center",
      },
      sortable: false,
      Cell: (customer) => (
        <div>
          <GuardedComponent scope={["customers-edit"]}>
            <IconButton
              color="success"
              customClass={
                classes.actionButton + " " + classes.actionButtonRound
              }
              key="edit"
              onClick={() =>
                history.push(`/customers/edit/${customer.original.id}`)
              }
            >
              <Edit className="Edit" />
            </IconButton>
          </GuardedComponent>
          <GuardedComponent scope={["customers-delete"]}>
            <IconButton
              color="danger"
              customClass={
                classes.actionButton + " " + classes.actionButtonRound
              }
              key="delete"
              onClick={() => onClickDelete(customer.original)}
            >
              <Close className="Close" />
            </IconButton>
          </GuardedComponent>
        </div>
      ),
      width: 120,
    },
  ];

  const handleFetchData = useCallback(
    async ({ page: nextPage, pageSize: nextPageSize, sorted: nextSorted }) => {
      const fetchDetails = { page, pageSize, sorted, search };
      const nextFetch = {
        page: nextPage,
        pageSize: nextPageSize,
        sorted: nextSorted,
        search,
      };
      if (!_.isEqual(fetchDetails, nextFetch)) {
        await getCustomers({
          page: nextPage + 1,
          pageSize: nextPageSize,
          sorted: nextSorted,
          search,
        });
      }
    },
    [page, pageSize, sorted, search, getCustomers]
  );

  return (
    <GridContainer>
      <ItemGrid xs={12}>
        <IconCard
          icon={Contacts}
          iconColor="rose"
          title="Customers"
          content={
            <div>
              <GuardedComponent scope={["customers-create"]}>
                <Button
                  color="rose"
                  size="sm"
                  style={{ position: "absolute", top: "2px", right: "20px" }}
                  onClick={() => history.push("/customers/create")}
                >
                  <AddIcon />
                  Add Customer
                </Button>
              </GuardedComponent>
              <GuardedComponent scope={["customers-edit"]}>
                <Button
                  color="blue"
                  size="sm"
                  style={{ position: "absolute", top: "2px", right: "200px" }}
                  onClick={syncCustomers}
                >
                  <AddIcon />
                  Sync Customers (Square)
                </Button>
              </GuardedComponent>

              <ReactTable
                data={customers}
                columns={tableColumns}
                showPageSizeOptions
                showPageJump={false}
                showPaginationTop={false}
                showPaginationBottom
                defaultPageSize={pageSize}
                multiSort={false}
                manual
                page={page}
                pages={totalPages}
                pageSizeOptions={[5, 10, 15, 20, 25, 50]}
                defaultSorted={sorted}
                onFetchData={handleFetchData}
              />
            </div>
          }
        />
      </ItemGrid>
    </GridContainer>
  );
};

export default compose(
  withRouter,
  withStyles(extendedTablesStyle)
)(CustomersTable);
