import axios from "axios";

import {
  VERIFY_START,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
} from "./recover-reducer";

export default function verify(recoveryToken, userId) {
  return (dispatch) => {
    dispatch({ type: VERIFY_START });

    axios
      .get(`/recover/verify/${recoveryToken}/${userId}`)
      .then((response) => {
        dispatch({ type: VERIFY_SUCCESS });
      })
      .catch((error) => {
        console.log(error.response)
        dispatch({
          type: VERIFY_FAILED,
          payload: { errorText: error.response.data.message },
        });
      });
  };
}
