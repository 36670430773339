import sale from './sale';
import profile from './profile';
import users, {usersInner} from './users';
import categories, {categoriesInner} from './categories';
import clients, {clientsInner} from './clients';
import customers, {customersInner} from './customers';
import products, {productsInner} from './products';
import { reportsInner } from './reports';

const dashRoutes = [
    sale,
    products,
    ...productsInner,
    profile,
    users,
    ...usersInner,
    clients,
    ...clientsInner,
    customers,
    ...customersInner,
    categories,
    //...categoriesInner,
    ...reportsInner,
    {
        redirect: true,
        path: "/",
        pathTo: "/sale",
        name: "Sale"
    }
];

export default dashRoutes;