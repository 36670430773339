import React from 'react'
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import CreateCategoryForm from './CreateCategoryForm'
import { NotificationManager} from 'react-notifications';

import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import OverlayLoader from '../Components/OverlayLoader';

class CreateCategory extends React.Component {

    state = {
        alert: null,
        submitting: false
    }

    componentDidMount() {
        document.title = "EPOS - Create Category"
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(message) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title="Create Failed"
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    createCategory = async (json) => {
        console.log(json)
        this.setState({submitting: true})

        const file = json.picture && json.picture.file
        delete json.picture
        
        try {
            const response = await axios.post(`/api/categories`, json)
            const categoryId = response.data.data.id
            if (file !== -1 && file) {
                await axios.post(`/api/files/categories/${categoryId}/photo.jpg`
                , file, {
                    headers: {
                    'Content-Type': file.type
                    }
                });
            }
            NotificationManager.success("Category Added", "Success")
            this.setState({submitting: false})
        } catch (error) {
            this.setState({submitting: false})
            this.showAlert(extractErrorMessage(error))
        }

    }

    render() {
        return (
            <div>
                { this.state.alert }
                <CreateCategoryForm submitting={this.state.submitting} onSubmit={this.createClient} />
                { this.state.submitting && <OverlayLoader />}
            </div>
        )
    }
};

export default withStyles(buttonStyle)(CreateCategory)