import _ from 'lodash';

const isNotEmptyArray = {};

isNotEmptyArray.message = '{title} please select one or more values!';

isNotEmptyArray.validate = (value) => {
    return !_.isEmpty(value);
};

export default isNotEmptyArray;