import Validator from '../Validator';

class ProfileValidator extends Validator {
    rulesDefinition() {
        return {
            email: {
                required: true,
                email: true,
                stop: true,
            },
            firstName: {
                required: true,
                stop: true,
            },
            lastName: {
                stop: true,
                required: true
            },
            password: {
                ignoreNull: true,
                requiredIf: {
                    config: {
                        input: {}
                    },
                    target: 'password_confirmation'
                },
                sameAs: {
                    config: {
                        input: {}
                    },
                    target: 'password_confirmation'
                },
                stop: true,
            },
            password_confirmation: {
                ignoreNull: true,
                requiredIf: {
                    config: {
                        input: {}
                    },
                    target: 'password'
                },
                sameAs: {
                    config: {
                        input: {}
                    },
                    target: 'password'
                },
                stop: true,
            },
        };
    }
}

export default ProfileValidator;