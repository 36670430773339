import React from 'react';

import { serviceAxios as axios } from '../../bootstrap';

import defaultImage from "assets/img/image_placeholder.jpg";
import { Paper, CircularProgress } from 'material-ui';

class CategoryProduct extends React.Component {

    state = {
        loaded: false,
        image: defaultImage
    }

    componentDidMount() {
        if(!this.state.loaded) {
            this.getImage(this.props.product.id);
        }
    }

    getImage = (id) => {
        axios.get(`/api/files/products/${id}/photo.jpg`, {responseType: 'blob'})
            .then(response => {
                if (response.data.size > 10) {
                    const data_url = URL.createObjectURL(response.data);
                    this.setState({image: data_url, loaded: true});
                } else {
                    this.setState({loaded: true});
                }
            })
            .catch(() => {
                this.setState({loaded: true});
            })
    }

    render() {
        const {product} = this.props;
        return (
            <div>
                <Paper style={{ 
                        height:"100px", 
                        margin:10, 
                        padding:"2px", 
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center" }}>
                    <img src= {this.state.image} alt=""
                        style = {{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                            cursor: "pointer"
                        }}
                        onClick={
                            () => this.props.onClick(product.id)
                        }
                    >
                    </img>
                    { !this.state.loaded  && <CircularProgress color="secondary" style={{  position:"absolute" }}  /> }
                </Paper>

            
            </div>
        )
    }
}
export default CategoryProduct