import { serviceAxios as axios } from "bootstrap";

export const fetchAll = async (endpoint = "") => {
  const { status, data } = await axios.get(`api/${endpoint}`);

  if (status !== 200) {
    return null;
  }

  const { data: response } = data;

  return response;
};
