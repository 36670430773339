import axios from "axios";

import {
  RESET_START,
  RESET_SUCCESS,
  RESET_FAILED,
} from "./recover-reducer";

export default function resetPassword(newPassword, userId) {
  return (dispatch) => {
    dispatch({ type: RESET_START });

    axios
      .post("/recover/reset", { newPassword, userId })
      .then((response) => {
        dispatch({ type: RESET_SUCCESS });
      })
      .catch((error) => {
        console.log(error.response)
        dispatch({
          type: RESET_FAILED,
          payload: { errorText: error.response.data.message },
        });
      });
  };
}
