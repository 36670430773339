import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import LoginValidator from "validators/auth/LoginValidator";
import login from "redux/auth/login";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";

// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockOutline from "@material-ui/icons/LockOutline";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      fields: {
        username: {
          name: "username",
          type: "text",
          labelText: "Username",
          formControlProps: {
            fullWidth: true,
          },
          inputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircle
                  className={this.props.classes.inputAdornmentIcon}
                />
              </InputAdornment>
            ),
          },
        },
        password: {
          name: "password",
          type: "password",
          labelText: "Password",
          formControlProps: {
            fullWidth: true,
          },
          inputProps: {
            type: "password",
            endAdornment: (
              <InputAdornment position="end">
                <LockOutline
                  className={this.props.classes.inputAdornmentIcon}
                />
              </InputAdornment>
            ),
          },
        },
      },
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    document.title = "EPOS - Login";
  }

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      loginError,
      isLoggingIn,
      errorText,
    } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <ItemGrid xs={12} sm={6} md={4}>
              <form onSubmit={handleSubmit}>
                <LoginCard
                  customCardClass={classes[this.state.cardAnimaton]}
                  headerColor="rose"
                  cardTitle="Login"
                  footerAlign="center"
                  footer={
                    <Button
                      color="roseNoBackground"
                      wd
                      size="lg"
                      type="submit"
                      disabled={isLoggingIn || submitting}
                    >
                      Submit
                    </Button>
                  }
                  content={
                    <div>
                      {loginError ? (
                        <SnackbarContent
                          message={`Login failed. Please try again. ( ${errorText} )`}
                          close
                          color="danger"
                        />
                      ) : null}

                      <Field
                        component={CustomInput}
                        {...this.state.fields.username}
                      />
                      <Field
                        component={CustomInput}
                        {...this.state.fields.password}
                      />
                      <a href="/recover/forgot">Forgot Password?</a>
                      
                    </div>
                  }
                />
              </form>
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  p: PropTypes.shape({
    t: PropTypes.func.isRequired,
    tc: PropTypes.func.isRequired,
    tu: PropTypes.func.isRequired,
    tm: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = (state) => {
  return {
    loginError: state.auth.loginError,
    isLoggingIn: state.auth.isLoggingIn,
    errorText: state.auth.errorText,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  );
};

export default compose(
  withStyles(loginPageStyle),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "login",
    validate: (values) => {
      const loginValidator = new LoginValidator();

      const result = loginValidator.validate(values);

      return result.valid ? {} : result.errors;
    },
    onSubmit: ({ username, password }, dispatch, { login }) => {
      login(username, password);
    },
  })
)(LoginPage);
