import React from "react";
import { serviceAxios as axios } from "../../bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateProductForm from "./CreateProductForm";

import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import extractErrorMessage from "../../utilities/HttpErrorResponse";
import { NotificationManager } from "react-notifications";
import OverlayLoader from "../Components/OverlayLoader";
import BarcodeScanner from "../Components/BarcodeScanner";
import { getCategoryPath } from "../../utilities/helpers/category";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { BarLoader } from "react-css-loaders";
import qs from "qs";

class CreateProduct extends React.Component {
  state = {
    alert: null,
    submitting: false,
    initialProduct: null,
    busy: false,
    initialBusy: true,
  };

  componentDidMount() {
    document.title = "EPOS - Create Product";

    let sku = null;
    if (this.props.location.search) {
      const query = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      if (query && query.sku) {
        sku = query.sku;
        this.updateWithSKU(sku);
      }
    }

    //HACK: For some reason when jumping from product list to this page
    //  the page would not appear properly. Until we figure out what's going on
    //  this is forcing this page to properly update
    setTimeout(() => {
      this.setState({
        initialBusy: false,
      });
    }, 500);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  showAlert(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          title={title}
          confirmBtnText="Continue"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  createProduct = async (json) => {
    this.setState({ submitting: true, busy: true });

    const file = json.picture && json.picture.file;
    delete json.picture;

    try {
      if (!json.name && json.category_id) {
        const catResponse = await axios.get(`/api/categories`);
        const categories = catResponse.data.data;
        json.name = getCategoryPath(json.category_id, categories || []).join(
          " / "
        );
      }
      const response = await axios.post(`/api/products`, json);
      const productId = response.data.data.id;
      const squareId = response.data.data.square_id;
      if (file !== -1 && file) {
        await axios.post(`/api/files/products/${productId}/photo.jpg`, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
      }
      if (squareId) {
        NotificationManager.success("Product Added", "Success");
      } else {
        NotificationManager.warning(
          "Product Added (but not synchronized with Square)",
          "Warning"
        );
      }
      this.setState({ submitting: false, busy: false });
      this.props.history.replace(`/products/list`);
    } catch (error) {
      this.setState({ submitting: false, busy: false });
      this.showAlert("Create Failed", extractErrorMessage(error));
    }
  };

  updateWithSKU = async (code) => {
    let clientCode = code.substr(0, 3);
    try {
      this.setState({ busy: true });
      const codeResponse = await axios.get(`/api/clients`, {
        params: {
          code: clientCode,
          per_page: Number.MAX_SAFE_INTEGER,
          sort: "code",
          sort_direction: "asc",
          enabled: 1,
        },
      });
      const client = codeResponse.data.data[0];
      if (client) {
        this.setState((state) => ({
          busy: false,
          initialProduct: {
            sku: code,
            client_id: client.id,
            clientName: `${client.firstName} ${client.lastName}`,
          },
        }));
      } else {
        this.showAlert(
          "Barcode Issue",
          "Scanned barcode does not match any consignor"
        );
        this.setState({ busy: false });
      }
    } catch (error) {
      this.showAlert(
        "Barcode Issue",
        "Scanned barcode does not match any consignor"
      );
      this.setState({ busy: false });
    }
  };

  codeScanned = (code) => {
    if (!this.state.initialProduct || !this.state.initialProduct.sku) {
      this.updateWithSKU(code);
    }
  };

  render() {
    return this.state.initialBusy ? (
      <BarLoader color="#d81b60" size={8} />
    ) : (
      <div>
        {this.state.alert}
        <CreateProductForm
          enableReinitialize={true}
          product={this.state.initialProduct}
          submitting={this.state.submitting}
          onSubmit={this.createProduct}
        />
        {this.state.busy && <OverlayLoader />}
        <BarcodeScanner
          regexp="([A-Z]{3})\w+"
          prefix="#"
          onCodeScanned={this.codeScanned}
        />
      </div>
    );
  }
}

export default compose(withRouter, withStyles(buttonStyle))(CreateProduct);
